<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4103_14270)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.5 1C2.94772 1 2.5 1.44772 2.5 2V16C2.5 16.5523 2.94772 17 3.5 17H8.5V11H12.5V8H16.5V2C16.5 1.44772 16.0523 1 15.5 1H3.5ZM13.5 4H5.5V5H13.5V4ZM5.5 7H9.5V8H5.5V7Z"
        :fill="getAssets.primaryBrandColor || '#000000'"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5 10H15.5V17H13.5V10ZM10.5 13H12.5V17H10.5V13ZM18.5 14H16.5V17H18.5V14Z"
        :fill="getAssets.primaryBrandColor || '#000000'"
      />
    </g>
    <defs>
      <clipPath id="clip0_4103_14270">
        <rect width="18" height="18" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Scenarions-Icon",
  computed: {
    ...mapGetters(["getAssets"]),
  },
};
</script>