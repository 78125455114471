<template>
  <div
    class="modal market-analysis-modal"
    id="market_analysis_modal"
    tabindex="-1"
    aria-labelledby="condosChartLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <div class="d-flex w-100 align-items-center">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="$emit('hideDialog', false)"
            >
              <img src="../assets/images/cross-icon.svg" alt="cross icon" />
            </button>
          </div>
        </div>
        <div class="modal-body">
          <div class="market-analysis-main-cover">
            <div class="market-analysis-main-left">
              <div class="market-analysis-main-scroll">
                <div class="market-analysis-main-title">
                  <h3>
                    <img
                      src="../assets/images/analysis-icon.svg"
                      alt="analysis"
                      class="mark-analys-icon"
                    />Market Analysis
                    <span
                      ><img
                        src="../assets/images/location-on-icon.svg"
                        alt=""
                        class="location-icon"
                      />
                      Nashville, TN</span
                    >
                  </h3>
                  <p v-if="getMarketAnalysisLastUpdateDate">Last Updated: {{getMarketAnalysisLastUpdateDate | formatDate}}</p>
                </div>
                <div v-if="getMarketAnalysisDesc" class="market-analysis-main-desc">
                  <p>{{ getMarketAnalysisDesc }}</p>
                </div>
                <div v-else class="market-analysis-main-desc">
                  <p>
                    The Nashville real estate market has remained strong over the last 30
                    days, Armstrong Group, offering a mix of challenges and opportunities
                    for your clients. The median home price in the city now stands at
                    $445,000, reflecting a 7% increase year-over-year. This steady rise is
                    indicative of the city's continuing appeal, with demand outpacing
                    available supply. Homes are selling faster than last month, averaging
                    28 days on the market—down from 33 days—suggesting that buyers need to
                    be ready to act quickly when they find the right property.
                  </p>

                  <p>
                    Inventory continues to be tight, with active listings down 15%
                    compared to this time last year. This creates a seller's market, but
                    it also presents opportunities for buyers who are willing to consider
                    neighborhoods that may have been overlooked. For those representing
                    sellers, this scarcity means it's a good time to leverage your
                    listings, especially during the holiday season, when competition can
                    sometimes softer.
                  </p>

                  <p>
                    Mortgage rates have held steady at around 7.3% on average, which has
                    introduced some caution into the market. However, it's important to
                    remind your clients that there are strategies to help them navigate
                    these rates. One option to explore is the temporary rate buydown,
                    which could reduce the interest rate by 1-2% for the first couple of
                    years. This could make monthly payments more manageable in the short
                    term while still allowing buyers to lock in a home now before rates
                    rise further. As always, Armstrong Group, Garrett Locklear and The
                    Locklear Lending Team are here to support your clients with the
                    financing solutions they need to succeed in this fast-moving market.
                    Whether they’re buying, selling, or investing, we're ready to provide
                    personalized advice that fits their goals. Let’s keep making great
                    things happen!
                  </p>
                </div>
              </div>
            </div>
            <div class="market-analysis-main-right">
              <div class="market-analysis-main-scroll">
                <div class="chart">
                  <div class="chart-title-top">
                    <h3>Credit & LTV</h3>
                  </div>
                  <BarChart :chartData="barChartData" :chartOptions="barChartOptions" />
                </div>
                <div class="chart">
                  <div class="chart-title-top">
                    <h3><strong>Rates Trend</strong></h3>
                  </div>
                  <LineChart :chartData="lineChartData" :chartOptions="lineChartOptions" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import LineChart from "./LineChart.vue";
import BarChart from "./BarChart.vue";
import { mapGetters } from "vuex";

export default {
  name: "MarketAnalysisModal",
  components: {
    LineChart,
    BarChart,
  },
  props: {
    rateHistoryData: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      selectedTab: "rates",
      // Data for line chart
      lineChartData: {
        labels: [],
        datasets: [
          {
            label: "C15 - Yr",
            borderColor: "#222222",
            backgroundColor: "#222222",
            data: [],
            tension: 0.1, // Make line sharp
            fill: false, // Remove background color
          },
          {
            label: "C30 - Yr",
            borderColor: "",
            backgroundColor: "",
            data: [],
            tension: 0.1, // Make line sharp
            fill: false, // Remove background color
          },
        ],
      },
      // Line Chart options (optional)
      lineChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },

      // Data for bar chart
      barChartData: {
        labels: ["Fico < 680", "680 - 699", "700 - 719", "720 - 739", "Fico > 740"],
        datasets: [
          {
            label: "LTV <= 80",
            backgroundColor: "#222222",
            data: [],
          },
          {
            label: "LTV > 80",
            backgroundColor: "",
            data: [],
          },
        ],
      },
      // Bar Chart options (optional)
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
        },
        animation: {
          y: { from: 0 },
        },
      },
    };
  },
  computed: {
    ...mapGetters(["getAssets", "getMarketAnalysisDesc", "getMarketAnalysisLastUpdateDate"]),

    isCreditTabSelected() {
      return this.selectedTab === "credit";
    },
    isRateTabSelected() {
      return this.selectedTab === "rates";
    },
  },
  watch: {
    rateHistoryData: {
      deep: true,
      handler(newVal) {
        if (newVal?.length) {
          this.barChartData.datasets[0].data = [
            newVal[0].X30LT80Less680,
            newVal[0].X30LT80Less680To699,
            newVal[0].X30LT80Less700To719,
            newVal[0].X30LT80Less720To739,
            newVal[0].X30LT80Plus740,
          ];

          this.barChartData.datasets[1].backgroundColor =
            this.getAssets?.primaryBrandColor || "#BE2530";

          this.barChartData.datasets[1].data = [
            newVal[0].X30GT80Less680,
            newVal[0].X30GT80Plus680To699,
            newVal[0].X30GT80Plus700To719,
            newVal[0].X30GT80Plus720To739,
            newVal[0].X30GT80Plus740,
          ];

          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
          });

          this.lineChartData.datasets[1].borderColor =
            this.getAssets?.primaryBrandColor || "#BE2530";
          this.lineChartData.datasets[1].backgroundColor =
            this.getAssets?.primaryBrandColor || "#BE2530";

          this.lineChartData.labels = [];

          newVal.forEach((element, index) => {
            if (index % 2 === 0) {
              let day = dateFormatter.format(new Date(element.dataDate));
              this.lineChartData.labels.unshift(day);
              this.lineChartData.datasets[0].data.unshift(element.C15);
              this.lineChartData.datasets[1].data.unshift(element.C30);
            }
          });
        }
      },
    },
  },
  mounted() {
    if (this.rateHistoryData?.length) {
      this.barChartData.datasets[0].data = [
        this.rateHistoryData[0].X30LT80Less680,
        this.rateHistoryData[0].X30LT80Less680To699,
        this.rateHistoryData[0].X30LT80Less700To719,
        this.rateHistoryData[0].X30LT80Less720To739,
        this.rateHistoryData[0].X30LT80Plus740,
      ];

      this.barChartData.datasets[1].backgroundColor =
        this.getAssets?.primaryBrandColor || "#BE2530";

      this.barChartData.datasets[1].data = [
        this.rateHistoryData[0].X30GT80Less680,
        this.rateHistoryData[0].X30GT80Plus680To699,
        this.rateHistoryData[0].X30GT80Plus700To719,
        this.rateHistoryData[0].X30GT80Plus720To739,
        this.rateHistoryData[0].X30GT80Plus740,
      ];

      const dateFormatter = new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "2-digit",
      });

      this.lineChartData.datasets[1].borderColor =
        this.getAssets?.primaryBrandColor || "#BE2530";
      this.lineChartData.datasets[1].backgroundColor =
        this.getAssets?.primaryBrandColor || "#BE2530";

      this.lineChartData.labels = [];

      this.rateHistoryData.forEach((element, index) => {
        if (index % 2 === 0) {
          let day = dateFormatter.format(new Date(element.dataDate));
          this.lineChartData.labels.unshift(day);
          this.lineChartData.datasets[0].data.unshift(element.C15);
          this.lineChartData.datasets[1].data.unshift(element.C30);
        }
      });
    }
  },
  methods: {
    changeTab(tab) {
      this.selectedTab = tab;
    },
  },
};
</script>

<style scoped>
.market-analysis-main-right {
  display: inline-flex;
  width: 50%;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
}
.market-analysis-main-right .chart {
  display: inline-block;
  width: 100%;
  height: 50%;
}
.market-analysis-main-right canvas {
  height: 90% !important;
}
.market-analysis-modal .modal-body {
  padding: 0px !important;
}
.market-analysis-modal .modal-header {
  padding: 0px !important;
}
.market-analysis-main-cover {
  display: inline-flex;
  width: 100%;
  height: calc(100vh - 75px) !important;
  max-height: 880px;
}
.market-analysis-main-left {
  width: 50%;
  padding-left: 35px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 28px;
}
.market-analysis-main-right {
  width: 50%;
  border-radius: 16px;
  overflow: auto;
}
.market-analysis-main-title h3 {
  color: #000000;
  font-weight: 600;
  font-size: 22px;
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 42px;
  padding-top: 3px;
  margin-bottom: 10px;
}
.market-analysis-main-title h3 span {
  font-weight: normal;
  color: #222222;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 5px;
}
.mark-analys-icon {
  width: 26px;
  position: absolute;
  left: 0;
  top: 2px;
}

.market-analysis-main-title p {
  color: #707070;
  padding-left: 42px;
  font-size: 15px;
}
.location-icon {
  width: 18px;
}

.market-analysis-main-desc {
  padding-left: 42px;
  display: inline-block;
  width: 100%;
}

.market-analysis-main-desc p {
  color: #222222;
  display: inline-block;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 0px;
}
.market-analysis-main-scroll {
  height: 100%;
  overflow: auto;
  padding-right: 20px;
  width: 100%;
}
.market-analysis-main-scroll::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border: 5px solid #d9d9d9;
}
@media (max-width: 1024px) {
  .market-analysis-modal .modal-dialog {
    max-width: 88%;
  }
}
@media (max-width: 991px) {
  .market-analysis-main-cover {
    flex-wrap: wrap;
    height: 100% !important;
    max-height: 100%;
  }
  .market-analysis-main-left {
    width: 100%;
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 30px;
    padding-right: 20px;
  }
  .market-analysis-main-right {
    width: 100%;
  }
  .market-analysis-main-scroll {
    max-height: 510px;
  }
}
@media (max-width: 575px) {
  .market-analysis-modal .modal-dialog {
    max-width: 100%;
  }
}

.market-analysis-main-right {
  background-color: #f6f8fa;
}

.market-analysis-main-right {
  padding: 25px;
}

.chart-title-top {
  display: inline-block;
  width: 100%;
  height: auto !important;
}

.chart-title-top h3 {
  font-size: 16px;
  margin-bottom: 10px;
  font-family: "Segoe UI Bold";
}

.market-analysis-modal .modal-content {
  border-radius: 12px;
  border: none;
}

.market-analysis-modal .modal-header {
  border-bottom: none;
  padding: 27px 40px 0;
}

.market-analysis-modal .modal-header .btn-close {
  position: absolute;
  top: 10px;
  right: -30px;
  background-color: #fff;
  border-radius: 40px;
}

.market-analysis-modal .modal-body {
  padding: 28px 40px 40px;
}

.market-analysis-modal .modal-body .chart {
  background-color: #f6f8fa;
  padding: 20px;
}

@media (max-width: 767px) {
  .market-analysis-modal .modal-header {
    padding: 20px 20px 0;
  }

  .market-analysis-modal .modal-body {
    padding: 20px 20px;
  }

  .market-analysis-modal .modal-body .chart {
    padding: 10px;
  }

  .market-analysis-modal .modal-header .btn-close {
    position: relative;
    right: 0;
    top: 0;
    background-color: transparent;
    margin: 0 0 auto auto;
  }
}
</style>
