<template>
  <div class="content__wrapper">
    <header>
      <InterestRateToolBar
        :interest-rates="getAssets?.rateHistoryData?.[0] || {}"
      />
    </header>
    <div class="common-details-cover">
      <div class="banner-bg">
        <div class="bg1"></div>
        <div class="bg2"></div>
        <div class="bg3"></div>
        <div class="bg4"></div>
      </div>
      <div class="form__wrapper">
        <div class="container">
          <div class="form__wrapper-content">
            <form @submit.prevent="referClient">
              <div class="form__header">
                <h1 class="page-heading">Refer a Client</h1>
                <div class="button">
                  <button
                    type="button"
                    @click="referClient"
                    :disabled="$v.$invalid"
                    class="btn btn-primary rounded download"
                  >
                    Send Referral
                    <i class="fa fa-user-plus"></i>
                  </button>
                </div>
              </div>
              <div class="form__body">
                <div class="make-copy">
                  <div class="form-group">
                    <div class="row">
                      <div class="form-input col-md-6">
                        <label class="asterisk">First Name</label>
                        <div class="input-field">
                          <i class="fa fa-user"></i>
                          <input
                            type="text"
                            v-model.trim="$v.firstName.$model"
                            placeholder="First Name"
                            :class="$v.firstName.$error ? 'is-invalid' : ''"
                          />
                        </div>
                        <span
                          v-if="$v.firstName.$error && !$v.firstName.required"
                          class="pl-10 pr-10 invalid-feedback"
                        >
                          First Name field is required
                        </span>
                      </div>
                      <div class="form-input col-md-6">
                        <label class="asterisk">Last Name</label>
                        <div class="input-field">
                          <i class="fa fa-user"></i>
                          <input
                            type="text"
                            v-model.trim="$v.lastName.$model"
                            placeholder="Last Name"
                            :class="$v.lastName.$error ? 'is-invalid' : ''"
                          />
                        </div>
                        <span
                          v-if="$v.lastName.$error && !$v.lastName.required"
                          class="pl-10 pr-10 invalid-feedback"
                        >
                          Last Name field is required
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="form-input col-md-6">
                        <label class="asterisk">Email</label>
                        <div class="input-field">
                          <i class="fa fa-envelope"></i>
                          <input
                            type="text"
                            v-model.trim="$v.email.$model"
                            placeholder="Email"
                            :class="$v.email.$error ? 'is-invalid' : ''"
                          />
                        </div>
                        <div v-if="$v.email.$error">
                          <span
                            v-if="!$v.email.required"
                            class="pl-10 pr-10 invalid-feedback"
                          >
                            Email field is required
                          </span>
                          <span
                            v-else-if="!$v.email.email"
                            class="pl-10 pr-10 invalid-feedback"
                          >
                            Please enter a valid email address
                          </span>
                        </div>
                      </div>
                      <div class="form-input col-md-6 mb-0">
                        <label class="asterisk">Phone</label>
                        <div class="input-field">
                          <i class="fa fa-phone-alt"></i>
                          <input
                            type="text"
                            v-model.trim="$v.phone.$model"
                            placeholder="Phone"
                            inputmode="tel"
                            :class="$v.phone.$error ? 'is-invalid' : ''"
                            @input="numberWithDash($event, phone, false)"
                          />
                        </div>
                        <div v-if="$v.phone.$error">
                          <span
                            v-if="!$v.phone.required"
                            class="pl-10 pr-10 invalid-feedback"
                          >
                            Phone field is required
                          </span>
                          <span
                            v-else-if="!$v.phone.minLength"
                            class="pl-10 pr-10 invalid-feedback"
                          >
                            Phone field must have a valid 10 digit number
                            (without Country code)
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="form-input col-12">
                        <label>Notes</label>
                        <div class="input-field">
                          <i class="fa fa-pencil-alt"></i>
                          <input
                            type="text"
                            v-model.trim="notes"
                            placeholder="Notes"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="divider mt-3"></div>
                </div>
                <div class="clone"></div>
                <div class="form-group" v-if="!additionalClient">
                  <button
                    @click="additionalClient = true"
                    type="button"
                    class="add-btn"
                    id="additional-client"
                  >
                    <i class="fa fa-plus"></i>
                    Add additional client
                  </button>
                </div>
                <div v-if="additionalClient" class="make-copy">
                  <div class="form-group">
                    <div class="row">
                      <div class="form-input col-md-6">
                        <label class="asterisk">First Name</label>
                        <div class="input-field">
                          <i class="fa fa-user"></i>
                          <input
                            type="text"
                            v-model.trim="$v.addon.firstName.$model"
                            placeholder="First Name"
                            :class="
                              $v.addon.firstName.$error ? 'is-invalid' : ''
                            "
                          />
                        </div>
                        <span
                          v-if="
                            $v.addon.firstName.$error &&
                            !$v.addon.firstName.required
                          "
                          class="pr-10 pl-10 invalid-feedback"
                        >
                          First Name field is required
                        </span>
                      </div>
                      <div class="form-input col-md-6">
                        <label class="asterisk">Last Name</label>
                        <div class="input-field">
                          <i class="fa fa-user"></i>
                          <input
                            type="text"
                            v-model.trim="$v.addon.lastName.$model"
                            placeholder="Last Name"
                            :class="
                              $v.addon.lastName.$error ? 'is-invalid' : ''
                            "
                          />
                        </div>
                        <span
                          v-if="
                            $v.addon.lastName.$error &&
                            !$v.addon.lastName.required
                          "
                          class="pr-10 pl-10 invalid-feedback"
                        >
                          Last Name field is required
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="form-input col-md-6">
                        <label>Email</label>
                        <div class="input-field">
                          <i class="fa fa-envelope"></i>
                          <input
                            type="text"
                            v-model.trim="$v.addon.email.$model"
                            placeholder="Email"
                            :class="$v.addon.email.$error ? 'is-invalid' : ''"
                          />
                        </div>
                        <span
                          v-if="$v.addon.email.$error && !$v.addon.email.email"
                          class="pr-10 pl-10 invalid-feedback"
                        >
                          Please enter a valid email address
                        </span>
                      </div>
                      <div class="form-input col-md-6 mb-0">
                        <label>Phone</label>
                        <div class="input-field">
                          <i class="fa fa-phone-alt"></i>
                          <input
                            type="text"
                            v-model.trim="$v.addon.phone.$model"
                            placeholder="Phone"
                            :class="$v.addon.phone.$error ? 'is-invalid' : ''"
                            @input="numberWithDash($event, addon.phone, true)"
                          />
                        </div>
                        <span
                          v-if="
                            $v.addon.phone.$error && !$v.addon.phone.minLength
                          "
                          class="pr-10 pl-10 invalid-feedback"
                        >
                          Phone field must have a valid 10 digit number (without
                          Country code)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="form-input col-12">
                        <label>Notes</label>
                        <div class="input-field">
                          <i class="fa fa-pencil-alt"></i>
                          <input
                            type="text"
                            v-model.trim="addon.notes"
                            placeholder="Notes"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <a
                    @click="additionalClient = false"
                    class="text-danger"
                    role="button"
                    ><i class="fa fa-minus-circle" aria-hidden="true"></i>
                    Remove</a
                  >
                  <div class="divider mt-3"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <ProfileInfo />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Toasters from "../mixins/toasters";
import Shades from "../mixins/shades";
import { eventBus } from "../event-bus";
import {
  required,
  requiredIf,
  email,
  minLength,
} from "vuelidate/lib/validators";
import InterestRateToolBar from "./InterestRateToolBar.vue";
import ProfileInfo from "./Profile-Info";

export default {
  name: "Refer-a-Client",
  mixins: [Toasters, Shades],
  components: { InterestRateToolBar, ProfileInfo },
  data() {
    return {
      additionalClient: false,
      preventNextIteration: false,
      addon: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        notes: "",
      },
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      notes: "",
    };
  },
  validations: {
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    email: {
      required,
      email,
    },
    phone: {
      required,
      minLength: minLength(12),
    },
    addon: {
      firstName: {
        required: requiredIf(function () {
          return this.additionalClient;
        }),
      },
      lastName: {
        required: requiredIf(function () {
          return this.additionalClient;
        }),
      },
      email: {
        email,
      },
      phone: {
        minLength: minLength(12),
      },
    },
  },
  watch: {
    additionalClient(newVal) {
      if (!newVal) {
        this.addon.firstName = "";
        this.addon.lastName = "";
        this.addon.email = "";
        this.addon.phone = "";
        this.addon.notes = "";
        this.$v.addon.$reset();
      }
    },
  },
  computed: {
    ...mapGetters(["getOrgId", "getProspectId", "getAssets"]),
  },
  methods: {
    async referClient() {
      eventBus.$emit("loader-status", true);

      const payLoad = {
        accountId: this.getProspectId,
        orgId: this.getOrgId,
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        phone: this.phone,
        notes: this.notes ? this.escapeForSalesforce(this.notes) : null,
        additionalClient: this.additionalClient,
        coEmail: this.additionalClient ? this.addon.email : null,
        coFirstName: this.additionalClient ? this.addon.firstName : null,
        coLastName: this.additionalClient ? this.addon.lastName : null,
        coPhone: this.additionalClient ? this.addon.phone : null,
        coNotes: this.additionalClient
          ? this.escapeForSalesforce(this.addon.notes)
          : null,
      };

      await this.$http
        .post("/referral/send", payLoad)
        .then((response) => {
          let resp = response.data;

          if (resp.status === 511) this.$router.push("/");

          if (resp.status === 200) {
            this.$confetti.start();
            setTimeout(() => {
              this.$confetti.stop();
            }, 7000);

            this.success("Thank you! Referral submitted successfully.");

            this.firstName = "";
            this.lastName = "";
            this.email = "";
            this.phone = "";
            this.notes = "";
            this.additionalClient = false;
            this.$v.$reset();
          }

          if (resp.status !== 511 && resp.status !== 200) {
            if (resp?.message && resp.message !== "") {
              this.error(resp.message);
            } else {
              this.error("Referral not submitted, lead info already received.");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });

      eventBus.$emit("loader-status", false);
    },

    escapeForSalesforce(input) {
      return input
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#39;")
        .replace(/\//g, "&#x2F;")
        .replace(/\\/g, "\\\\") // JSON escape
        .replace(/\n/g, "\\n") // Newline escape
        .replace(/\r/g, "\\r") // Carriage return escape
        .replace(/\t/g, "\\t") // Tab escape
        .replace(/[\u200B-\u200D\uFEFF]/g, ""); // Remove invisible characters
    },

    numberWithDash(event, number, addon) {
      if (["Arrow", "Backspace", "Shift"].includes(event.key)) {
        this.preventNextIteration = true;
        return;
      }

      if (this.preventNextIteration) {
        this.preventNextIteration = false;
        return;
      }

      if (number) {
        const x = number
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

        if (!addon)
          this.phone =
            x[1] + (x[2] ? "-" + x[2] : "") + (x[3] ? "-" + x[3] : "");
        else
          this.addon.phone =
            x[1] + (x[2] ? "-" + x[2] : "") + (x[3] ? "-" + x[3] : "");
      }
    },
  },
};
</script>
