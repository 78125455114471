<template>
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1H11M11 1V11M11 1L1 11"
        :stroke="color"
        stroke-width="1.5"
      />
    </svg>
  </template>
  
  <script>
  export default {
    name: 'UpArrowIcon',
    props: {
      color: {
        type: String,
        default: '#000000', // Default color if none is provided
      },
    },
  };
  </script>
  