<template>
  <div class="content__wrapper pb-0">
    <div v-if="!allClients.length && activeTab == 'All'">
      <header>
        <InterestRateToolBar
          :interest-rates="getAssets?.rateHistoryData?.[0] || {}"
        />
      </header>
      <div class="no-client">
        <NoClientsIcon />
        <h3>No clients found</h3>
        <p>
          Click here to start your client's mortgage journey and track their
          progress
        </p>
        <button
          type="button"
          class="btn btn-primary rounded"
          data-bs-toggle="modal"
          data-bs-target="#send-mortgage-app-modal"
          style="width: auto"
          @click="updateModalValue"
        >
          Add New Client
        </button>
      </div>
    </div>
    <template v-else>
      <header>
        <InterestRateToolBar
          :interest-rates="getAssets?.rateHistoryData?.[0] || {}"
        />

        <div class="container">
          <div class="title-search">
            <h1 class="page-heading">{{ getUser.accountName }}'s Pipeline</h1>
            <!-- Search Bar starts -->
            <div class="global__search d-flex align-items-center">
              <div class="form-input" style="flex: 1">
                <input
                  v-model="searchQuery"
                  type="text"
                  placeholder="Search clients in the Pipeline"
                  class="global__search-input"
                />
                <i class="fa fa-search zero-index"></i>
              </div>
              <button
                type="button"
                class="btn btn-primary rounded"
                data-bs-toggle="modal"
                data-bs-target="#send-mortgage-app-modal"
                style="width: auto; white-space: nowrap; margin-left: 15px"
                @click="updateModalValue"
              >
                Add Client
              </button>
            </div>
            <!-- Search Bar ends -->
          </div>
          <div class="tabs">
            <!-- Main tabs starts -->
            <ul class="nav nav-tabs global-tabs" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  @click="
                    activeTab = 'All';
                    selectedFilter = 'Pipeline';
                  "
                  class="nav-link"
                  :class="activeTab == 'All' ? 'active' : ''"
                >
                  <i class="fa fa-users"></i> All
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  @click="
                    activeTab = 'Nurtured';
                    selectedFilter = 'nurtured';
                    searchQuery = '';
                  "
                  class="nav-link"
                  :class="activeTab == 'Nurtured' ? 'active' : ''"
                >
                  <i class="fa fa-lightbulb"></i> Nurtured
                </button>
              </li>
            </ul>
            <!-- Main tabs ends -->
          </div>
        </div>
      </header>
      <main class="pipeline-cover-main">
        <div class="main__wrapper">
          <div class="container">
            <div class="tab-content">
              <!-- All Tab Starts -->
              <div
                :class="activeTab == 'All' ? 'show active' : ''"
                class="tab-pane fade"
              >
                <!-- All Tab Filter Starts -->
                <div class="filter my-3">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div class="filter__title">
                      <i class="fa fa-filter"></i>
                      Filter:
                    </div>
                    <div class="filter__dropdown">
                      <div class="dropdown">
                        <button
                          class="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {{ !selectedFilter ? "Any Status" : selectedFilter }}
                          <i class="fa fa-chevron-up"></i>
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li
                            v-for="(filter, index) in pipelineFilters"
                            :key="index"
                          >
                            <a
                              role="button"
                              class="dropdown-item"
                              @click="applyFilter(filter)"
                              >{{ filter }}</a
                            >
                          </li>
                          <li>
                            <a
                              role="button"
                              class="dropdown-item"
                              @click="applyFilter('')"
                              >Any Status</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- All Tab Filter Ends -->

                <div class="table__wrapper">
                  <!-- All Tab Desktop View Starts -->
                  <div class="table-responsive d-none d-sm-block">
                    <table class="table" v-if="filteredRecords.length">
                      <thead>
                        <tr>
                          <th></th>
                          <th role="button" @click="sort('clientName')">
                            Client Name &nbsp;<i
                              class="fas fa-chevron-down"
                            ></i>
                          </th>
                          <th></th>
                          <th role="button" @click="sort('purchasePrice')">
                            Details &nbsp;<i class="fas fa-chevron-down"></i>
                          </th>
                          <th class="text-center">Contact</th>
                          <th
                            role="button"
                            @click="sort('status')"
                            class="text-center"
                          >
                            Status &nbsp;<i class="fas fa-chevron-down"></i>
                          </th>
                          <th
                            v-if="extraDetailsofProspect[0]?.isParentAccount"
                            class="text-center"
                          >
                            Agent
                          </th>
                          <th class="text-center">Role</th>
                          <th class="text-center">Last Updated</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(client, index) in paginatedData">
                          <tr :key="'expanded' + index">
                            <td
                              @click="
                                openOffcanvas(index, client?.prospectOrDealId)
                              "
                              :class="isActive === index ? 'open' : ''"
                            >
                              <base-down-arrow></base-down-arrow>
                            </td>
                            <td
                              class="name"
                              :class="isActive === index ? 'open' : ''"
                              @click="
                                openOffcanvas(index, client?.prospectOrDealId)
                              "
                            >
                              {{ client.clientName | capitalize }}
                            </td>
                            <td>
                              <div class="extra-column-for-icons">
                                <span
                                  v-if="
                                    isScenarioVisible(client?.prospectOrDealId)
                                  "
                                  class="custom-tooltip cursor-pointer"
                                  data-tooltip="Mortgage estimate review available"
                                  @click="clientScenariosData(client)"
                                >
                                  <ScenariosIcon />
                                </span>
                                <span
                                  v-if="
                                    clientShowScenarios.find(
                                      (el) =>
                                        el.prospectOrDealId ===
                                        client.prospectOrDealId
                                    )?.amrCompletedDate
                                  "
                                  @click="getAMRResponse(client)"
                                  class="cursor-pointer"
                                >
                                  <AmrResponseIcon />
                                </span>
                              </div>
                            </td>
                            <td v-if="client.isDeal && client.street">
                              {{ client.street }}
                            </td>
                            <td
                              v-else-if="
                                client.purchasePrice && client.loanType
                              "
                            >
                              ${{ client.purchasePrice | formatNumber }}
                              {{ client.loanType }}
                            </td>
                            <td v-else>Not Available</td>
                            <td
                              class="text-center"
                              :class="isActive === index ? 'open' : ''"
                            >
                              <img
                                v-if="client.contactProfilePhotoURL"
                                :src="client.contactProfilePhotoURL"
                                class="contact"
                                alt="user avatar"
                              />
                              <img v-else :src="defaultAvatar()" />
                            </td>
                            <td class="text-center">
                              <div
                                class="status rounded custom-tooltip"
                                :data-tooltip="textForToolTip(client)"
                                :class="classForFilters(client.status)"
                              >
                                {{
                                  !client.isAttorneyState &&
                                  client.status === "Under Contract"
                                    ? "In Escrow"
                                    : client.status
                                }}
                              </div>
                            </td>
                            <td
                              v-if="extraDetailsofProspect[0]?.isParentAccount"
                              class="d-flex justify-content-center"
                            >
                              <div class="agent-container">
                                <p class="agent-initial">
                                  {{ agentNameFormat(client?.buyersAgentName) }}
                                </p>
                              </div>
                            </td>
                            <td class="text-center">
                              <div class="status outline-skyblue rounded">
                                {{ client.role }}
                              </div>
                            </td>
                            <td
                              v-if="client.lastUpdatedDate"
                              class="text-center"
                            >
                              {{ client.lastUpdatedDate | formatDate }}
                            </td>
                            <td v-else class="text-center">N/A</td>
                          </tr>

                          <!-- Start of Offcanvas component -->
                          <div
                            v-if="activeTab === 'All'"
                            :key="'offcanvas-' + index"
                            class="offcanvas offcanvas-end prospect-offcanvas"
                            tabindex="-1"
                            :id="'offcanvasTop-' + index"
                            :aria-labelledby="'offcanvasTopLabel-' + index"
                          >
                            <div class="offcanvas-header">
                              <h5
                                class="offcanvas-title"
                                :id="'offcanvasTopLabel-' + index"
                                style="font-weight: 600"
                              >
                                {{ client?.clientName }}
                                <span style="color: #848692; font-weight: 600">
                                  /
                                </span>
                                <span
                                  :style="{
                                    color: getAssets.primaryBrandColor,
                                    fontWeight: 600,
                                  }"
                                  >{{ client?.role }}</span
                                >
                              </h5>
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                                @click="isActive = ''"
                              ></button>
                            </div>
                            <div class="offcanvas-body">
                              <!-- Tabs -->
                              <ul
                                class="nav nav-tabs global-tabs border-bottom"
                                :id="'myTab-' + index"
                                role="tablist"
                                style="margin-bottom: 1rem"
                              >
                                <li class="nav-item" role="presentation">
                                  <button
                                    class="nav-link"
                                    :class="{
                                      'active text-danger font-weight-bold':
                                        prospectTabs[index] === 'details',
                                    }"
                                    :id="'details-tab-' + index"
                                    @click="
                                      setProspectActiveTab('details', index)
                                    "
                                    data-bs-toggle="tab"
                                    :data-bs-target="
                                      '#details-tab-pane-' + index
                                    "
                                    type="button"
                                    role="tab"
                                    :aria-controls="'details-tab-pane-' + index"
                                    :aria-selected="
                                      prospectTabs[index] === 'details'
                                    "
                                  >
                                    Details
                                  </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                  <button
                                    class="nav-link"
                                    :class="{
                                      'active text-danger font-weight-bold':
                                        prospectTabs[index] === 'contact',
                                    }"
                                    :id="'contact-tab-' + index"
                                    @click="
                                      setProspectActiveTab('contact', index)
                                    "
                                    data-bs-toggle="tab"
                                    :data-bs-target="
                                      '#contact-tab-pane-' + index
                                    "
                                    type="button"
                                    role="tab"
                                    :aria-controls="'contact-tab-pane-' + index"
                                    :aria-selected="
                                      prospectTabs[index] === 'contact'
                                    "
                                  >
                                    Contact
                                  </button>
                                </li>
                              </ul>
                              <!-- Tab Content -->
                              <div
                                class="tab-content"
                                :id="'myTabContent-' + index"
                              >
                                <div
                                  class="tab-pane fade show active"
                                  :id="'details-tab-pane-' + index"
                                  role="tabpanel"
                                  :aria-labelledby="'details-tab-' + index"
                                  tabindex="0"
                                >
                                  <div class="">
                                    <div class="row">
                                      <div class="col-12">
                                        <h6
                                          class="prospect-section-title mt-3 prospect-data-heading"
                                        >
                                          Details
                                        </h6>
                                      </div>
                                    </div>
                                    <!-- Details Section -->
                                    <div
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">
                                          <span v-if="replaceTheFields(client)"
                                            >Close Date</span
                                          >
                                          <span v-else>Max Approval</span>
                                        </p>
                                      </div>
                                      <div class="col-3">
                                        <p class="name prospect-data-answer">
                                          <span v-if="replaceTheFields(client)">
                                            <span v-if="client.closeDate">{{
                                              client.closeDate | formatDate
                                            }}</span>
                                            <span v-else>Not Available</span>
                                          </span>
                                          <span v-else>
                                            <span v-if="client.maxApproval">
                                              ${{
                                                client.maxApproval
                                                  | formatNumber
                                              }}
                                            </span>
                                            <span v-else>Not Available</span>
                                          </span>
                                        </p>
                                      </div>
                                      <div
                                        v-if="
                                          isScenarioVisible(
                                            client?.prospectOrDealId
                                          )
                                        "
                                        class="col-4 d-flex align-items-center"
                                      >
                                        <a
                                          href="#"
                                          class="d-flex align-items-center prospect-sub-heading"
                                          @click.stop="
                                            clientScenariosData(client)
                                          "
                                        >
                                          <img
                                            src="../assets/images/mortgageCalculator.svg"
                                            alt="Mortgage Calculator"
                                            class="prospect-data-email-icon"
                                          />
                                          Mortgage Calculator
                                          <UpArrowIcon
                                            :color="getAssets.primaryBrandColor"
                                            class="prospect-data-email-img"
                                          />
                                        </a>
                                      </div>
                                    </div>
                                    <div
                                      v-if="client?.status !== 'Closed'"
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">
                                          <span v-if="replaceTheFields(client)"
                                            >Appraisal Status</span
                                          >
                                          <span v-else>Down Payment</span>
                                        </p>
                                      </div>
                                      <div class="col-3">
                                        <p class="name prospect-data-answer">
                                          <span v-if="replaceTheFields(client)">
                                            <span v-if="client.appraisalStage">
                                              <span
                                                v-if="appraisalCond(client)"
                                              >
                                                {{
                                                  client.appraisalStage ===
                                                  "TBD"
                                                    ? "Pending Order"
                                                    : client.role === "Listing"
                                                    ? "Completed"
                                                    : client.appraisalStage
                                                }}
                                              </span>
                                              <span
                                                v-if="
                                                  client.appraisalStage !==
                                                    'TBD' &&
                                                  client.appraisalStage !==
                                                    'Completed'
                                                "
                                                class="whitespace-pre-line"
                                              >
                                                <br />{{
                                                  "Ordered: " +
                                                  orderedDate(client)
                                                }}
                                                <br />{{
                                                  "Due Date: " + dueDate(client)
                                                }}
                                              </span>
                                            </span>
                                            <span v-else>Pending Order</span>
                                          </span>
                                          <span v-else>
                                            <span
                                              v-if="
                                                client.downPayment ||
                                                client.downPayment !== null
                                              "
                                            >
                                              {{ client.downPayment }}%
                                            </span>
                                            <span v-else>Not Available</span>
                                          </span>
                                        </p>
                                      </div>
                                      <div
                                        class="col-4 d-flex align-items-center"
                                      >
                                        <a
                                          href="/admin/approval-letters"
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <img
                                            src="../assets/images/approvalLetter.svg"
                                            alt="Approval Letter"
                                            class="prospect-data-email-icon"
                                          />
                                          Approval Letter
                                          <UpArrowIcon
                                            :color="getAssets.primaryBrandColor"
                                            class="prospect-data-email-img"
                                          />
                                        </a>
                                      </div>
                                    </div>
                                    <div
                                      v-if="client?.note"
                                      class="row align-items-start border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">
                                          <strong>Notes</strong>
                                        </p>
                                      </div>
                                      <div class="col-7">
                                        <p
                                          class="prospect-note"
                                          style="
                                            font-weight: 600;
                                            color: #222222;
                                            font-size: 15px;
                                          "
                                        >
                                          {{ client?.note }}
                                        </p>
                                      </div>
                                    </div>
                                    <!-- Divider -->
                                    <div class="row">
                                      <div class="col-12">
                                        <h6
                                          class="prospect-section-title prospect-data-heading"
                                          style="
                                            padding-top: 32px;
                                            padding-bottom: 8px;
                                          "
                                        >
                                          <strong>{{
                                            client.status !== "Closed"
                                              ? "Lender Contact Information"
                                              : "LO Contact Information"
                                          }}</strong>
                                        </h6>
                                      </div>
                                    </div>
                                    <div
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">
                                          <strong>Name</strong>
                                        </p>
                                      </div>
                                      <div
                                        class="col-7 d-flex align-items-center"
                                      >
                                        <img
                                          :src="
                                            client.status !== 'Closed'
                                              ? client?.contactProfilePhotoURL
                                              : getUser?.profilePhotoURL
                                          "
                                          :alt="
                                            client.status !== 'Closed'
                                              ? client?.contactName
                                              : getUser?.name
                                          "
                                          style="
                                            width: 24px;
                                            height: 24px;
                                            margin-right: 5px;
                                          "
                                        />
                                        <span>{{
                                          client.status !== "Closed"
                                            ? client?.contactName
                                            : getUser?.name
                                        }}</span>
                                      </div>
                                    </div>

                                    <div
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">
                                          <strong>Chat</strong>
                                        </p>
                                      </div>
                                      <div class="col-7">
                                        <a
                                          href="#"
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <i
                                            class="fa fa-comment prospect-data-icon"
                                          ></i>
                                          <span>{{
                                            client.status !== "Closed"
                                              ? client?.contactName
                                              : getUser?.name
                                          }}</span>
                                        </a>
                                      </div>
                                    </div>

                                    <div
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">
                                          <strong>Phone</strong>
                                        </p>
                                      </div>
                                      <div class="col-7">
                                        <a
                                          :href="
                                            'tel:' + client.status !== 'Closed'
                                              ? client?.contactNumber
                                              : getUser?.phone
                                          "
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <i
                                            class="fa fa-phone-alt prospect-data-icon"
                                          ></i>
                                          <span>{{
                                            client.status !== "Closed"
                                              ? client?.contactNumber
                                              : getUser?.phone
                                          }}</span>
                                        </a>
                                      </div>
                                    </div>

                                    <div
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">
                                          <strong>Email</strong>
                                        </p>
                                      </div>
                                      <div class="col-7">
                                        <a
                                          :href="
                                            'mailto:' + client.status !==
                                            'Closed'
                                              ? client?.contactEmail
                                              : getUser?.email
                                          "
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <img
                                            src="../assets/images/email-icon.svg"
                                            alt="Email"
                                            class="prospect-data-email-icon"
                                          />
                                          <span>{{
                                            client.status !== "Closed"
                                              ? client?.contactEmail
                                              : getUser?.email
                                          }}</span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!-- Prospect Contact details -->
                                <div
                                  class="tab-pane fade"
                                  :id="'contact-tab-pane-' + index"
                                  role="tabpanel"
                                  :aria-labelledby="'contact-tab-' + index"
                                  tabindex="0"
                                >
                                  <!-- Borrower Contact Information -->
                                  <div
                                    v-if="
                                      prospectContactData?.phoneB ||
                                      prospectContactData?.emailB ||
                                      prospectContactData?.nameB
                                    "
                                  >
                                    <div class="row">
                                      <div class="col-12">
                                        <h6
                                          class="prospect-section-title mt-3 prospect-data-heading"
                                        >
                                          Client Contact Information
                                        </h6>
                                      </div>
                                    </div>

                                    <div
                                      v-if="prospectContactData?.nameB"
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Name</p>
                                      </div>
                                      <div
                                        class="col-7 d-flex align-items-center"
                                      >
                                        <div class="initials-circle">
                                          {{
                                            getInitials(
                                              prospectContactData?.nameB
                                            )
                                          }}
                                        </div>
                                        <p class="name prospect-sub-heading">
                                          <span>{{
                                            prospectContactData?.nameB
                                          }}</span>
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      v-if="prospectContactData?.phoneB"
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Phone</p>
                                      </div>
                                      <div class="col-7">
                                        <a
                                          :href="
                                            'tel:' + prospectContactData?.phoneB
                                          "
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <i
                                            class="fa fa-phone-alt prospect-data-icon"
                                          ></i>
                                          <span>{{
                                            prospectContactData?.phoneB
                                          }}</span>
                                        </a>
                                      </div>
                                    </div>
                                    <div
                                      v-if="prospectContactData?.emailB"
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Email</p>
                                      </div>
                                      <div class="col-7">
                                        <a
                                          :href="
                                            'mailto:' +
                                            prospectContactData?.emailB
                                          "
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <i
                                            class="fa fa-envelope prospect-data-icon"
                                          ></i>
                                          <span>{{
                                            prospectContactData?.emailB
                                          }}</span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- Co-borrower Contact Information -->
                                  <div
                                    v-if="
                                      prospectContactData?.phoneCo &&
                                      prospectContactData?.emailCo &&
                                      prospectContactData?.nameCo
                                    "
                                  >
                                    <div class="row">
                                      <div class="col-12">
                                        <h6
                                          class="prospect-section-title mt-3 prospect-data-heading"
                                        >
                                          Client Contact Information
                                        </h6>
                                      </div>
                                    </div>
                                    <div
                                      v-if="prospectContactData?.nameCo"
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Name</p>
                                      </div>
                                      <div
                                        class="col-7 d-flex align-items-center"
                                      >
                                        <div class="initials-circle">
                                          {{
                                            getInitials(
                                              prospectContactData?.nameCo
                                            )
                                          }}
                                        </div>
                                        <p class="name prospect-sub-heading">
                                          <span>{{
                                            prospectContactData?.nameCo
                                          }}</span>
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      v-if="prospectContactData?.phoneCo"
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Phone</p>
                                      </div>
                                      <div class="col-7">
                                        <a
                                          :href="
                                            'tel:' +
                                            prospectContactData?.phoneCo
                                          "
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <i
                                            class="fa fa-phone-alt prospect-data-icon"
                                          ></i>
                                          <span>{{
                                            prospectContactData?.phoneCo
                                          }}</span>
                                        </a>
                                      </div>
                                    </div>
                                    <div
                                      v-if="prospectContactData?.emailCo"
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Email</p>
                                      </div>
                                      <div class="col-7">
                                        <a
                                          :href="
                                            'mailto:' +
                                            prospectContactData?.emailCo
                                          "
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <i
                                            class="fa fa-envelope prospect-data-icon"
                                          ></i>
                                          <span>{{
                                            prospectContactData?.emailCo
                                          }}</span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- CPA Contact Information -->
                                  <div
                                    v-if="
                                      prospectContactData?.cpaAccount
                                        ?.cpaName &&
                                      prospectContactData?.cpaAccount
                                        ?.cpaPhone &&
                                      prospectContactData?.cpaAccount?.cpaEmail
                                    "
                                  >
                                    <div class="row">
                                      <div class="col-12">
                                        <h6
                                          class="prospect-section-title mt-3 prospect-data-heading"
                                        >
                                          CPA/Accountant Contact Info
                                        </h6>
                                      </div>
                                    </div>

                                    <div
                                      v-if="
                                        prospectContactData?.cpaAccount?.cpaName
                                      "
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Name</p>
                                      </div>
                                      <div
                                        class="col-7 d-flex align-items-center"
                                      >
                                        <div class="initials-circle">
                                          {{
                                            getInitials(
                                              prospectContactData?.cpaAccount
                                                ?.cpaName
                                            )
                                          }}
                                        </div>
                                        <p class="name prospect-sub-heading">
                                          <span>{{
                                            prospectContactData?.cpaAccount
                                              ?.cpaName
                                          }}</span>
                                        </p>
                                      </div>
                                    </div>

                                    <div
                                      v-if="
                                        prospectContactData?.cpaAccount
                                          ?.cpaPhone
                                      "
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Phone</p>
                                      </div>
                                      <div class="col-7">
                                        <a
                                          :href="
                                            'tel:' +
                                            prospectContactData?.cpaAccount
                                              ?.cpaPhone
                                          "
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <i
                                            class="fa fa-phone-alt prospect-data-icon"
                                          ></i>
                                          <span>{{
                                            prospectContactData?.cpaAccount
                                              ?.cpaPhone
                                          }}</span>
                                        </a>
                                      </div>
                                    </div>

                                    <div
                                      v-if="
                                        prospectContactData?.cpaAccount
                                          ?.cpaEmail
                                      "
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Email</p>
                                      </div>
                                      <div class="col-7">
                                        <a
                                          :href="
                                            'mailto:' +
                                            prospectContactData?.cpaAccount
                                              ?.cpaEmail
                                          "
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <i
                                            class="fa fa-envelope prospect-data-icon"
                                          ></i>
                                          <span>{{
                                            prospectContactData?.cpaAccount
                                              ?.cpaEmail
                                          }}</span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>

                                  <!-- CFP Contact Information -->
                                  <div
                                    v-if="
                                      prospectContactData?.cfpAccount
                                        ?.cfpName &&
                                      prospectContactData?.cfpAccount
                                        ?.cfpPhone &&
                                      prospectContactData?.cfpAccount?.cfpEmail
                                    "
                                  >
                                    <div class="row">
                                      <div class="col-12">
                                        <h6
                                          class="prospect-section-title mt-3 prospect-data-heading"
                                        >
                                          Financial Planner Contact Info
                                        </h6>
                                      </div>
                                    </div>

                                    <div
                                      v-if="
                                        prospectContactData?.cfpAccount?.cfpName
                                      "
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Name</p>
                                      </div>
                                      <div
                                        class="col-7 d-flex align-items-center"
                                      >
                                        <div class="initials-circle">
                                          {{
                                            getInitials(
                                              prospectContactData?.cfpAccount
                                                ?.cfpName
                                            )
                                          }}
                                        </div>
                                        <p class="name prospect-sub-heading">
                                          <span>{{
                                            prospectContactData?.cfpAccount
                                              ?.cfpName
                                          }}</span>
                                        </p>
                                      </div>
                                    </div>

                                    <div
                                      v-if="
                                        prospectContactData?.cfpAccount
                                          ?.cfpPhone
                                      "
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Phone</p>
                                      </div>
                                      <div class="col-7">
                                        <a
                                          :href="
                                            'tel:' +
                                            prospectContactData?.cfpAccount
                                              ?.cfpPhone
                                          "
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <i
                                            class="fa fa-phone-alt prospect-data-icon"
                                          ></i>
                                          <span>{{
                                            prospectContactData?.cfpAccount
                                              ?.cfpPhone
                                          }}</span>
                                        </a>
                                      </div>
                                    </div>

                                    <div
                                      v-if="
                                        prospectContactData?.cfpAccount
                                          ?.cfpEmail
                                      "
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Email</p>
                                      </div>
                                      <div class="col-7">
                                        <a
                                          :href="
                                            'mailto:' +
                                            prospectContactData?.cfpAccount
                                              ?.cfpEmail
                                          "
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <i
                                            class="fa fa-envelope prospect-data-icon"
                                          ></i>
                                          <span>{{
                                            prospectContactData?.cfpAccount
                                              ?.cfpEmail
                                          }}</span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- End of Offcanvas component -->
                        </template>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="7">
                            <div class="pagination">
                              <div class="pagination__btn">
                                <button
                                  type="button"
                                  :class="currentPage === 1 ? 'disabled' : ''"
                                  @click="prevPage"
                                  class="pagination__btn-prev button"
                                >
                                  <img
                                    src="../assets/images/prev-icon.svg"
                                    class="previous icon"
                                  />
                                </button>
                                <button
                                  type="button"
                                  :class="
                                    currentPage === totalPages ? 'disabled' : ''
                                  "
                                  @click="nextPage"
                                  class="pagination__btn-next button"
                                >
                                  <img
                                    src="../assets/images/prev-icon.svg"
                                    class="next icon"
                                  />
                                </button>
                              </div>
                              <label
                                >{{ recordRange }} of
                                {{ filteredRecords.length }}
                                items</label
                              >
                            </div>
                          </td>
                        </tr>
                      </tfoot>
                    </table>

                    <table
                      v-else
                      class="table text-center text-secondary"
                      style="height: 220px"
                    >
                      <h1 class="fw-bolder">No Data Available</h1>
                    </table>
                  </div>
                  <!-- All Tab Desktop View Ends -->

                  <!-- All Tab Mobile View Starts -->
                  <div
                    v-if="filteredRecords.length"
                    class="table__card d-block d-sm-none"
                  >
                    <div
                      class="card"
                      v-for="(client, index) in paginatedData"
                      :key="index"
                    >
                      <div class="table-row">
                        <label>Client Name</label>
                        <div class="d-flex justify-content-between">
                          <p>{{ client.clientName | capitalize }}</p>
                          <div
                            @click="isActive = isActive === index ? '' : index"
                          >
                            <base-down-arrow></base-down-arrow>
                          </div>
                        </div>
                      </div>
                      <div class="table-row">
                        <label>Details</label>
                        <p v-if="client.isDeal && client.street">
                          {{ client.street }}
                        </p>
                        <p v-else-if="client.purchasePrice && client.loanType">
                          ${{ client.purchasePrice | formatNumber }}
                          {{ client.loanType }}
                        </p>
                        <p v-else>Not Available</p>
                      </div>
                      <slide-up-down
                        class="card"
                        :active="isActive === index"
                        :duration="1000"
                      >
                        <div class="table-row">
                          <div class="d-flex">
                            <div v-if="replaceTheFields(client)" class="box">
                              <label>Close Date</label>
                              <p v-if="client.closeDate">
                                {{ client.closeDate | formatDate }}
                              </p>
                              <p v-else>Not Available</p>
                            </div>
                            <div v-else class="box">
                              <label>Max Approval</label>
                              <p v-if="client.maxApproval">
                                ${{ client.maxApproval | formatNumber }}
                              </p>
                              <p v-else>Not Available</p>
                            </div>
                            <div v-if="replaceTheFields(client)" class="box">
                              <template v-if="client.status !== 'Closed'">
                                <label>Appraisal Status</label>
                                <p v-if="client.appraisalStage">
                                  <span v-if="appraisalCond(client)">{{
                                    client.appraisalStage === "TBD"
                                      ? "Pending Order"
                                      : client.role === "Listing"
                                      ? "Completed"
                                      : client.appraisalStage
                                  }}</span>
                                  <span
                                    v-if="
                                      client.appraisalStage !== 'TBD' &&
                                      client.appraisalStage !== 'Completed'
                                    "
                                    class="whitespace-pre-line"
                                  >
                                    <br />
                                    {{ "Ordered: " }}<br />
                                    {{ orderedDate(client) }}
                                    <br />
                                    {{ "Due Date: " }} <br />
                                    {{ dueDate(client) }}
                                  </span>
                                </p>
                                <p v-else>Pending Order</p>
                              </template>
                            </div>
                            <div v-else class="box">
                              <label>Down Payment</label>
                              <p
                                v-if="
                                  client.downPayment ||
                                  client.downPayment !== null
                                "
                              >
                                {{ client.downPayment }}%
                              </p>
                              <p v-else>Not Available</p>
                            </div>
                          </div>
                        </div>
                        <div v-if="replaceTheFields(client)" class="table-row">
                          <div class="d-flex">
                            <div class="box">
                              <label>CD Status</label>
                              <p v-if="client.cDStage">{{ client.cDStage }}</p>
                              <p v-else>Not Available</p>
                            </div>
                            <div class="box" v-if="client.role !== 'Listing'">
                              <label>Ratelock Expiration</label>
                              <p v-if="client.ratelockExpiration">
                                {{ client.ratelockExpiration | formatDate }}
                              </p>
                              <p v-else>Not Available</p>
                            </div>
                          </div>
                        </div>
                        <div v-else class="table-row">
                          <label>Notes</label>
                          <p v-if="client.note">
                            {{ client.note }}
                          </p>
                          <p v-else>Not Available</p>
                        </div>
                        <div
                          v-if="isScenarioVisible(client?.prospectOrDealId)"
                          class="table-row"
                        >
                          <label>Estimate / Scenario</label>
                          <p v-if="isScenarioVisible(client?.prospectOrDealId)">
                            <span style="cursor: pointer"
                              ><img
                                src="../assets/images/eye-icon.svg"
                                alt="cross icon"
                                @click.stop="clientScenariosData(client)"
                            /></span>
                          </p>
                          <p v-else>Not Available</p>
                        </div>
                        <div class="table-row contact-row">
                          <label>Contact</label>
                          <p>
                            <img
                              v-if="
                                client.status !== 'Closed'
                                  ? client.contactProfilePhotoURL
                                  : getUser.profilePhotoURL
                              "
                              :src="
                                client.status !== 'Closed'
                                  ? client.contactProfilePhotoURL
                                  : getUser.profilePhotoURL
                              "
                              alt="user image"
                            />
                            <img v-else :src="defaultAvatar()" />
                          </p>
                          <div class="d-flex">
                            <div class="box">
                              <label><i class="fa fa-comment"></i></label>
                              <p>
                                <a href="#">{{
                                  client.status !== "Closed"
                                    ? client.contactName
                                    : getUser.name
                                }}</a>
                              </p>
                            </div>
                            <div class="box">
                              <label><i class="fa fa-phone-alt"></i></label>
                              <p>
                                <a
                                  :href="
                                    'tel:' + client.status !== 'Closed'
                                      ? client.contactNumber
                                      : getUser.phone
                                  "
                                  >{{
                                    client.status !== "Closed"
                                      ? client.contactNumber
                                      : getUser.phone
                                  }}</a
                                >
                              </p>
                            </div>
                          </div>
                          <div class="d-flex">
                            <div class="box">
                              <label><i class="fa fa-envelope"></i></label>
                              <p>
                                <a
                                  :href="
                                    'mailto:' + client.status !== 'Closed'
                                      ? client.contactEmail
                                      : getUser.email
                                  "
                                  >{{
                                    client.status !== "Closed"
                                      ? client.contactEmail
                                      : getUser.email
                                  }}</a
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                      </slide-up-down>
                      <div class="table-row">
                        <div class="d-flex flex-nowrap justify-content-between">
                          <label>Status</label>
                          <div
                            class="status rounded custom-tooltip"
                            :data-tooltip="textForToolTip(client)"
                            :class="classForFilters(client.status)"
                          >
                            {{
                              !client.isAttorneyState &&
                              client.status === "Under Contract"
                                ? "In Escrow"
                                : client.status
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="table-row">
                        <div class="d-flex flex-nowrap justify-content-between">
                          <label>Role</label>
                          <div class="status outline-skyblue rounded">
                            {{ client.role }}
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="extraDetailsofProspect[0]?.isParentAccount"
                        class="table-row"
                      >
                        <div class="d-flex flex-nowrap justify-content-between">
                          <label>Agent</label>
                          <div class="agent-container">
                            <p class="agent-initial">
                              {{ agentNameFormat(client?.buyersAgentName) }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="table-row">
                        <div class="d-flex flex-nowrap justify-content-between">
                          <label>Last Updated</label>
                          <p v-if="client.lastUpdatedDate">
                            {{ client.lastUpdatedDate | formatDate }}
                          </p>
                          <p v-else>N/A</p>
                        </div>
                      </div>
                      <div class="table-row">
                        <div class="icon-card-set">
                          <span
                            v-if="isScenarioVisible(client?.prospectOrDealId)"
                            class="custom-tooltip cursor-pointer"
                            data-tooltip="Mortgage estimate review available"
                            @click="clientScenariosData(client)"
                            ><ScenariosIcon
                          /></span>
                          <span
                            v-if="
                              clientShowScenarios.find(
                                (el) =>
                                  el.prospectOrDealId ===
                                  client.prospectOrDealId
                              )?.amrCompletedDate
                            "
                            @click="getAMRResponse(client)"
                            class="cursor-pointer"
                          >
                            <AmrResponseIcon />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="pagination">
                      <div class="pagination__btn">
                        <button
                          @click="prevPage"
                          type="button"
                          :class="currentPage === 1 ? 'disabled' : ''"
                          class="pagination__btn-prev button"
                        >
                          <img
                            src="../assets/images/prev-icon.svg"
                            class="previous icon"
                          />
                        </button>
                        <button
                          @click="nextPage"
                          :class="currentPage === totalPages ? 'disabled' : ''"
                          type="button"
                          class="pagination__btn-next button"
                        >
                          <img
                            src="../assets/images/prev-icon.svg"
                            class="next icon"
                          />
                        </button>
                      </div>
                      <label
                        >{{ recordRange }} of
                        {{ filteredRecords.length }}
                        items</label
                      >
                    </div>
                  </div>
                  <div
                    v-else
                    class="table__card d-block d-sm-none text-center text-secondary"
                  >
                    <h1 class="fw-bolder">No Data Available</h1>
                  </div>
                  <!-- All Tab Mobile View End -->
                </div>
              </div>
              <!-- All Tab Ends -->

              <!-- Nurture Tab Starts -->
              <div
                :class="activeTab == 'Nurtured' ? 'show active' : ''"
                class="tab-pane fade"
              >
                <div class="mt-3 table__wrapper">
                  <!-- Nurture Tab Desktop View Starts -->
                  <div class="table-responsive d-none d-sm-block">
                    <table class="table" v-if="filteredRecords.length">
                      <thead>
                        <tr>
                          <th></th>
                          <th role="button" @click="sort('clientName')">
                            Client Name &nbsp;<i
                              class="fas fa-chevron-down"
                            ></i>
                          </th>
                          <th></th>
                          <th role="button" @click="sort('purchasePrice')">
                            Details &nbsp;<i class="fas fa-chevron-down"></i>
                          </th>
                          <th class="text-center">Contact</th>
                          <th class="text-center">Status</th>
                          <th
                            v-if="extraDetailsofProspect[0]?.isParentAccount"
                            class="text-center"
                          >
                            Agent
                          </th>
                          <th class="text-center">Role</th>
                          <th class="text-center">Last Updated</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(client, index) in paginatedData">
                          <tr :key="'expanded' + index">
                            <td
                              @click="
                                openOffcanvas(index, client?.prospectOrDealId)
                              "
                              :class="isActive === index ? 'open' : ''"
                            >
                              <base-down-arrow></base-down-arrow>
                            </td>
                            <td
                              @click="
                                openOffcanvas(index, client?.prospectOrDealId)
                              "
                              class="name"
                              :class="isActive === index ? 'open' : ''"
                            >
                              {{ client.clientName | capitalize }}
                            </td>
                            <td>
                              <div class="extra-column-for-icons">
                                <span
                                  v-if="
                                    isScenarioVisible(client?.prospectOrDealId)
                                  "
                                  class="custom-tooltip cursor-pointer"
                                  data-tooltip="Mortgage estimate review available"
                                  @click="clientScenariosData(client)"
                                >
                                  <ScenariosIcon />
                                </span>
                                <span
                                  v-if="
                                    clientShowScenarios.find(
                                      (el) =>
                                        el.prospectOrDealId ===
                                        client.prospectOrDealId
                                    )?.amrCompletedDate
                                  "
                                  @click="getAMRResponse(client)"
                                  class="cursor-pointer"
                                >
                                  <AmrResponseIcon />
                                </span>
                              </div>
                            </td>
                            <td v-if="client.isDeal && client.street">
                              {{ client.street }}
                            </td>
                            <td
                              v-else-if="
                                client.purchasePrice && client.loanType
                              "
                            >
                              ${{ client.purchasePrice | formatNumber }}
                              {{ client.loanType }}
                            </td>
                            <td v-else>Not Available</td>
                            <td
                              class="text-center"
                              :class="isActive === index ? 'open' : ''"
                            >
                              <img
                                v-if="client.contactProfilePhotoURL"
                                :src="client.contactProfilePhotoURL"
                                class="contact"
                                alt="user avatar"
                              />
                              <img v-else :src="defaultAvatar()" />
                            </td>
                            <td class="text-center">
                              <div class="status rounded nurture">
                                {{ client.status }}
                              </div>
                            </td>
                            <td
                              v-if="extraDetailsofProspect[0]?.isParentAccount"
                              class="d-flex justify-content-center"
                            >
                              <div class="agent-container">
                                <p class="agent-initial">
                                  {{ agentNameFormat(client?.buyersAgentName) }}
                                </p>
                              </div>
                            </td>
                            <td class="text-center">
                              <div class="status outline-skyblue rounded">
                                {{ client.role }}
                              </div>
                            </td>
                            <td
                              v-if="client.lastUpdatedDate"
                              class="text-center"
                            >
                              {{ client.lastUpdatedDate | formatDate }}
                            </td>
                            <td v-else class="text-center">N/A</td>
                          </tr>

                          <!-- Start of Offcanvas component -->
                          <div
                            v-if="activeTab === 'Nurtured'"
                            :key="'offcanvas-' + index"
                            class="offcanvas offcanvas-end prospect-offcanvas"
                            tabindex="-1"
                            :id="'offcanvasTop-' + index"
                            :aria-labelledby="'offcanvasTopLabel-' + index"
                          >
                            <div class="offcanvas-header">
                              <h5
                                class="offcanvas-title"
                                :id="'offcanvasTopLabel-' + index"
                                style="font-weight: 600"
                              >
                                {{ client?.clientName }}
                                <span style="color: #848692; font-weight: 600">
                                  /
                                </span>
                                <span
                                  :style="{
                                    color: getAssets.primaryBrandColor,
                                    fontWeight: 600,
                                  }"
                                  >{{ client?.role }}</span
                                >
                              </h5>
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                                @click="isActive = ''"
                              ></button>
                            </div>
                            <div class="offcanvas-body">
                              <!-- Tabs -->
                              <ul
                                class="nav nav-tabs global-tabs border-bottom"
                                :id="'myTab-' + index"
                                role="tablist"
                                style="margin-bottom: 1rem"
                              >
                                <li class="nav-item" role="presentation">
                                  <button
                                    class="nav-link"
                                    :class="{
                                      'active text-danger font-weight-bold':
                                        prospectTabs[index] === 'details',
                                    }"
                                    :id="'details-tab-' + index"
                                    @click="
                                      setProspectActiveTab('details', index)
                                    "
                                    data-bs-toggle="tab"
                                    :data-bs-target="
                                      '#details-tab-pane-' + index
                                    "
                                    type="button"
                                    role="tab"
                                    :aria-controls="'details-tab-pane-' + index"
                                    :aria-selected="
                                      prospectTabs[index] === 'details'
                                    "
                                  >
                                    Details
                                  </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                  <button
                                    class="nav-link"
                                    :class="{
                                      'active text-danger font-weight-bold':
                                        prospectTabs[index] === 'contact',
                                    }"
                                    :id="'contact-tab-' + index"
                                    @click="
                                      setProspectActiveTab('contact', index)
                                    "
                                    data-bs-toggle="tab"
                                    :data-bs-target="
                                      '#contact-tab-pane-' + index
                                    "
                                    type="button"
                                    role="tab"
                                    :aria-controls="'contact-tab-pane-' + index"
                                    :aria-selected="
                                      prospectTabs[index] === 'contact'
                                    "
                                  >
                                    Contact
                                  </button>
                                </li>
                              </ul>

                              <!-- Tab Content -->
                              <div
                                class="tab-content"
                                :id="'myTabContent-' + index"
                              >
                                <div
                                  class="tab-pane fade show active"
                                  :id="'details-tab-pane-' + index"
                                  role="tabpanel"
                                  :aria-labelledby="'details-tab-' + index"
                                  tabindex="0"
                                >
                                  <div class="">
                                    <div class="row">
                                      <div class="col-12">
                                        <h6
                                          class="prospect-section-title mt-3 prospect-data-heading"
                                        >
                                          Details
                                        </h6>
                                      </div>
                                    </div>

                                    <!-- Details Section -->
                                    <div
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">
                                          <span v-if="replaceTheFields(client)"
                                            >Close Date</span
                                          >
                                          <span v-else>Max Approval</span>
                                        </p>
                                      </div>
                                      <div class="col-3">
                                        <p class="name prospect-data-answer">
                                          <span v-if="replaceTheFields(client)">
                                            <span v-if="client.closeDate">{{
                                              client.closeDate | formatDate
                                            }}</span>
                                            <span v-else>Not Available</span>
                                          </span>
                                          <span v-else>
                                            <span v-if="client.maxApproval">
                                              ${{
                                                client.maxApproval
                                                  | formatNumber
                                              }}
                                            </span>
                                            <span v-else>Not Available</span>
                                          </span>
                                        </p>
                                      </div>
                                      <div
                                        v-if="
                                          isScenarioVisible(
                                            client?.prospectOrDealId
                                          )
                                        "
                                        class="col-4 d-flex align-items-center"
                                      >
                                        <a
                                          href="#"
                                          class="d-flex align-items-center prospect-sub-heading"
                                          @click.stop="
                                            clientScenariosData(client)
                                          "
                                        >
                                          <img
                                            src="../assets/images/mortgageCalculator.svg"
                                            alt="Mortgage Calculator"
                                            class="prospect-data-email-icon"
                                          />
                                          Mortgage Calculator
                                          <UpArrowIcon
                                            :color="getAssets.primaryBrandColor"
                                            class="prospect-data-email-img"
                                          />
                                        </a>
                                      </div>
                                    </div>

                                    <div
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">
                                          <span v-if="replaceTheFields(client)"
                                            >Appraisal Status</span
                                          >
                                          <span v-else>Down Payment</span>
                                        </p>
                                      </div>
                                      <div class="col-3">
                                        <p class="name prospect-data-answer">
                                          <span v-if="replaceTheFields(client)">
                                            <span v-if="client.appraisalStage">
                                              <span
                                                v-if="appraisalCond(client)"
                                              >
                                                {{
                                                  client.appraisalStage ===
                                                  "TBD"
                                                    ? "Pending Order"
                                                    : client.role === "Listing"
                                                    ? "Completed"
                                                    : client.appraisalStage
                                                }}
                                              </span>
                                              <span
                                                v-if="
                                                  client.appraisalStage !==
                                                    'TBD' &&
                                                  client.appraisalStage !==
                                                    'Completed'
                                                "
                                                class="whitespace-pre-line"
                                              >
                                                <br />{{
                                                  "Ordered: " +
                                                  orderedDate(client)
                                                }}
                                                <br />{{
                                                  "Due Date: " + dueDate(client)
                                                }}
                                              </span>
                                            </span>
                                            <span v-else>Pending Order</span>
                                          </span>
                                          <span v-else>
                                            <span
                                              v-if="
                                                client.downPayment ||
                                                client.downPayment !== null
                                              "
                                            >
                                              {{ client.downPayment }}%
                                            </span>
                                            <span v-else>Not Available</span>
                                          </span>
                                        </p>
                                      </div>
                                      <div
                                        class="col-4 d-flex align-items-center"
                                      >
                                        <a
                                          href="/admin/approval-letters"
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <img
                                            src="../assets/images/approvalLetter.svg"
                                            alt="Approval Letter"
                                            class="prospect-data-email-icon"
                                          />
                                          Approval Letter
                                          <UpArrowIcon
                                            :color="getAssets.primaryBrandColor"
                                            class="prospect-data-email-img"
                                          />
                                        </a>
                                      </div>
                                    </div>

                                    <div
                                      v-if="client?.note"
                                      class="row align-items-start border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">
                                          <strong>Notes</strong>
                                        </p>
                                      </div>
                                      <div class="col-7">
                                        <p
                                          class="prospect-note"
                                          style="
                                            font-weight: 600;
                                            color: #222222;
                                            font-size: 15px;
                                          "
                                        >
                                          {{ client?.note }}
                                        </p>
                                      </div>
                                    </div>
                                    <!-- Divider -->
                                    <div class="row">
                                      <div class="col-12">
                                        <h6
                                          class="prospect-section-title prospect-data-heading"
                                          style="
                                            padding-top: 32px;
                                            padding-bottom: 8px;
                                          "
                                        >
                                          <strong
                                            >Lender Contact Information</strong
                                          >
                                        </h6>
                                      </div>
                                    </div>
                                    <div
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">
                                          <strong>Name</strong>
                                        </p>
                                      </div>
                                      <div
                                        class="col-7 d-flex align-items-center"
                                      >
                                        <img
                                          :src="client?.contactProfilePhotoURL"
                                          :alt="client.contactName"
                                          style="
                                            width: 24px;
                                            height: 24px;
                                            margin-right: 5px;
                                          "
                                        />
                                        <span v-if="client.contactName">{{
                                          client.contactName
                                        }}</span>
                                        <span v-else>Not Available</span>
                                      </div>
                                    </div>

                                    <div
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">
                                          <strong>Chat</strong>
                                        </p>
                                      </div>
                                      <div class="col-7">
                                        <a
                                          href="#"
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <i
                                            class="fa fa-comment prospect-data-icon"
                                          ></i>
                                          <span>{{ client.contactName }}</span>
                                        </a>
                                      </div>
                                    </div>

                                    <div
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">
                                          <strong>Phone</strong>
                                        </p>
                                      </div>
                                      <div class="col-7">
                                        <a
                                          :href="'tel:' + client.contactNumber"
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <i
                                            class="fa fa-phone-alt prospect-data-icon"
                                          ></i>
                                          <span>{{
                                            client.contactNumber
                                          }}</span>
                                        </a>
                                      </div>
                                    </div>

                                    <div
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">
                                          <strong>Email</strong>
                                        </p>
                                      </div>
                                      <div class="col-7">
                                        <a
                                          :href="
                                            'mailto:' + client.contactEmail
                                          "
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <img
                                            src="../assets/images/email-icon.svg"
                                            alt="Email"
                                            class="prospect-data-email-icon"
                                          />
                                          <span>{{ client.contactEmail }}</span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!-- Prospect Contact details -->
                                <div
                                  class="tab-pane fade"
                                  :id="'contact-tab-pane-' + index"
                                  role="tabpanel"
                                  :aria-labelledby="'contact-tab-' + index"
                                  tabindex="0"
                                >
                                  <!-- Borrower Contact Information -->
                                  <div
                                    v-if="
                                      prospectContactData?.phoneB ||
                                      prospectContactData?.emailB ||
                                      prospectContactData?.nameB
                                    "
                                  >
                                    <div class="row">
                                      <div class="col-12">
                                        <h6
                                          class="prospect-section-title mt-3 prospect-data-heading"
                                        >
                                          Client Contact Information
                                        </h6>
                                      </div>
                                    </div>
                                    <div
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Name</p>
                                      </div>
                                      <div
                                        class="col-7 d-flex align-items-center"
                                      >
                                        <div class="initials-circle">
                                          {{
                                            getInitials(
                                              prospectContactData?.nameB
                                            )
                                          }}
                                        </div>
                                        <p class="name prospect-sub-heading">
                                          <span
                                            v-if="prospectContactData?.nameB"
                                            >{{
                                              prospectContactData?.nameB
                                            }}</span
                                          >
                                          <span v-else>Not Available</span>
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Phone</p>
                                      </div>
                                      <div class="col-7">
                                        <a
                                          :href="
                                            'tel:' + prospectContactData?.phoneB
                                          "
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <i
                                            class="fa fa-phone-alt prospect-data-icon"
                                          ></i>
                                          <span
                                            v-if="prospectContactData?.phoneB"
                                            >{{
                                              prospectContactData?.phoneB
                                            }}</span
                                          >
                                          <span v-else>Not Available</span>
                                        </a>
                                      </div>
                                    </div>
                                    <div
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Email</p>
                                      </div>
                                      <div class="col-7">
                                        <a
                                          :href="
                                            'mailto:' +
                                            prospectContactData?.emailB
                                          "
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <i
                                            class="fa fa-envelope prospect-data-icon"
                                          ></i>
                                          <span
                                            v-if="prospectContactData?.emailB"
                                            >{{
                                              prospectContactData?.emailB
                                            }}</span
                                          >
                                          <span v-else>Not Available</span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- Co-borrower Contact Information -->
                                  <div
                                    v-if="
                                      prospectContactData?.phoneCo &&
                                      prospectContactData?.emailCo &&
                                      prospectContactData?.nameCo
                                    "
                                  >
                                    <div class="row">
                                      <div class="col-12">
                                        <h6
                                          class="prospect-section-title mt-3 prospect-data-heading"
                                        >
                                          Client Contact Information
                                        </h6>
                                      </div>
                                    </div>
                                    <div
                                      v-if="prospectContactData?.nameCo"
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Name</p>
                                      </div>
                                      <div
                                        class="col-7 d-flex align-items-center"
                                      >
                                        <div class="initials-circle">
                                          {{
                                            getInitials(
                                              prospectContactData?.nameCo
                                            )
                                          }}
                                        </div>
                                        <p class="name prospect-sub-heading">
                                          <span>{{
                                            prospectContactData?.nameCo
                                          }}</span>
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      v-if="prospectContactData?.phoneCo"
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Phone</p>
                                      </div>
                                      <div class="col-7">
                                        <a
                                          :href="
                                            'tel:' +
                                            prospectContactData?.phoneCo
                                          "
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <i
                                            class="fa fa-phone-alt prospect-data-icon"
                                          ></i>
                                          <span>{{
                                            prospectContactData?.phoneCo
                                          }}</span>
                                        </a>
                                      </div>
                                    </div>
                                    <div
                                      v-if="prospectContactData?.emailCo"
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Email</p>
                                      </div>
                                      <div class="col-7">
                                        <a
                                          :href="
                                            'mailto:' +
                                            prospectContactData?.emailCo
                                          "
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <i
                                            class="fa fa-envelope prospect-data-icon"
                                          ></i>
                                          <span>{{
                                            prospectContactData?.emailCo
                                          }}</span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- CPA Contact Information -->
                                  <div
                                    v-if="
                                      prospectContactData?.cpaAccount
                                        ?.cpaName &&
                                      prospectContactData?.cpaAccount
                                        ?.cpaPhone &&
                                      prospectContactData?.cpaAccount?.cpaEmail
                                    "
                                  >
                                    <div class="row">
                                      <div class="col-12">
                                        <h6
                                          class="prospect-section-title mt-3 prospect-data-heading"
                                        >
                                          CPA/Accountant Contact Info
                                        </h6>
                                      </div>
                                    </div>
                                    <div
                                      v-if="
                                        prospectContactData?.cpaAccount?.cpaName
                                      "
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Name</p>
                                      </div>
                                      <div
                                        class="col-7 d-flex align-items-center"
                                      >
                                        <div class="initials-circle">
                                          {{
                                            getInitials(
                                              prospectContactData?.cpaAccount
                                                ?.cpaName
                                            )
                                          }}
                                        </div>
                                        <p class="name prospect-sub-heading">
                                          <span>{{
                                            prospectContactData?.cpaAccount
                                              ?.cpaName
                                          }}</span>
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      v-if="
                                        prospectContactData?.cpaAccount
                                          ?.cpaPhone
                                      "
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Phone</p>
                                      </div>
                                      <div class="col-7">
                                        <a
                                          :href="
                                            'tel:' +
                                            prospectContactData?.cpaAccount
                                              ?.cpaPhone
                                          "
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <i
                                            class="fa fa-phone-alt prospect-data-icon"
                                          ></i>
                                          <span>{{
                                            prospectContactData?.cpaAccount
                                              ?.cpaPhone
                                          }}</span>
                                        </a>
                                      </div>
                                    </div>
                                    <div
                                      v-if="
                                        prospectContactData?.cpaAccount
                                          ?.cpaEmail
                                      "
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Email</p>
                                      </div>
                                      <div class="col-7">
                                        <a
                                          :href="
                                            'mailto:' +
                                            prospectContactData?.cpaAccount
                                              ?.cpaEmail
                                          "
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <i
                                            class="fa fa-envelope prospect-data-icon"
                                          ></i>
                                          <span>{{
                                            prospectContactData?.cpaAccount
                                              ?.cpaEmail
                                          }}</span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- CFP Contact Information -->
                                  <div
                                    v-if="
                                      prospectContactData?.cfpAccount
                                        ?.cfpName &&
                                      prospectContactData?.cfpAccount
                                        ?.cfpPhone &&
                                      prospectContactData?.cfpAccount?.cfpEmail
                                    "
                                  >
                                    <div class="row">
                                      <div class="col-12">
                                        <h6
                                          class="prospect-section-title mt-3 prospect-data-heading"
                                        >
                                          Financial Planner Contact Info
                                        </h6>
                                      </div>
                                    </div>
                                    <div
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Name</p>
                                      </div>
                                      <div
                                        v-if="
                                          prospectContactData?.cfpAccount
                                            ?.cfpName
                                        "
                                        class="col-7 d-flex align-items-center"
                                      >
                                        <div class="initials-circle">
                                          {{
                                            getInitials(
                                              prospectContactData?.cfpAccount
                                                ?.cfpName
                                            )
                                          }}
                                        </div>
                                        <p class="name prospect-sub-heading">
                                          <span>{{
                                            prospectContactData?.cfpAccount
                                              ?.cfpName
                                          }}</span>
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      v-if="
                                        prospectContactData?.cfpAccount
                                          ?.cfpPhone
                                      "
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Phone</p>
                                      </div>
                                      <div class="col-7">
                                        <a
                                          :href="
                                            'tel:' +
                                            prospectContactData?.cfpAccount
                                              ?.cfpPhone
                                          "
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <i
                                            class="fa fa-phone-alt prospect-data-icon"
                                          ></i>
                                          <span>{{
                                            prospectContactData?.cfpAccount
                                              ?.cfpPhone
                                          }}</span>
                                        </a>
                                      </div>
                                    </div>
                                    <div
                                      v-if="
                                        prospectContactData?.cfpAccount
                                          ?.cfpEmail
                                      "
                                      class="row align-items-center border-bottom"
                                    >
                                      <div class="col-5">
                                        <p class="prospect-label">Email</p>
                                      </div>
                                      <div class="col-7">
                                        <a
                                          :href="
                                            'mailto:' +
                                            prospectContactData?.cfpAccount
                                              ?.cfpEmail
                                          "
                                          class="d-flex align-items-center prospect-sub-heading"
                                        >
                                          <i
                                            class="fa fa-envelope prospect-data-icon"
                                          ></i>
                                          <span>{{
                                            prospectContactData?.cfpAccount
                                              ?.cfpEmail
                                          }}</span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- End of Offcanvas component -->
                        </template>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="7">
                            <div class="pagination">
                              <div class="pagination__btn">
                                <button
                                  @click="prevPage"
                                  :class="currentPage === 1 ? 'disabled' : ''"
                                  type="button"
                                  class="pagination__btn-prev button"
                                >
                                  <img
                                    src="../assets/images/prev-icon.svg"
                                    class="previous icon"
                                  />
                                </button>
                                <button
                                  @click="nextPage"
                                  :class="
                                    currentPage === totalPages ? 'disabled' : ''
                                  "
                                  type="button"
                                  class="pagination__btn-next button"
                                >
                                  <img
                                    src="../assets/images/prev-icon.svg"
                                    class="next icon"
                                  />
                                </button>
                              </div>
                              <label
                                >{{ recordRange }} of
                                {{ filteredRecords.length }}
                                items</label
                              >
                            </div>
                          </td>
                        </tr>
                      </tfoot>
                    </table>

                    <table
                      v-else
                      class="table text-center text-secondary"
                      style="height: 220px"
                    >
                      <h1 class="fw-bolder">No Data Available</h1>
                    </table>
                  </div>
                  <!-- Nurture Tab Desktop View Ends -->

                  <!-- Nurture Tab Mobile View Starts -->
                  <div
                    v-if="filteredRecords.length"
                    class="table__card d-block d-sm-none"
                  >
                    <div
                      class="card"
                      v-for="(client, index) in paginatedData"
                      :key="index"
                    >
                      <div class="table-row">
                        <label>Client Name</label>
                        <div class="d-flex justify-content-between">
                          <p>{{ client.clientName | capitalize }}</p>
                          <div
                            @click="isActive = isActive === index ? '' : index"
                          >
                            <base-down-arrow></base-down-arrow>
                          </div>
                        </div>
                      </div>
                      <div class="table-row">
                        <label>Details</label>
                        <p v-if="client.isDeal && client.street">
                          {{ client.street }}
                        </p>
                        <p v-else-if="client.purchasePrice && client.loanType">
                          ${{ client.purchasePrice | formatNumber }}
                          {{ client.loanType }}
                        </p>
                        <p v-else>Not Available</p>
                      </div>
                      <slide-up-down
                        class="card"
                        :active="isActive === index"
                        :duration="1000"
                      >
                        <div class="table-row">
                          <div class="d-flex">
                            <div v-if="replaceTheFields(client)" class="box">
                              <label>Close Date</label>
                              <p v-if="client.closeDate">
                                {{ client.closeDate | formatDate }}
                              </p>
                              <p v-else>Not Available</p>
                            </div>
                            <div v-else class="box">
                              <label>Max Approval</label>
                              <p v-if="client.maxApproval">
                                ${{ client.maxApproval | formatNumber }}
                              </p>
                              <p v-else>Not Available</p>
                            </div>
                            <div v-if="replaceTheFields(client)" class="box">
                              <label>Appraisal Status</label>
                              <p v-if="client.appraisalStage">
                                <span v-if="appraisalCond(client)">{{
                                  client.appraisalStage === "TBD"
                                    ? "Pending Order"
                                    : client.role === "Listing"
                                    ? "Completed"
                                    : client.appraisalStage
                                }}</span>
                                <span
                                  v-if="
                                    client.appraisalStage !== 'TBD' &&
                                    client.appraisalStage !== 'Completed'
                                  "
                                  class="whitespace-pre-line"
                                  ><br />
                                  {{ "Ordered: " }} <br />
                                  {{ orderedDate(client) }}
                                  <br />
                                  {{ "Due Date: " }} <br />
                                  {{ dueDate(client) }}
                                </span>
                              </p>
                              <p v-else>Pending Order</p>
                            </div>
                            <div v-else class="box">
                              <label>Down Payment</label>
                              <p
                                v-if="
                                  client.downPayment ||
                                  client.downPayment !== null
                                "
                              >
                                {{ client.downPayment }}%
                              </p>
                              <p v-else>Not Available</p>
                            </div>
                          </div>
                        </div>
                        <div v-if="replaceTheFields(client)" class="table-row">
                          <div class="d-flex">
                            <div class="box">
                              <label>CD Status</label>
                              <p v-if="client.cDStage">{{ client.cDStage }}</p>
                              <p v-else>Not Available</p>
                            </div>
                            <div class="box" v-if="client.role !== 'Listing'">
                              <label>Ratelock Expiration</label>
                              <p v-if="client.ratelockExpiration">
                                {{ client.ratelockExpiration | formatDate }}
                              </p>
                              <p v-else>Not Available</p>
                            </div>
                          </div>
                        </div>
                        <div v-else class="table-row">
                          <label>Notes</label>
                          <p v-if="client.note">
                            {{ client.note }}
                          </p>
                          <p v-else>Not Available</p>
                        </div>
                        <div
                          v-if="isScenarioVisible(client?.prospectOrDealId)"
                          class="table-row"
                        >
                          <label>Estimate / Scenario</label>
                          <p v-if="isScenarioVisible(client.prospectOrDealId)">
                            <span style="cursor: pointer"
                              ><img
                                src="../assets/images/eye-icon.svg"
                                alt="cross icon"
                                @click.stop="clientScenariosData(client)"
                            /></span>
                          </p>
                          <p v-else>Not Available</p>
                        </div>
                        <div class="table-row contact-row">
                          <label>Contact</label>
                          <p>
                            <img
                              v-if="client.contactProfilePhotoURL"
                              :src="client.contactProfilePhotoURL"
                              alt="user image"
                            />
                            <img v-else :src="defaultAvatar()" />
                          </p>
                          <div class="d-flex">
                            <div class="box">
                              <label><i class="fa fa-comment"></i></label>
                              <p>
                                <a href="#">{{ client.contactName }}</a>
                              </p>
                            </div>
                            <div class="box">
                              <label><i class="fa fa-phone-alt"></i></label>
                              <p>
                                <a :href="'tel:' + client.contactNumber">{{
                                  client.contactNumber
                                }}</a>
                              </p>
                            </div>
                          </div>
                          <div class="d-flex">
                            <div class="box">
                              <label><i class="fa fa-envelope"></i></label>
                              <p>
                                <a :href="'mailto:' + client.contactEmail">{{
                                  client.contactEmail
                                }}</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </slide-up-down>
                      <div class="table-row">
                        <div class="d-flex flex-nowrap justify-content-between">
                          <label>Status</label>
                          <div class="status rounded nurture">
                            {{ client.status }}
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="extraDetailsofProspect[0]?.isParentAccount"
                        class="table-row"
                      >
                        <div class="d-flex flex-nowrap justify-content-between">
                          <label>Agent</label>
                          <div class="agent-container">
                            <p class="agent-initial">
                              {{ agentNameFormat(client?.buyersAgentName) }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="table-row">
                        <div class="d-flex flex-nowrap justify-content-between">
                          <label>Role</label>
                          <div class="status outline-skyblue rounded">
                            {{ client.role }}
                          </div>
                        </div>
                      </div>
                      <div class="table-row">
                        <div class="d-flex flex-nowrap justify-content-between">
                          <label>Last Updated</label>
                          <p v-if="client.lastUpdatedDate">
                            {{ client.lastUpdatedDate | formatDate }}
                          </p>
                          <p v-else>N/A</p>
                        </div>
                      </div>
                      <div class="table-row">
                        <div class="icon-card-set">
                          <span
                            v-if="isScenarioVisible(client?.prospectOrDealId)"
                            class="custom-tooltip cursor-pointer"
                            data-tooltip="Mortgage estimate review available"
                            @click="clientScenariosData(client)"
                            ><ScenariosIcon
                          /></span>
                          <span
                            v-if="
                              clientShowScenarios.find(
                                (el) =>
                                  el.prospectOrDealId ===
                                  client.prospectOrDealId
                              )?.amrCompletedDate
                            "
                            @click="getAMRResponse(client)"
                            class="cursor-pointer"
                          >
                            <AmrResponseIcon />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="pagination">
                      <div class="pagination__btn">
                        <button
                          @click="prevPage"
                          :class="currentPage === 1 ? 'disabled' : ''"
                          type="button"
                          class="pagination__btn-prev button"
                        >
                          <img
                            src="../assets/images/prev-icon.svg"
                            class="previous icon"
                          />
                        </button>
                        <button
                          @click="nextPage"
                          :class="currentPage === totalPages ? 'disabled' : ''"
                          type="button"
                          class="pagination__btn-next button"
                        >
                          <img
                            src="../assets/images/prev-icon.svg"
                            class="next icon"
                          />
                        </button>
                      </div>
                      <label
                        >{{ recordRange }} of
                        {{ filteredRecords.length }}
                        items</label
                      >
                    </div>
                  </div>
                  <div
                    v-else
                    class="table__card d-block d-sm-none text-center text-secondary"
                  >
                    <h1 class="fw-bolder">No Data Available</h1>
                  </div>
                  <!-- Nurture Tab Mobile View End -->
                </div>
              </div>
              <!-- Nurture Tab Ends -->
            </div>
          </div>
        </div>
      </main>
    </template>
    <MortgageEstimate class="hidden" />

    <AmrResponseModal />
    <ProfileInfo />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "./../event-bus";
import Toasters from "./../mixins/toasters";
import moment from "moment";
import SlideUpDown from "vue-slide-up-down";
import BaseDownArrow from "./BaseDownArrow.vue";
import UpArrowIcon from "../components/Icons/UpArrow.vue";
import NoClientsIcon from "./NoClientsIcon.vue";
import AmrResponseModal from "./AmrResponseModal.vue";
import InterestRateToolBar from "./InterestRateToolBar.vue";
import ProfileInfo from "./Profile-Info";
import MortgageEstimate from "../components/MortgageEstimate.vue";
import axios from "@/api-service";
import { cloneDeep } from "lodash";
import ScenariosIcon from "./Icons/ScenariosIcon.vue";
import AmrResponseIcon from "./Icons/AmrResponseIcon.vue";

export default {
  name: "Pipeline",
  mixins: [Toasters],
  components: {
    "slide-up-down": SlideUpDown,
    "base-down-arrow": BaseDownArrow,
    UpArrowIcon,
    MortgageEstimate,
    NoClientsIcon,
    InterestRateToolBar,
    AmrResponseModal,
    ProfileInfo,
    ScenariosIcon,
    AmrResponseIcon,
  },
  data() {
    return {
      activeTab: "All",
      allClients: [],
      showClients: [],
      apiFilteredRecords: [],
      isActive: "",
      pipelineFilters: [],
      searchQuery: "",
      selectedFilter: "Pipeline",
      previousSelectedFilter: null,
      currentPage: 1,
      pageSize: 10,
      sortColumn: "", // Track the column to sort by
      sortOrder: "asc",
      clientShowScenarios: [],
      extraDetailsofProspect: [],
      mortgageData: {},
      prospectActiveTab: "details", // Default prospect ActiveTab
      prospectTabs: {},
      prospectContactData: {},
    };
  },

  watch: {
    searchQuery(newVal) {
      if (newVal) {
        if (this.previousSelectedFilter === null) {
          // Only set previousSelectedFilter once, if it hasn’t been set
          this.previousSelectedFilter = this.selectedFilter;
        }
        this.currentPage = 1;
        this.selectedFilter = "";
      } else if (newVal.length === 0) {
        this.selectedFilter = this.previousSelectedFilter || "Pipeline";
        this.previousSelectedFilter = null; // Reset for future changes
      }
    },
    selectedFilter(newVal) {
      if (newVal === "nurtured" || newVal === "Lost to Lender") {
        this.onFilterChange();
      } else {
        this.apiFilteredRecords = [];
      }
    },
  },
  computed: {
    ...mapGetters([
      "getOrgId",
      "getProspectId",
      "getUser",
      "getAssets",
      "getOpenModal",
    ]),

    filteredRecords() {
      // Start with all records
      let data;

      if (this.activeTab === "Nurtured") {
        data = this.apiFilteredRecords || [];
      } else {
        data = this.apiFilteredRecords.length
          ? this.apiFilteredRecords
          : this.allClients;
      }

      // Apply search logic
      if (this.searchQuery.length) {
        const normalizedQuery = this.normalizeString(this.searchQuery);

        data = data.filter((item) => {
          if (item.borrowerCoBorrowerName) {
            return this.normalizeString(item.borrowerCoBorrowerName).includes(
              normalizedQuery
            );
          } else {
            return this.normalizeString(item.clientName).includes(
              normalizedQuery
            );
          }
        });
      }
      // Apply filter logic only if no search query exists
      else if (this.selectedFilter) {
        let pipelineFilter = [
          "Nurture",
          "Contact Lost",
          "Lost to Lender",
          "Converted",
          "Dead",
          "Closed",
          "DNQ",
          "TBD",
        ];

        if (this.selectedFilter === "Pipeline") {
          const checklistOrder = [
            "Clear to Close",
            "Approved",
            "Conditional Approval",
            "Processing",
            "Under Contract",
            "Guaranteed Financing",
            "Pre-Approved",
            "Pre-Qualified",
            "Items Needed",
            "App Completed",
            "App Started",
            "Contacted",
            "New",
          ];

          data = data.filter((item) => !pipelineFilter.includes(item.status));

          // Sort data based on the checklist order
          data.sort((a, b) => {
            const indexA = checklistOrder.indexOf(a.status);
            const indexB = checklistOrder.indexOf(b.status);

            // If status is not found in the checklistOrder, push it to the end
            return (
              (indexA === -1 ? Infinity : indexA) -
              (indexB === -1 ? Infinity : indexB)
            );
          });
        } else {
          data = data.filter(
            (item) =>
              item.status ===
              (this.selectedFilter === "nurtured"
                ? "Nurture"
                : this.selectedFilter)
          );
        }
      }

      if (this.sortColumn) {
        data.sort((a, b) => {
          const valueA = a[this.sortColumn];
          const valueB = b[this.sortColumn];

          // Handle numbers and strings differently
          if (typeof valueA === "number" && typeof valueB === "number") {
            return this.sortOrder === "asc" ? valueA - valueB : valueB - valueA;
          } else {
            const strA = valueA ? valueA.toString() : "";
            const strB = valueB ? valueB.toString() : "";
            return this.sortOrder === "asc"
              ? strA.localeCompare(strB)
              : strB.localeCompare(strA);
          }
        });
      }

      return data;
    },

    totalPages() {
      return Math.ceil(this.filteredRecords.length / this.pageSize);
    },

    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredRecords.slice(start, end);
    },

    recordRange() {
      const start = (this.currentPage - 1) * this.pageSize + 1;
      const end = Math.min(
        this.currentPage * this.pageSize,
        this.filteredRecords.length
      );
      return `${start}-${end}`;
    },
  },
  created() {
    eventBus.$on("triggerPipelineAPI", (data) => {
      if (data) {
        this.getAllData();
      }
    });
  },
  mounted() {
    this.getAllData();
    this.prospects?.forEach((_, index) => {
      if (!this.prospectTabs?.[index]) {
        this.$set(this.prospectTabs, index, "details"); // Default to 'details'
      }
    });
  },
  methods: {
    ...mapActions("mortgage-estimate", [
      "getScenarios",
      "SET_CLIENT_NAME",
      "SET_AMR_MODAL_DATA",
      "SET_AMR_RESPONSES",
    ]),

    openOffcanvas(index, prospectId) {
      this.isActive = index;

      if (typeof window !== "undefined" && window.bootstrap) {
        const offcanvasElement = document.getElementById(
          `offcanvasTop-${index}`
        );

        if (offcanvasElement) {
          const offcanvasInstance = new window.bootstrap.Offcanvas(
            offcanvasElement
          );

          offcanvasElement.addEventListener("hidden.bs.offcanvas", () => {
            this.isActive = "";
          });

          offcanvasInstance.show();
        }
      }

      // Ensure tab state is set correctly
      if (!this.prospectTabs[index]) {
        this.$set(this.prospectTabs, index, "details");
      }

      // Update prospect data
      this.extraDetailsofProspect?.forEach((item) => {
        if (item?.prospectOrDealId === prospectId) {
          this.prospectContactData = item;
        }
      });
    },

    handleButtonClick(client) {
      this.clientScenariosData(client);
    },

    agentNameFormat(value) {
      if (!value) {
        return "-";
      }

      const words = value.split(" ");
      if (words.length === 1) {
        return words[0].charAt(0);
      } else if (words.length >= 2) {
        return words[0].charAt(0) + words[1].charAt(0);
      } else {
        return value;
      }
    },
    getInitials(name) {
      if (!name) return ""; // Return empty if name is not provided
      const nameParts = name?.split(" ");
      return nameParts
        ?.map((part) => part[0])
        ?.join("")
        ?.toUpperCase()
        ?.slice(0, 2);
    },

    setProspectActiveTab(tab, index) {
      // this.prospectActiveTab = tabName; // Update the active tab
      this.$set(this.prospectTabs, index, tab); // Set the selected tab
    },
    isScenarioVisible(prospectOrDealId) {
      const scenario = this.clientShowScenarios?.find(
        (item) =>
          typeof item?.prospectOrDealId === "string" &&
          item?.prospectOrDealId?.includes(prospectOrDealId)
      );
      return scenario ? scenario?.showScenarios : false;
    },

    async clientScenariosData(clientData) {
      this.SET_CLIENT_NAME(clientData?.clientName);
      eventBus.$emit("loader-status", true);
      const { data = {} } = await axios.get(
        "https://clientportalapi.candidcrm.com/scenarios",
        {
          params: {
            isBorrower: true,
            orgId: this.getOrgId,
            recordId: clientData?.prospectOrDealId,
          },
        }
      );
      eventBus.$emit("loader-status", false);
      this.mortgageData = cloneDeep(data);
      this.getScenarios(this.mortgageData);
      eventBus.$emit("modalOpen", true);
      this.$store.dispatch("mortgage-estimate/SET_OPEN_MODAL", true);
    },

    async getAMRResponse(clientData) {
      eventBus.$emit("loader-status", true);

      this.SET_AMR_MODAL_DATA({
        ...clientData,
        ...this.clientShowScenarios.find(
          (item) => item.prospectOrDealId === clientData?.prospectOrDealId
        ),
      });

      await this.$http
        .get(
          `/AMR/getAMRResponse/${clientData?.prospectOrDealId}?orgId=${this.getOrgId}`
        )
        .then((response) => {
          let resp = response.data;

          this.SET_AMR_RESPONSES(resp?.questions || []);
          eventBus.$emit("amrModalOpen", true);
          this.$store.dispatch("mortgage-estimate/SET_OPEN_AMR_MODAL", true);
        })
        .catch((error) => {
          console.log(error);
        });

      eventBus.$emit("loader-status", false);
    },

    async getAllData() {
      eventBus.$emit("loader-status", true);

      await this.$http
        .get(
          "/pipeline/getPipelineData/" +
            this.getProspectId +
            "/All?orgId=" +
            this.getOrgId
        )
        .then((response) => {
          let resp = response.data;
          this.clientShowScenarios = resp?.showScenarios;
          this.extraDetailsofProspect = resp?.extraDetailsofPros;

          if (resp.status === 200) {
            this.isActive = "";
            this.allClients = resp.data;
            this.pipelineFilters = resp.pipelineStatus;
          }
        })
        .catch((error) => {
          console.log(error);
        });

      eventBus.$emit("loader-status", false);
    },

    async getFilteredData() {
      eventBus.$emit("loader-status", true);

      try {
        const response = await this.$http.get(
          `/pipeline/getPipelineData/${this.getProspectId}/${
            this.selectedFilter === "nurtured" ? "nurture" : this.selectedFilter
          }?orgId=${this.getOrgId}`
        );

        return response.data.data;
      } catch (error) {
        console.error(error);
        return [];
      } finally {
        eventBus.$emit("loader-status", false);
      }
    },

    async onFilterChange() {
      // When the filter changes, call the API
      const data = await this.getFilteredData();
      this.apiFilteredRecords = data; // Store fetched data
      this.currentPage = 1; // Reset to first page after filter change
    },

    normalizeString(str) {
      const textArea = document.createElement("textarea");
      textArea.innerHTML = str; // Decode HTML entities
      return textArea.value.toLowerCase().trim(); // Lowercase and trim
    },

    applyFilter(status) {
      this.searchQuery = ""; // Reset search when filter is applied
      this.currentPage = 1;
      this.selectedFilter = status;
    },

    sort(column) {
      if (this.sortColumn === column) {
        // Toggle sort order if the same column is clicked
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        // Reset to ascending order if a new column is selected
        this.sortOrder = "asc";
      }
      this.sortColumn = column; // Set the new column to sort by
      this.currentPage = 1; // Reset to the first page after sorting
    },

    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },

    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },

    defaultAvatar() {
      let theAvatarColor;

      if (this.getAssets.primaryBrandColor) {
        theAvatarColor = this.getAssets.primaryBrandColor.substring(1);
      } else {
        let color = "#A6383C";
        theAvatarColor = color.substring(1);
      }

      return `https://img.icons8.com/material-rounded/36/${theAvatarColor}/user-male-circle.png`;
    },

    textForToolTip(client) {
      let status =
        !client.isAttorneyState && client.status === "Under Contract"
          ? "in escrow"
          : "under contract";

      if (client.appraisalStage === "Completed")
        return `The appraisal has been completed.`;
      else if (client.appraisalStage === "Under Value")
        return `The appraisal has been completed. The appraiser's opinion of value was less than the purchase price.`;
      else if (client.appraisalStage === "Subject to")
        return `The appraisal has been completed. The appraiser listed the appraisal as 'subject to' meaning some repairs are needed before the appraisal is final.`;
      else {
        if (client.status === "New" || client.status === "TBD")
          return `Thank you for the referral! We are looking forward to connecting with the buyers`;
        else if (client.status === "Contacted")
          return `We have connected with the buyers and are discussing next steps.`;
        else if (client.status === "App Started")
          return `The buyers started their mortgage application!`;
        else if (client.status === "App Completed")
          return `The buyers completed their mortgage application and we are reviewing.`;
        else if (client.status === "Items Needed")
          return `We are waiting on some items needed to complete the buyer's Pre-Approval.`;
        else if (client.status === "Pre-Approved")
          return `The buyer's home financing has been Pre-Approved!`;
        else if (client.status === "Approved")
          return `The buyer's home financing has been Approved by underwriting!`;
        else if (client.status === "Lost to Lender")
          return `The buyers decided to go with a different lender.`;
        else if (client.status === "DNQ")
          return `We are working with the buyers to help them qualify for home financing.`;
        else if (client.status === "Under Contract")
          return `Congratulations, we are ${status}! Looking forward to providing an amazing home financing experience.`;
        else if (client.status === "Conditional Approval")
          return `The underwriter will approve the loan once all conditions are met.`;
        else if (client.status === "Clear to Close")
          return `All conditions are met and loan is ready for closing.`;
        else if (client.status === "Guaranteed Financing")
          return `The buyer's financing is approved and guaranteed by the lender. For details, contact ${this.getUser.loCompany}`;
      }
    },

    classForFilters(filter) {
      switch (filter) {
        case "New":
        case "TBD":
        case "Guaranteed Financing":
          return "new";
        case "Contacted":
          return "contacted";
        case "App Started":
          return "started";
        case "App Completed":
          return "completed";
        case "Items Needed":
          return "item-needed";
        case "Pre-Approved":
          return "pre-approved";
        case "Approved":
          return "approved";
        case "Lost to Lender":
          return "lost-to-lender";
        case "DNQ":
          return "dnq";
        case "Under Contract":
          return "contract";
        case "Conditional Approval":
          return "conditional";
        case "Clear to Close":
          return "close";
        case "Nurture":
          return "nurture";
        default:
          return "primary-color";
      }
    },

    replaceTheFields(client) {
      return client.isDeal ||
        (client.isProspect && client.dealOrProspectStage === "Docs Out") ||
        client.role === "Listing"
        ? true
        : false;
    },

    appraisalCond(client) {
      let stage = client.appraisalStage;
      return (
        stage === "TBD" ||
        stage === "Completed" ||
        stage === "As Is" ||
        stage === "Subject To" ||
        stage === "Under Value"
      );
    },

    orderedDate(client) {
      if (client.appraisalStage) {
        let orderedDate = client.appraisalStage.split(" ");

        if (orderedDate[1]) {
          let date = moment(orderedDate[1].trim()).format("MMM D, YYYY");

          if (date !== "Invalid date") return date;
          else return "Not Available";
        } else return "Not Available";
      } else return "Not Available";
    },

    dueDate(client) {
      if (client.appraisalStage) {
        let dueDate = client.appraisalStage.split(" ");

        if (dueDate[5]) {
          let date = moment(dueDate[5].trim()).format("MMM D, YYYY");

          if (date !== "Invalid date") return date;
          else return "Not Available";
        } else return "Not Available";
      } else return "Not Available";
    },

    updateModalValue() {
      eventBus.$emit("modal-text", "Add Client");
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 1200px !important;
}
.table-responsive {
  overflow-x: unset !important;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.no-client {
  width: 100%;
  height: calc(100% - 52px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.no-client h3 {
  margin: 36px 0 16px;
  font-size: 22px;
  color: #252c32;
  font-weight: 600;
  line-height: 26.4px;
  text-align: center;
}
.no-client p {
  margin-bottom: 22px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: center;
  color: #000000;
}
.icon-wrap {
  height: auto;
  width: 25px;
  display: inline-block;
  padding-top: 3px;
}
.extra-column-for-icons {
  width: 40px;
  text-align: right;
  margin-right: 10px;
}
.icon-card-set {
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.icon-card-set img,
.icon-card-set svg {
  width: 20px;
}
.zero-index {
  z-index: 0;
}
.prospect-offcanvas {
  width: 600px;
  padding: 16px;
}
.prospect-note {
  white-space: normal;
}
.prospect-label {
  color: #848692;
  font-size: 15px;
  font-weight: 600;
}
hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
.agent-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ecedf2;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  gap: 4px;
  border: 1px solid #ecedf2;
  padding: 5px;
}
.agent-initial {
  color: #222222;
  padding: 5px;
  font-weight: 600;
  text-align: center;
  font-size: 14px;
}
.initials-circle {
  width: 24px;
  height: 24px;
  background-color: var(--primary-color);
  color: white;
  font-weight: bold;
  font-size: 13px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.border-bottom {
  border-bottom: 1px solid #ecedf2;
  padding-bottom: 12px;
  padding-top: 12px;
}
.prospect-data-heading {
  color: #43434b;
  font-weight: 700;
}
.prospect-data-answer {
  font-weight: 600;
  color: #222222;
}
.prospect-data-icon-answer {
  font-weight: 400;
  color: #222222;
  text-decoration: none;
}
.prospect-sub-heading {
  text-decoration: none;
  color: #222222;
  font-weight: 400;
}
.prospect-data-icon {
  color: #98989e;
  margin-right: 18px;
}
.prospect-data-email-icon {
  width: 18px;
  margin-right: 5px;
}
.prospect-data-email-img {
  width: 10px;
  margin-left: 5px;
}
</style>
