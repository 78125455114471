<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.5 1H3.5C2.94772 1 2.5 1.44772 2.5 2V16C2.5 16.5523 2.94772 17 3.5 17H15.5C16.0523 17 16.5 16.5523 16.5 16V2C16.5 1.44772 16.0523 1 15.5 1H13.5V3H5.5V1Z"
      :fill="getAssets.primaryBrandColor || '#000000'"
    />
    <path d="M5 9.5L8 12.5L14 6.5" stroke="white" stroke-width="2" />
    <path
      d="M6.5 1C6.5 0.447715 6.94772 0 7.5 0H11.5C12.0523 0 12.5 0.447715 12.5 1V2H6.5V1Z"
      :fill="getAssets.primaryBrandColor || '#000000'"
    />
  </svg>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Amr-Response-Icon",
  computed: {
    ...mapGetters(["getAssets"]),
  },
};
</script>