<template>
  <div class="content__wrapper">
    <header>
      <InterestRateToolBar
        :interest-rates="getAssets?.rateHistoryData?.[0] || {}"
      />
    </header>
    <div class="common-details-cover">
      <div class="banner-bg">
        <div class="bg1"></div>
        <div class="bg2"></div>
        <div class="bg3"></div>
        <div class="bg4"></div>
      </div>
      <div class="form__wrapper">
        <div class="container">
          <div class="form__wrapper-content">
            <form @submit.prevent="createTask">
              <div class="form__header">
                <h1 class="page-heading">
                  {{ getUser.accountName }}'s Approval Letters Access
                </h1>
                <div class="button">
                  <button
                    type="button"
                    class="btn btn-primary rounded download"
                    @click="
                      getAssets.showContactListingAgent ? '' : createTask()
                    "
                    :data-bs-toggle="
                      getAssets.showContactListingAgent ? 'modal' : ''
                    "
                    :data-bs-target="
                      getAssets.showContactListingAgent ? '#noModal' : ''
                    "
                    :disabled="
                      $v.$invalid || missingProperty || !this.allowedMaxAmount
                    "
                  >
                    Download PDF
                    <i class="fa fa-cloud-download-alt"></i>
                  </button>
                </div>
              </div>
              <div class="form__body">
                <div class="form-group">
                  <div class="form-input col-lg-6">
                    <div v-if="clientList?.length > 0" class="input-field">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        v-model="selectedClient"
                        placeholder="Select Client"
                      >
                        <option value="" selected disabled hidden>
                          Select a Client
                        </option>
                        <option
                          v-for="(cn, index) in clientList"
                          :key="index"
                          :value="cn.clientName"
                        >
                          {{ cn.clientName | capitalize }}
                        </option>
                      </select>
                    </div>
                    <div v-else class="input-field">
                      <div
                        class="custom-select-wrap"
                        @click="toggleCustomOption"
                      >
                        <div class="custom-select">
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            v-model="selectedClient"
                            placeholder="Select Client"
                          >
                            <option value="" selected disabled hidden>
                              Select a Client
                            </option>
                            <!-- <option>
                            <div class="no-clients-message" style="height: 100px;">
                              <p>No clients to display.</p>
                              <a href="#" @click.prevent="referClient" class="refer-client-link">Please Refer a Client</a>
                            </div>
                              </option> -->
                          </select>
                        </div>
                        <div v-if="customOpOtion" class="custom-option">
                          <span>No clients to display</span>
                          <div>
                            <span
                              >Please
                              <span
                                @click="referClient"
                                data-bs-toggle="modal"
                                data-bs-target="#send-mortgage-app-modal"
                                style="
                                  color: var(--primary-color);
                                  border-bottom: 1px solid;
                                  cursor: pointer;
                                "
                                >Refer a Client</span
                              ></span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="divider"></div>
                <div class="form-group">
                  <div class="row">
                    <div class="form-input col-md-6">
                      <label>Purchase Price</label>
                      <div class="input-field">
                        <img
                          src="../assets/images/price-icon.svg"
                          alt="price icon"
                        />
                        <input
                          type="text"
                          pattern="\d*"
                          placeholder="Purchase Price"
                          v-model.trim="$v.purchasePrice.$model"
                          :class="
                            $v.purchasePrice.$error || !this.allowedMaxAmount
                              ? 'is-invalid'
                              : ''
                          "
                          :disabled="Object.keys(this.theClient).length === 0"
                        />
                      </div>
                      <div
                        v-if="$v.purchasePrice.$error || !this.allowedMaxAmount"
                        class="pl-10 pr-10"
                      >
                        <span
                          v-if="!$v.purchasePrice.required"
                          class="invalid-feedback"
                        >
                          Purchase Price is required
                        </span>
                        <span
                          v-else-if="!$v.purchasePrice.numeric"
                          class="invalid-feedback"
                        >
                          Purchase Price must be a numeric value
                        </span>
                        <span
                          v-else-if="!this.allowedMaxAmount"
                          class="invalid-feedback"
                        >
                          Purchase Price exceeds Max Approval amount
                          {{ this.maxApproval | formatNumber }}
                        </span>
                      </div>
                    </div>
                    <div class="form-input col-md-6">
                      <label>Subject Property</label>
                      <div class="input-field">
                        <img
                          src="../assets/images/map-icon.svg"
                          alt="map icon"
                        />
                        <vue-google-autocomplete
                          ref="mainAddress"
                          id="main-form"
                          v-on:placechanged="getProperty"
                          v-on:inputChange="getProperty"
                          placeholder="Subject Property"
                          v-model.trim="subjectProperty"
                          country="us"
                          :class="missingProperty ? 'is-invalid' : ''"
                          :disabled="Object.keys(this.theClient).length === 0"
                          :fields="[
                            'formatted_address',
                            'address_components',
                            'geometry',
                          ]"
                        >
                        </vue-google-autocomplete>
                      </div>
                      <div v-if="missingProperty" class="pl-10 pr-10">
                        <span class="error-message">
                          Subject Property is required
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="showManualFields" class="form-group">
                  <div class="row">
                    <div class="form-input col-md-6">
                      <label>Annual Property Taxes</label>
                      <div class="input-field">
                        <img
                          src="../assets/images/calc-icon.svg"
                          alt="calculator icon"
                        />
                        <input
                          type="text"
                          placeholder="Annual Property Taxes"
                          v-model.trim="$v.annualPropertyTaxes.$model"
                          :class="{
                            'is-invalid': $v.annualPropertyTaxes.$error,
                          }"
                        />
                      </div>
                      <div
                        v-if="$v.annualPropertyTaxes.$error"
                        class="pl-10 pr-10"
                      >
                        <span
                          v-if="!$v.annualPropertyTaxes.numeric"
                          class="invalid-feedback"
                        >
                          Annual Property Taxes must be a numeric value
                        </span>
                      </div>
                    </div>
                    <div class="form-input col-md-6 mb-0">
                      <label>Transfer Tax</label>
                      <div class="input-field">
                        <img
                          src="../assets/images/loan-icon.svg"
                          alt="loan icon"
                        />
                        <input
                          type="text"
                          placeholder="Transfer Tax"
                          v-model.trim="$v.transferTaxes.$model"
                          :class="{ 'is-invalid': $v.transferTaxes.$error }"
                        />
                      </div>
                      <div v-if="$v.transferTaxes.$error" class="pl-10 pr-10">
                        <span
                          v-if="!$v.transferTaxes.numeric"
                          class="invalid-feedback"
                        >
                          Transfer Tax must be a numeric value
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div
                      v-if="showClosingCostField"
                      class="form-input col-md-6"
                    >
                      <label>Closing Costs</label>
                      <div class="input-field">
                        <img
                          src="../assets/images/calc-icon.svg"
                          alt="calculator icon"
                        />
                        <input
                          class="bold-input"
                          type="text"
                          placeholder="Closing Costs"
                          :value="closingCost | formatNumber"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="form-input col-md-6 mb-0">
                      <label>Loan Type</label>
                      <div class="input-field">
                        <img
                          src="../assets/images/loan-icon.svg"
                          alt="loan icon"
                        />
                        <input
                          type="text"
                          placeholder="Loan Type"
                          v-model.trim="$v.loanType.$model"
                          disabled
                          :class="{ 'is-invalid': $v.loanType.$error }"
                        />
                      </div>
                      <div v-if="$v.loanType.$error" class="pl-10 pr-10">
                        <span
                          v-if="!$v.loanType.required"
                          class="invalid-feedback"
                        >
                          Loan Type is required
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group add-custom-margin">
                  <div class="checkbox">
                    <input
                      type="checkbox"
                      id="paid"
                      v-model="sellerPaid"
                      :disabled="Object.keys(this.theClient).length === 0"
                    />
                    <label for="paid">Seller paid title</label>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div
                      v-if="maxSellerConcessionPercent"
                      class="form-input col-md-6"
                    >
                      <label
                        >Maximum Seller Concession (%)
                        <span
                          @mouseenter="maxSellerPercentInfo = true"
                          @mouseleave="maxSellerPercentInfo = false"
                          class="info-badge help-icon border-0 ms-0"
                        >
                          <InfoTooltipIcon />
                          <div
                            class="info-tooltip maxseller-tooltip"
                            v-show="maxSellerPercentInfo"
                          >
                            <p>
                              Maximum seller concessions refer to the highest
                              amount a seller can contribute towards a buyer's
                              closing costs and other expenses.
                            </p>
                          </div>
                        </span>
                      </label>
                      <div class="input-field">
                        <img
                          src="../assets/images/percentage-icon.svg"
                          alt="calculator icon"
                        />
                        <input
                          type="text"
                          placeholder="Maximum Seller Concession (%)"
                          :value="maxSellerConcessionPercent | formatNumber"
                          disabled
                        />
                      </div>
                    </div>
                    <div
                      v-if="maxSellerConcessionDollar"
                      class="form-input col-md-6 mb-0"
                    >
                      <label
                        >Maximum Seller Concession ($)
                        <span
                          @mouseenter="maxSellerDollarInfo = true"
                          @mouseleave="maxSellerDollarInfo = false"
                          class="info-badge help-icon border-0 ms-0"
                        >
                          <InfoTooltipIcon />
                          <div
                            class="info-tooltip maxseller-tooltip"
                            v-show="maxSellerDollarInfo"
                          >
                            <p>
                              Maximum seller concessions refer to the highest
                              amount a seller can contribute towards a buyer's
                              closing costs and other expenses.
                            </p>
                          </div>
                        </span>
                      </label>
                      <div class="input-field">
                        <img
                          src="../assets/images/dollar-icon.svg"
                          alt="loan icon"
                        />
                        <input
                          type="text"
                          placeholder="Maximum Seller Concession ($)"
                          :value="maxSellerConcessionDollar | formatNumber"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <template v-if="revealContactListingAgent">
                  <div class="custom-divider"></div>
                  <div class="form-group">
                    <label for=""
                      >Contact Listing Agent
                      <span
                        @mouseenter="showInfo = true"
                        @mouseleave="showInfo = false"
                        class="info-badge help-icon border-0 ms-0"
                      >
                        <InfoTooltipIcon />
                        <div class="info-tooltip" v-show="showInfo">
                          <p>
                            To highlight {{ selectedClient.split(" ")[0] }}'s
                            strong financing qualification and enhance the
                            competitiveness of their offer.
                          </p>
                        </div>
                      </span>
                    </label>
                    <div class="d-flex">
                      <div class="form-check ps-2">
                        <input
                          type="radio"
                          class="form-check-input"
                          name="contact-agent"
                          v-model="contactListingAgent"
                          value="Yes"
                          id="yes"
                          :disabled="missingProperty"
                          data-bs-toggle="modal"
                          data-bs-target="#yesModal"
                        />
                        <label for="yes" class="form-check-label">Yes</label>
                      </div>
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          name="contact-agent"
                          v-model="contactListingAgent"
                          :disabled="missingProperty"
                          value="No"
                          id="no"
                        />
                        <label for="no" class="form-checck-label">No</label>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        data-bs-keyboard="false"
        id="noModal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                Would you like {{ getUser.loCompany }} to contact the Listing
                Agent?
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <p>
                To highlight {{ selectedClient.split(" ")[0] }}'s strong
                financing qualification and enhance the competitiveness of their
                offer.
              </p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-link"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-outline rounded"
                data-bs-dismiss="modal"
                @click="createTask"
                :disabled="
                  $v.$invalid || missingProperty || !this.allowedMaxAmount
                "
              >
                No, Download PDF
              </button>
              <button
                type="button"
                class="btn btn-primary rounded"
                data-bs-dismiss="modal"
                data-bs-toggle="modal"
                data-bs-target="#yesModal"
                @click="contactListingAgent = 'Yes'"
              >
                Yes, Contact & Download PDF
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        data-bs-keyboard="false"
        id="yesModal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Contact Listing Agent</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                @click="contactListingAgent = 'No'"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form action="" class="form__wrapper-content border-0">
                <div class="form-group">
                  <div class="row">
                    <div class="form-input">
                      <label>Name</label>
                      <div class="input-field dropdown name-dropdown">
                        <img
                          src="../assets/images/user-icon.svg"
                          alt="user-icon"
                        />
                        <input
                          type="text"
                          placeholder="Type agent name to search..."
                          :class="
                            $v.listingAgent.name.$error ? 'is-invalid' : ''
                          "
                          v-model.trim="$v.listingAgent.name.$model"
                          @input="showAgentsList = true"
                        />
                        <div
                          class="dropdown-menu"
                          :class="showAgentsList ? 'show' : ''"
                        >
                          <template v-if="visibleAgentsList.length">
                            <div
                              v-for="item in visibleAgentsList"
                              :key="item.Id"
                              class="dropdown-item"
                              @click="selectContactListingAgent(item)"
                            >
                              <div class="icon">
                                <img
                                  src="../assets/images/user-icon.svg"
                                  alt="user-icon"
                                />
                              </div>
                              <span :value="item.Name"> {{ item.Name }}</span>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div v-if="$v.listingAgent.name.$error">
                        <span
                          v-if="!$v.listingAgent.name.required"
                          class="pl-10 pr-10 invalid-feedback"
                        >
                          Name field is required
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="form-input">
                      <label
                        >Preferred Contact Time {{ displayUserTimeZone }}</label
                      >
                      <div class="input-field">
                        <img
                          src="../assets/images/date-icon.svg"
                          alt="date-icon"
                        />
                        <DatePicker
                          v-model.trim="$v.listingAgent.contact_time.$model"
                          class="w-100"
                          :class="
                            $v.listingAgent.contact_time.$error
                              ? 'is-invalid'
                              : ''
                          "
                          type="datetime"
                          :format="'MM-DD-YYYY h:mm A'"
                          :use12h="true"
                          :editable="false"
                          :time-picker-options="timePickerOptions"
                          :disabled-date="disabledPastDates"
                          :title-format="'MMM DD, YYYY'"
                          :time-title-format="'MMM DD, YYYY'"
                          value-type="format"
                          :append-to-body="false"
                          @pick="setDefaultTime"
                        />
                      </div>
                      <div v-if="$v.listingAgent.contact_time.$error">
                        <span
                          v-if="!$v.listingAgent.contact_time.required"
                          class="pl-10 pr-10 invalid-feedback"
                        >
                          Preferred Contact Time field is required
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="form-input">
                      <label>Email</label>
                      <div class="input-field">
                        <img
                          src="../assets/images/email-icon.svg"
                          alt="email-icon"
                        />
                        <input
                          type="email"
                          :class="
                            $v.listingAgent.email.$error ? 'is-invalid' : ''
                          "
                          inputmode="email"
                          v-model.trim="$v.listingAgent.email.$model"
                        />
                      </div>
                      <div v-if="$v.listingAgent.email.$error">
                        <span
                          v-if="!$v.listingAgent.email.required"
                          class="pl-10 pr-10 invalid-feedback"
                        >
                          Email field is required
                        </span>
                        <span
                          v-else-if="!$v.listingAgent.email.email"
                          class="pl-10 pr-10 invalid-feedback"
                        >
                          Please enter a valid email address
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div
                      class="form-input"
                      :class="showSubjectPropertyInModal ? '' : 'mb-0'"
                    >
                      <label>Phone</label>
                      <div class="input-field">
                        <img
                          src="../assets/images/phone-icon.svg"
                          alt="loan icon"
                        />
                        <input
                          type="text"
                          :class="
                            $v.listingAgent.phone.$error ? 'is-invalid' : ''
                          "
                          inputmode="tel"
                          placeholder="(XXX) XXX XX XX"
                          v-model.trim="$v.listingAgent.phone.$model"
                        />
                      </div>
                      <div v-if="$v.listingAgent.phone.$error">
                        <span
                          v-if="!$v.listingAgent.phone.required"
                          class="pl-10 pr-10 invalid-feedback"
                        >
                          Phone field is required
                        </span>
                        <span
                          v-else-if="!$v.listingAgent.phone.minLength"
                          class="pl-10 pr-10 invalid-feedback"
                        >
                          Phone field must have a valid 10 digit number (without
                          Country code)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group" v-if="showSubjectPropertyInModal">
                  <div class="row">
                    <div class="form-input mb-0">
                      <label>Subject Property</label>
                      <div class="input-field">
                        <img
                          src="../assets/images/map-icon.svg"
                          alt="map icon"
                        />
                        <vue-google-autocomplete
                          ref="modalAddress"
                          id="modal-form"
                          v-on:placechanged="getProperty"
                          v-on:inputChange="getProperty"
                          placeholder="Subject Property"
                          v-model.trim="listingAgent.property"
                          country="us"
                          :class="
                            $v.listingAgent.property.$invalid ||
                            listingAgent.property === 'TBD' ||
                            listingAgent.property === 'null'
                              ? 'is-invalid'
                              : ''
                          "
                          :fields="[
                            'formatted_address',
                            'address_components',
                            'geometry',
                          ]"
                        >
                        </vue-google-autocomplete>
                      </div>
                      <div
                        v-if="$v.listingAgent.property.$invalid"
                        class="pl-10 pr-10"
                      >
                        <span
                          class="error-message"
                          v-if="!$v.listingAgent.property.required"
                        >
                          Subject Property is required
                        </span>
                      </div>
                      <div
                        v-else-if="
                          listingAgent.property === 'TBD' ||
                          listingAgent.property === 'null'
                        "
                        class="pl-10 pr-10"
                      >
                        <span class="error-message">
                          Invalid Subject Property
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-link"
                @click="contactListingAgent = 'No'"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary rounded"
                data-bs-dismiss="modal"
                :disabled="
                  $v.listingAgent.$invalid || invalidSubjectPropertyInModal
                "
                @click="
                  saveContactListingAgent();
                  createTask();
                "
              >
                Contact & Download PDF
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="contact-listing-agent-bar">
        <div class="contact-listing-agent-bar-container">
          <template v-for="(client, index) in clientList">
            <div
              v-if="checkAppointmentFlag(client?.contactId)"
              :key="client?.mapping?.prospectOrDealId || index"
              class="box"
            >
              <div class="icon">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2 0H4V2H10V0H12V2H14V4H0V2H2V0ZM0 5H14V14H0V5ZM4 7H2V9H4V7ZM2 10H4V12H2V10ZM8 7H6V9H8V7ZM6 10H8V12H6V10ZM12 7H10V9H12V7Z"
                    :fill="getAssets.primaryBrandColor || '#BE2530'"
                  />
                </svg>
              </div>
              <div class="title-desc">
                <h4>Contact Listing Agent for {{ client.clientName }}</h4>
                <ul>
                  <li>
                    <span>{{
                      client?.mapping?.listingAgentContactTime | dateFormat
                    }}</span>
                  </li>
                  <li>
                    <span>{{ client.subjectProperty | onlyStreet }}</span>
                  </li>
                </ul>
                <div class="cross-btn">
                  <button
                    type="button"
                    class="close"
                    data-bs-toggle="modal"
                    :data-bs-target="`#keepOrCancelListingAgentMeetModal${
                      client?.mapping?.prospectOrDealId || index
                    }`"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.00004 2L14 14M2 14L14 2.00004"
                        stroke="#707070"
                        stroke-width="2"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              :key="`modal${client?.mapping?.prospectOrDealId || index}`"
              data-bs-keyboard="false"
              :id="`keepOrCancelListingAgentMeetModal${
                client?.mapping?.prospectOrDealId || index
              }`"
              tabindex="-1"
              aria-hidden="true"
            >
              <div class="modal-lg modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">
                      Are you sure you want to cancel Listing Agent meeting
                      schedule for <strong>{{ client.clientName }}</strong
                      >?
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn-custom-width btn btn-outline rounded"
                      data-bs-dismiss="modal"
                    >
                      No, Keep meeting
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary rounded"
                      data-bs-dismiss="modal"
                      @click="
                        deleteAppointment(
                          client?.mapping?.prospectOrDealId,
                          client.clientName
                        )
                      "
                    >
                      Yes, Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <ProfileInfo />

    <!-- Live Chat component starts here -->
    <!-- <LiveChat /> -->
    <!-- Live Chat component ends here -->

    <!-- HTML to PDF Code -->
    <!-- <div ref="approvalLetter" class="no-show">
      <div class="row">
        <div class="left-part">
          <div class="logo">
            <img src="../assets/images/pdf-logo.png" alt="logo" />
          </div>
          <div class="left-content-position">
            <img
              src="../assets/images/ic_side_text.png"
              class="img-rotate"
              alt="side text"
            />
          </div>
        </div>

        <div class="right-part">
          <div class="row header-part">
            <div class="header-left-part">
              <h2 class="color-white">LOCKLEAR LEADING TEAM</h2>
              <p class="color-white font-21 op-07">GARRETT LOCKLEAR</p>
              <p class="color-white font-21 op-07">
                SENIOR LOAN ORGINATOR & TEAM LEAD
              </p>
            </div>
            <div class="header-right-part">
              <p class="color-white font-21 op-07">O: 615.933.2080</p>
              <p class="color-white font-21 op-07">M: 615.944.3475</p>
              <p class="color-white font-12 mb-1">
                Created: {{ currentDate }} Exp
              </p>
            </div>
          </div>

          <div class="content-part">
            <p class="ml-15 mb-30 font-21 font-weight">
              {{ loanType }} Mortgage Pre-Approval for Borrower(s)
            </p>
            <p class="ml-15 mb-30 font-21 font-weight">
              Borrower(s)/Buyer(s): {{ selectedClient }} <br />
              Subject Property: {{ subjectProperty }}
            </p>
            <p class="ml-15 mb-30 font-21">To Whom It May Concern;</p>
            <p class="ml-15 mb-30 font-21 circle">
              This letter is to inform any/all parties necessary that the above
              borrower(s) is/are PreApproved for a purchase price of
              <strong>${{ purchasePrice }}</strong>
              with <strong>10.0%</strong> down via
              <strong>{{ loanType }}</strong> financing.
            </p>
            <p class="ml-15 mb-30 font-21 font-weight">
              The following loan qualification status verifies the specific
              approval status of the borrower(s).
            </p>
            <p class="ml-15 mb-30 font-21">
              () Pre-Qualification. This selection is checked upon review of
              stated credit, income, and assets meeting or exceeding guideline
              expectations. DO/DU can be run at any time upon property
              selection.
            </p>
            <p class="ml-15 mb-30 font-21">
              <strong>(X) Pre-Approval.</strong> This selection is checked upon
              manual review of credit, stated income, stated assets meeting or
              exceeding guideline expectations but also includes approval.
            </p>
            <p class="ml-15 mb-30 font-21 fot-weight circle cir-set">
              () UNDERWRITING APPROVAL. This selection is checked upon manual
              review of credit, income, assets meeting or exceeding guidelines,
              includes DO/DU approval and has been presented to Underwriting for
              issuance and receipt of a conditional loan commitment.
            </p>
            <p class="ml-15 mb-30 font-21 font-weight">
              Our Pre-Approval is contingent upon the following Conventional
              standard requirements.
            </p>
            <div class="custom-list">
              <p class="font-21">
                Conventional Property Appraisal – Must meet Conventional
                Guidelines
              </p>
              <p class="font-21">Clear Title</p>
              <p class="font-21">One year Homeowner's Insurance</p>
              <p class="font-21">Copy of cleared earnest money check</p>
              <p class="font-21">Credit and income to remain the same</p>
            </div>
            <p class="ml-15 mb-30 font-21 circle cir-set-0">
              Respectfully submitted,
            </p>
          </div>

          <div class="signature">
            <img
              src="../assets/images/ic_sign.png"
              width="350"
              alt="signature"
            />
            <p class="color-gray">GARRETT LOCKLEAR</p>
            <p class="color-gray">
              SENIOR LOAN ORGINATOR & TEAM LEAD | THE LOCKLEAR LEADING TEAM |
              NMLS 820797
            </p>
          </div>
        </div>
      </div>

      <div class="footer-part">
        <div class="row">
          <div class="f-lpart">
            <div class="ft-lcontent">
              <img src="../assets/images/ic_mm_logo.png" alt="mm logo" />
            </div>
          </div>
          <div class="f-rpart">
            <div class="ft-rcontent">
              <p class="color-white font-21">
                <span>GARRETT@LOCKLEARLENDINGTEAM.COM </span
                ><img
                  width="50"
                  src="../assets/images/ic_btm_right_logo.png"
                  alt="homelogo"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "./../event-bus";
import Toasters from "./../mixins/toasters";
import Numbers from "../mixins/numbers";
import Shades from "./../mixins/shades";
import moment from "moment";
import {
  required,
  numeric,
  email,
  minLength,
  requiredIf,
} from "vuelidate/lib/validators";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import InterestRateToolBar from "./InterestRateToolBar.vue";
import InfoTooltipIcon from "./Icons/InfoTooltipIcon.vue";
import ProfileInfo from "./Profile-Info.vue";
// import html2pdf from "html2pdf.js";

export default {
  name: "Approval-Letters",
  components: {
    VueGoogleAutocomplete,
    DatePicker,
    InterestRateToolBar,
    InfoTooltipIcon,
    ProfileInfo,
  },
  mixins: [Toasters, Numbers, Shades],
  data() {
    return {
      theClient: {},
      clientList: [],
      selectedClient: "",
      subjectProperty: "",
      purchasePrice: "",
      maxApproval: "",
      loanType: "",
      dealId: "",
      contactId: "",
      sellerPaid: false,
      maxSellerConcessionPercent: 0,
      maxSellerConcessionDollar: 0,
      closingCostType: "",
      prospectStatus: "",
      annualPropertyTaxes: "",
      transferTaxes: "",
      missingProperty: false,
      contactListingAgent: "No",
      listingAgentsList: [],
      showAgentsList: false,
      visibleAgentsList: [],
      listingAgent: {
        id: null,
        name: "",
        contact_time: "",
        email: "",
        phone: "",
        property: "",
      },
      preventNextIteration: false,
      dataToUpdate: {
        isChanged: false,
        purchasePrice: "",
        street: "",
        city: "",
        state: "",
        country: "",
        zipCode: "",
      },
      showInfo: false,
      maxSellerPercentInfo: false,
      maxSellerDollarInfo: false,
      timePickerOptions: {
        start: "08:00",
        step: "00:30",
        end: "17:30",
        format: "hh:mm A",
      },
      scheduledAppointments: {},
      userTimeZone: null,
      customOpOtion: false,
    };
  },
  filters: {
    dateFormat: (date) => {
      return moment(date, "MM.DD.YYYY h:mm A").format("MM.DD.YYYY h:mm A");
    },
    onlyStreet: (property) => {
      return property.split(",")[0];
    },
  },
  validations: {
    selectedClient: {
      required,
    },
    purchasePrice: {
      required,
      numeric,
    },
    loanType: {
      required,
    },
    annualPropertyTaxes: {
      numeric,
    },
    transferTaxes: {
      numeric,
    },
    listingAgent: {
      name: {
        required: requiredIf(function () {
          return this.contactListingAgent === "Yes";
        }),
      },
      contact_time: {
        required: requiredIf(function () {
          return this.contactListingAgent === "Yes";
        }),
      },
      email: {
        required: requiredIf(function () {
          return this.contactListingAgent === "Yes";
        }),
        email,
      },
      phone: {
        required: requiredIf(function () {
          return this.contactListingAgent === "Yes";
        }),
        minLength: minLength(12),
      },
      property: {
        required: requiredIf(function () {
          return (
            this.contactListingAgent === "Yes" &&
            this.showSubjectPropertyInModal
          );
        }),
      },
    },
  },
  watch: {
    selectedClient(newVal) {
      if (newVal) {
        this.subjectProperty = "";
        this.listingAgent.property = "";
        this.annualPropertyTaxes = "";
        this.transferTaxes = "";
        this.theClient = this.clientList.find((v) => v.clientName === newVal);
        this.closingCostType = this.theClient.Agent_Portal_Closing_Costs;
        this.prospectStatus = this.theClient.status;
        this.purchasePrice = this.theClient.purchasePrice || 0;
        this.maxApproval = this.theClient.maxApproval || 0;
        this.loanType = this.theClient.loanType;
        this.sellerPaid =
          this.theClient.titlePaidBy === "Buyer Paid" ? false : true;
        this.maxSellerConcessionPercent =
          this.theClient?.mapping?.maxSellerConcessionPercentage || 0;
        this.maxSellerConcessionDollar =
          this.theClient?.mapping?.maxSellerConcessionCurrency || 0;
        this.contactId = this.theClient.contactId;
        this.dealId =
          this.theClient?.mapping?.prospectOrDealId ||
          this.theClient?.prospectOrDealId;
        this.subjectProperty = this.theClient?.subjectProperty || "TBD";
        this.$refs.mainAddress.update(this.theClient?.subjectProperty || "TBD");
        this.$v.$reset();

        let listing_agent_id = this.theClient?.mapping?.contactListingAgentId;

        if (listing_agent_id) {
          let listing_agent_obj = this.listingAgentsList.find(
            (agent) => agent.Id === listing_agent_id
          );

          if (listing_agent_obj) {
            this.selectContactListingAgent(listing_agent_obj);
          } else {
            this.emptyListingAgentForm();
            this.listingAgent.name = "";
          }
        } else {
          this.emptyListingAgentForm();
          this.listingAgent.name = "";
        }

        for (const key in this.dataToUpdate) {
          if (key === "isChanged") this.dataToUpdate[key] = false;
          else this.dataToUpdate[key] = "";
        }

        if (this.showManualFields) {
          let infoMessage =
            "Please enter Annual Property Taxes and Transfers Tax to get accurate Closing Costs";
          this.info(infoMessage);
        }
      } else {
        this.resetTheForm();
        this.emptyListingAgentForm();
        this.listingAgent.name = "";
      }
    },

    subjectProperty(newVal) {
      if (
        (!newVal || newVal === "null null, null, null") &&
        this.selectedClient
      ) {
        this.subjectProperty = "TBD";
        this.$refs.mainAddress.update("TBD");
      }
    },

    purchasePrice(newVal) {
      if (Number(newVal) === this.theClient.purchasePrice) {
        this.dataToUpdate.isChanged = false;
        this.dataToUpdate.purchasePrice = "";
      } else {
        this.dataToUpdate.isChanged = true;
        this.dataToUpdate.purchasePrice = newVal;
      }

      if (newVal) {
        this.maxSellerConcessionDollar = (
          (this.maxSellerConcessionPercent / 100) *
          newVal
        ).toFixed(0);
      }
    },

    contactListingAgent(newVal) {
      if (newVal === "No" && !this.theClient?.mapping?.contactListingAgentId) {
        this.listingAgent = {
          id: null,
          name: "",
          contact_time: "",
          email: "",
          phone: "",
          property: "",
        };

        this.$v.listingAgent.$reset();
      }
    },

    "listingAgent.name": {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal.length > 1) {
          this.visibleAgentsList = this.listingAgentsList.filter((agent) =>
            agent.Name.toLowerCase().includes(newVal.toLowerCase())
          );

          if (!this.visibleAgentsList.length) this.emptyListingAgentForm();
        } else {
          this.visibleAgentsList = this.listingAgentsList;
          this.emptyListingAgentForm();
        }
      },
    },

    "listingAgent.contact_time": {
      deep: true,
      handler(newVal) {
        if (newVal && this.contactListingAgent === "Yes") {
          let current_time = moment(new Date(), "MM-DD-YYYY h:mm A", true);
          let selected_time = moment(newVal, "MM-DD-YYYY h:mm A");

          if (current_time.isSame(selected_time, "date")) {
            let start_time;

            if (moment().minutes() >= 0 && moment().minutes() < 30)
              start_time = `${moment().hour()}:30`;
            if (moment().minutes() >= 30)
              start_time = `${moment().hour() + 1}:00`;

            this.timePickerOptions = {
              start: start_time,
              step: "00:30",
              end: "17:30",
              format: "hh:mm A",
            };
          } else {
            this.timePickerOptions = {
              start: "08:00",
              step: "00:30",
              end: "17:30",
              format: "hh:mm A",
            };
          }
        }
      },
    },

    "listingAgent.phone": {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.numberWithDash(newVal);
        }
      },
    },
  },
  computed: {
    ...mapGetters(["getOrgId", "getProspectId", "getUser", "getAssets"]),

    currentDate() {
      return moment().format("MMM D, YYYY");
    },

    allowedMaxAmount() {
      if (this.maxApproval) return this.purchasePrice <= this.maxApproval;
      else return true;
    },

    showManualFields() {
      return this.closingCostType === "Manual" && this.prospectStatus === "PP";
    },

    showClosingCostField() {
      if ("hideClosingCostAgentPortal" in this.getAssets) {
        if (this.getAssets.hideClosingCostAgentPortal) return false;
      }

      return true;
    },

    closingCost() {
      if (Object.keys(this.theClient).length === 0) return "";

      if (
        this.showManualFields &&
        (!this.annualPropertyTaxes || !this.transferTaxes)
      )
        return "";

      if (!this.allowedMaxAmount) return "";
      if (this.$v.$invalid) return "";

      let closing_cost = 0;
      let client = this.theClient;

      let purchase_price = this.purchasePrice || 0;
      let lender_fees = client.Lender_Fees || 0;
      let title_fees = client.Title_Fees || 0;
      let misc_fees = client.Misc_Fees || 0;
      let appraisal_fee = client.Appraisal_Fee || 0;
      let tax_stamps = client.Tax_Stamps || 0;
      let annual_hoi_premium = client.Annual_HOI_Premium || 0;
      let annual_tax_rate = client.Annual_Tax_Rate || 0;
      let client_scenario = client.Client_Scenario;
      let loan_amount_auto = client.Loan_Amount || 0;
      let points = client.Points;
      let total_loan_amount = client.Total_Loan_Amount || 0;
      let interest_rate = client.Interest_Rate
        ? client.Interest_Rate.slice(0, -1)
        : 0;
      let ltv = client.Ltv || 0;
      let loan_type = client.Loan_Type_Auto;
      let agent_portal_closing_cost = this.closingCostType;

      // For Manual Type and PP prospect status
      let annual_property_taxes = this.showManualFields
        ? Number(this.annualPropertyTaxes || 0)
        : 0;
      let transfer_taxes = this.showManualFields
        ? Number(this.transferTaxes || 0)
        : 0;

      loan_amount_auto = this.roundTheNumber((purchase_price * ltv) / 100, 0);

      if (loan_type === "FHA" || loan_type === "THDA")
        total_loan_amount = loan_amount_auto * 1.0175;
      else if (loan_type === "USDA")
        total_loan_amount = loan_amount_auto * 1.01;
      else if (loan_type === "VA")
        total_loan_amount = loan_amount_auto * 1.0215;
      else total_loan_amount = loan_amount_auto;

      let loan_amount = 0;

      if (loan_amount_auto < 450000) loan_amount = loan_amount_auto * 0.00415;
      else if (loan_amount_auto >= 450000 && loan_amount_auto < 600000)
        loan_amount = loan_amount_auto * 0.00375;
      else if (loan_amount_auto >= 600001 && loan_amount_auto < 900000)
        loan_amount = loan_amount_auto * 0.00329;
      else if (loan_amount_auto > 900000)
        loan_amount = loan_amount_auto * 0.0037;
      else loan_amount = loan_amount_auto * 0.00296;

      let amount1 =
        points != "" ? (Number(points) * total_loan_amount) / 100 : 0;

      let interest = 0;
      interest = ((loan_amount_auto * interest_rate) / 100 / 365) * 15;

      if (client_scenario == "Refinance") {
        closing_cost =
          lender_fees +
          loan_amount +
          title_fees +
          loan_amount_auto * 0.0014 +
          misc_fees +
          amount1 +
          interest;
      }

      if (client_scenario != "Refinance") {
        if (agent_portal_closing_cost == "Auto") {
          closing_cost =
            appraisal_fee +
            lender_fees +
            title_fees +
            (purchase_price * tax_stamps) / 100 +
            misc_fees +
            annual_hoi_premium +
            amount1 +
            (purchase_price * 0.25 * annual_tax_rate * 0.2) / 100 +
            interest;
        }

        if (agent_portal_closing_cost != "Auto") {
          closing_cost =
            appraisal_fee +
            lender_fees +
            title_fees +
            (purchase_price * tax_stamps) / 100 +
            misc_fees +
            annual_hoi_premium +
            amount1 +
            annual_property_taxes * 0.2 +
            interest +
            transfer_taxes;
        }
      }
      let owners_title = total_loan_amount * 0.00057;
      let lenders_title = total_loan_amount * 0.0052;

      closing_cost = !this.sellerPaid
        ? closing_cost + owners_title + lenders_title
        : closing_cost;

      return this.roundTheNumber(closing_cost, 0);
    },

    showSubjectPropertyInModal() {
      if (
        this.subjectProperty === "" ||
        this.subjectProperty === "null" ||
        this.subjectProperty === "NULL" ||
        this.subjectProperty === "Null" ||
        this.subjectProperty === "tbd" ||
        this.subjectProperty === "TBD" ||
        this.subjectProperty === "Tbd"
      ) {
        return true;
      }

      return false;
    },

    invalidSubjectPropertyInModal() {
      if (
        this.listingAgent.property === "null" ||
        this.listingAgent.property === "NULL" ||
        this.listingAgent.property === "Null" ||
        this.listingAgent.property === "tbd" ||
        this.listingAgent.property === "TBD" ||
        this.listingAgent.property === "Tbd"
      ) {
        return true;
      }

      return false;
    },

    revealContactListingAgent() {
      let prospectStatuses = [
        "New",
        "Contacted",
        "RIN",
        "PP",
        "Submitted for UUA",
        "UUA",
      ];

      return (
        this.getAssets.showContactListingAgent &&
        this.dealId &&
        this.theClient.isProspect &&
        prospectStatuses.includes(this.theClient.status)
      );
    },
    displayUserTimeZone() {
      return this.userTimeZone ? `- ${this.userTimeZone}` : null;
    },
  },
  mounted() {
    this.getClients();
    this.getListingAgents();
    eventBus.$on("portalAssetsData", (data) => {
      if (data?.userTimeZone) {
        this.userTimeZone = data?.userTimeZone?.toString();
      }
    });

    document.getElementById("yesModal").addEventListener("click", (e) => {
      // Check if the modal is clicked, not an element inside the modal:
      if (e.target === e.currentTarget && this.contactListingAgent === "Yes") {
        this.contactListingAgent = "No";
      }
    });

    setInterval(this.validateScheduledAppointment, 1000);
  },
  methods: {
    checkAppointmentFlag(contactId) {
      return this.scheduledAppointments[contactId] || false;
    },

    validateScheduledAppointment() {
      const scheduledAppointments = {};

      this.clientList.forEach((item) => {
        scheduledAppointments[item.contactId] = this.validateTimeInFuture(item);
      });

      this.scheduledAppointments = scheduledAppointments;
    },

    validateTimeInFuture(item) {
      if (!item?.mapping?.listingAgentContactTime) return false;

      const appointmentTime = moment(
        item?.mapping?.listingAgentContactTime,
        "MM.DD.YYYY h:mm A"
      )
        .toDate()
        .getTime();

      return appointmentTime >= Date.now();
    },

    async getClients() {
      eventBus.$emit("loader-status", true);

      await this.$http
        .get(
          "/approval/getApprovalLetterData/" +
            this.getProspectId +
            "?orgId=" +
            this.getOrgId
        )
        .then((response) => {
          let resp = response.data;

          if (resp.status === 200) {
            this.clientList = resp.data;

            this.clientList.sort((a, b) => {
              let clientA = a.clientName.toUpperCase();
              let clientB = b.clientName.toUpperCase();

              return clientA.localeCompare(clientB);
            });

            this.validateScheduledAppointment();
          }
        })
        .catch((error) => {
          console.log(error);
        });

      eventBus.$emit("loader-status", false);
    },

    getListingAgents() {
      this.$http
        .get(
          "/contactListingAgent?loId=" +
            this.getProspectId +
            "&orgId=" +
            this.getOrgId
        )
        .then((response) => {
          let resp = response.data;

          if (resp.status === 200) {
            this.listingAgentsList = resp.agentDetails;

            this.listingAgentsList.sort((a, b) => {
              let agentA = a.Name.toUpperCase();
              let agentB = b.Name.toUpperCase();

              return agentA.localeCompare(agentB);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    saveContactListingAgent() {
      const payLoad = {
        requestBody: {
          listingAgent: this.listingAgent,
          orgId: this.getOrgId,
          recordId: this.dealId,
        },
      };

      this.$http
        .post("/saveContactListingAgent", payLoad)
        .then((response) => {
          let resp = response.data;

          if (resp.status === 200) {
            let prospect_id = resp.listingAgentDetails?.prospectOrDealId;

            if (!this.listingAgent.id) {
              this.getListingAgents();
            }

            if (prospect_id) {
              this.clientList.forEach((rec) => {
                if (rec?.mapping?.prospectOrDealId === prospect_id) {
                  rec.mapping = resp.listingAgentDetails;
                }
              });
            }
          }

          this.contactListingAgent = "No";
          this.listingAgent.contact_time = "";
          this.$v.listingAgent.contact_time.$reset();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async deleteAppointment(id, name) {
      eventBus.$emit("loader-status", true);

      const payLoad = {
        orgId: this.getOrgId,
        recordId: id,
        contactListingAgentId: null,
        listingAgent: null,
      };

      await this.$http
        .put("/removeScheduledAppointment", payLoad)
        .then((response) => {
          let resp = response.data;

          if (resp.status === 200) {
            this.getClients();
            this.selectedClient = "";
            this.success(`Scheduled appointment for ${name} has been deleted.`);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      eventBus.$emit("loader-status", false);
    },

    async createTask() {
      eventBus.$emit("loader-status", true);

      // html2pdf(this.$refs.approvalLetter.innerHTML, {
      //   margin: 0,
      //   filename: this.selectedClient + " Pre-Approval Letter.pdf",
      //   image: { type: "jpeg", quality: 1 },
      //   html2canvas: {
      //     dpi: 192,
      //     scale: 4,
      //     letterRendering: true,
      //   },
      //   jsPDF: { unit: "cm", format: "a4", orientation: "portrait" },
      // });

      const payLoad = {
        accountId: this.getProspectId,
        orgId: this.getOrgId,
        prospectOrDealId: this.dealId,
        contactId: this.contactId,
        clientName: this.selectedClient,
        dataToUpdate: this.dataToUpdate,
      };

      await this.$http
        .post("/approval/createTaskForApprovalLetter", payLoad)
        .then((response) => {
          let resp = response.data;

          if (resp.status === 511) this.$router.push("/");

          if (resp.status === 200) {
            this.getClients();
            this.success(resp.message);

            for (const key in this.dataToUpdate) {
              if (key === "isChanged") this.dataToUpdate[key] = false;
              else this.dataToUpdate[key] = "";
            }

            let clientNameWithCaps = this.selectedClient.replace(/\b\w/g, (l) =>
              l.toUpperCase()
            );

            let fileName = clientNameWithCaps + " Pre-Approval Letter.pdf";
            this.downloadPDF(resp.byteData, fileName);
            this.selectedClient = "";
          }
        })
        .catch((error) => {
          console.log(error);
        });

      eventBus.$emit("loader-status", false);
    },

    downloadPDF(byteData, fileName) {
      const binaryString = window.atob(byteData);
      const bytes = new Uint8Array(binaryString.length);
      const mappedData = bytes.map((byte, i) => binaryString.charCodeAt(i));
      const blob = new Blob([mappedData], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      var link = document.createElement("a");
      link.download = fileName;
      link.href = fileURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    getProperty(addressData, place) {
      if (this.selectedClient && place.formatted_address !== undefined) {
        if (addressData.route) {
          this.dataToUpdate.isChanged = true;

          let completeStreet;

          if (addressData.street_number)
            completeStreet =
              addressData.street_number + " " + addressData.route;
          else completeStreet = addressData.route;

          this.dataToUpdate.street = completeStreet;

          if (this.contactListingAgent === "No") {
            this.subjectProperty = place.formatted_address;
          }

          if (this.contactListingAgent === "Yes") {
            this.listingAgent.property = place.formatted_address;
          }
        }

        if (addressData.locality) this.dataToUpdate.city = addressData.locality;
        else {
          if (addressData.administrative_area_level_2) {
            let theCity = addressData.administrative_area_level_2;

            if (theCity.includes("County")) {
              theCity = theCity.replace("County", "");

              this.dataToUpdate.city = theCity.trim();
            }
          }
        }

        if (addressData.administrative_area_level_1)
          this.dataToUpdate.state = addressData.administrative_area_level_1;

        if (addressData.country)
          this.dataToUpdate.country = addressData.country;

        if (addressData.postal_code)
          this.dataToUpdate.zipCode = addressData.postal_code;
      } else {
        if (this.contactListingAgent === "No") {
          this.subjectProperty = addressData.newVal;
        }

        if (this.contactListingAgent === "Yes") {
          this.listingAgent.property = addressData.newVal;
        }
      }

      if (this.selectedClient && this.contactListingAgent === "No") {
        if (!addressData.newVal) this.missingProperty = true;
        if (addressData.newVal) this.missingProperty = false;
      }
    },

    resetTheForm() {
      this.theClient = {};
      this.subjectProperty = "";
      this.purchasePrice = "";
      this.maxApproval = "";
      this.loanType = "";
      this.dealId = "";
      this.contactId = "";
      this.sellerPaid = false;
      this.closingCostType = "";
      this.prospectStatus = "";
      this.annualPropertyTaxes = "";
      this.transferTaxes = "";
      this.$refs.mainAddress.update("");
      this.missingProperty = false;
      this.contactListingAgent = "No";
      this.$v.$reset();
    },

    selectContactListingAgent(obj) {
      this.listingAgent.id = obj.Id;
      this.listingAgent.name = obj.Name;
      this.listingAgent.email = obj?.Email__c || obj?.CANDIDOEM__Email__c;
      this.listingAgent.phone = obj.Phone;
      this.showAgentsList = false;
    },

    setDefaultTime(date) {
      let current_time = moment(new Date(), "MM-DD-YYYY h:mm A", true);
      let selected_time = moment(date, "MM-DD-YYYY h:mm A");

      let time;
      let day = date.getDate();
      let month = date.getMonth() + 1;

      let set_day = day < 10 ? `0${day}` : day;
      let set_month = month < 10 ? `0${month}` : month;

      if (current_time.isSame(selected_time, "date")) {
        let hours = new Date().getHours();
        let minutes = new Date().getMinutes();
        let meridian = hours > 11 ? "PM" : "AM";

        time =
          minutes < 30
            ? `${hours}:30 ${meridian}`
            : `${hours + 1}:00 ${meridian}`;
      } else {
        time = "8:00 AM";
      }

      this.listingAgent.contact_time = `${set_month}-${set_day}-${date.getFullYear()} ${time}`;
    },

    disabledPastDates(date) {
      const today = new Date();

      if (
        moment().hour() < 17 ||
        (moment().hour() === 17 && moment().minute() < 30)
      ) {
        today.setHours(0, 0, 0, 0);
      }

      return date < today;
    },

    // disabledHours(date) {
    //   return (
    //     date < new Date(date.getTime()).setHours(8, 0, 0, 0) ||
    //     date > new Date(date.getTime()).setHours(17, 30, 0, 0)
    //   );
    // },

    emptyListingAgentForm() {
      this.showAgentsList = false;
      this.listingAgent.id = null;
      this.listingAgent.email = "";
      this.listingAgent.phone = "";
      this.listingAgent.property = "";
    },

    numberWithDash(number) {
      if (number) {
        const num = number
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

        this.listingAgent.phone =
          num[1] + (num[2] ? "-" + num[2] : "") + (num[3] ? "-" + num[3] : "");
      }
    },
    toggleCustomOption() {
      this.customOpOtion = !this.customOpOtion;
    },
    referClient() {
      eventBus.$emit("modal-text", "Add Client");
    },
  },
};
</script>

<style scoped>
@import "./../assets/css/pdf.css";

.add-custom-margin {
  margin: 20px 0;
}

.custom-divider {
  width: 100%;
  height: 1px;
  background-color: #e8ecf1;
  margin: 5px 0 25px 0 !important;
}

@media (max-width: 767px) {
  .add-custom-margin {
    margin-top: 1rem !important;
  }
}
.dropdown.show .dropdown-menu {
  visibility: visible;
  opacity: 1;
  top: 25px !important;
  margin-top: 25px !important;
}
.dropdown.show .dropdown-toggle::after {
  transform: rotate(180deg);
}
.dropdown .dropdown-toggle {
  padding: 16px 0;
  padding-right: 20px;
  position: relative;
  font-size: 16px;
  color: var(--color-primary);
}
.dropdown .dropdown-toggle::after {
  background-position: center top 5px;
  border: 0;
  width: 12px;
  height: 15px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.dropdown .dropdown-toggle.show::after {
  transform: rotate(180deg);
}
.dropdown .dropdown-menu {
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border: 1px solid var(--color-divider);
  right: 0 !important;
  left: unset !important;
  margin-top: 50px !important;
  transform: unset !important;
  padding: 0;
  display: block !important;
  visibility: hidden;
  opacity: 0;
}
.dropdown .dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  max-height: 300px;
  overflow: auto;
}
.dropdown .dropdown-menu li:not(:last-child) {
  border-bottom: 1px solid var(--color-divider);
}
.dropdown .dropdown-menu li .dropdown-item {
  font-size: 16px;
  color: var(--color-primary);
  padding: 13px 15px;
}
#yesModal .dropdown.name-dropdown .dropdown-menu {
  left: 0 !important;
  border-radius: 4px;
  border: 1px solid #dcdfe5;
  background: var(--foundation-gray-01, #fff);
  box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.2);
  margin-top: 12px !important;
  padding: 5px 0;
}
#yesModal .dropdown.name-dropdown .dropdown-menu.show {
  margin-top: 35px !important;
}
#yesModal .dropdown.name-dropdown .dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
}
#yesModal .dropdown.name-dropdown .dropdown-menu .dropdown-item .icon {
  margin-right: 8px;
}
#yesModal .dropdown.name-dropdown .dropdown-menu .dropdown-item .icon img {
  all: unset;
}
#keepOrCancelListingAgentMeetModal .btn {
  font-size: 15px;
}
.btn-custom-width {
  width: auto;
  color: #262a31;
  border: 1px solid #e0e4e8;
}
.btn-custom-width:hover {
  color: white;
}
</style>

<style>
.pac-container {
  z-index: 1061 !important;
}
.custom-select-wrap .option {
  display: none !important;
}
.custom-select-wrap {
  position: relative;
}
.custom-option {
  width: 100%;
  position: absolute;
  top: 40px;
  background-color: #ffffff;
  z-index: 1;
  padding: 20px;
  text-align: center;
  box-shadow: 0px 2px 12px -2px #00000033;
  border-radius: 4px;
  color: #707070;
}
.custom-select {
  pointer-events: none;
}
</style>
