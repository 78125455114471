<template>
  <div class="content__wrapper pb-0">
    <header>
      <InterestRateToolBar :interest-rates="getAssets?.rateHistoryData?.[0] || {}" />
      <div class="container">
        <div class="title-search">
          <h1 class="page-heading">Condos</h1>
          <div class="global__search">
            <div class="form-input">
              <input
                v-model="searchCondo"
                type="text"
                placeholder="Search by Name or Address"
                class="global__search-input"
              />
              <i class="fa fa-search zero-index"></i>
            </div>
          </div>
        </div>
      </div>
    </header>
    <main class="h-condos condos-height-main">
      <div class="main__wrapper">
        <div class="container">
          <div class="tab-content condos">
            <div class="tab-pane fade show active">
              <div class="filter my-3">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="filter__title">
                    <i class="fa fa-filter"></i>
                    Filter:
                  </div>
                  <div class="d-flex flex-grow-1 justify-content-end">
                    <div class="filter__dropdown cursor-pointer">
                      <div class="dropdown">
                        <button
                          class="btn dropdown-toggle font-weight-normal"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {{ areaFilterValue === "" ? "by Area" : areaFilterValue }}
                          <i class="fa fa-chevron-up"></i>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li>
                            <a class="dropdown-item" @click="applyAreaFilter('')"
                              >All Areas</a
                            >
                          </li>
                          <li v-for="(area, index) in areaFilter" :key="index">
                            <a class="dropdown-item" @click="applyAreaFilter(area)">{{
                              area
                            }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="filter__dropdown cursor-pointer">
                      <div class="dropdown downpayment-dropdown">
                        <button
                          class="btn dropdown-toggle font-weight-normal"
                          type="button"
                          data-bs-auto-close="outside"
                          id="downPayment"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          by Down Payment <i class="fa fa-chevron-up"></i>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <!-- <li>
                            <a class="dropdown-item" href="#"
                              >All Down Payments</a
                            >
                          </li> -->
                          <li v-for="(dp, index) in dpFilter" :key="index">
                            <div class="checkbox">
                              <input
                                type="checkbox"
                                :value="dp"
                                :id="dp + '%'"
                                v-model="dpFilterValue[index]"
                              />
                              <label :for="dp + '%'">{{ dp }}%</label>
                            </div>
                          </li>
                          <li class="height-auto">
                            <div class="button">
                              <button
                                @click="dpFilterValue = []"
                                class="btn btn-primary-outline"
                              >
                                Clear
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table__wrapper">
                <!----Desktop table code start---->
                <div class="table-responsive condos-table d-none d-sm-block">
                  <table v-if="showCondos.length" class="table">
                    <thead>
                      <tr>
                        <th
                          @click="searchCondo.length <= 2 ? sortByName() : ''"
                          :class="searchCondo.length <= 2 ? 'sorting' : ''"
                        >
                          Condo Name<span></span>
                        </th>
                        <th class="text-left">Address</th>
                        <th
                          class="text-center"
                          @click="searchCondo.length <= 2 ? sortByStatus() : ''"
                          :class="searchCondo.length <= 2 ? 'sorting' : ''"
                        >
                          Status<span></span>
                        </th>
                        <th
                          class="text-center"
                          @click="searchCondo.length <= 2 ? sortByArea() : ''"
                          :class="searchCondo.length <= 2 ? 'sorting' : ''"
                        >
                          Area<span></span>
                        </th>
                        <th
                          @click="searchCondo.length <= 2 ? sortByPDP() : ''"
                          :class="searchCondo.length <= 2 ? 'sorting' : ''"
                        >
                          Primary DP<span></span>
                        </th>
                        <th
                          @click="searchCondo.length <= 2 ? sortBySDP() : ''"
                          :class="searchCondo.length <= 2 ? 'sorting' : ''"
                        >
                          Second DP<span></span>
                        </th>
                        <th
                          @click="searchCondo.length <= 2 ? sortByIDP() : ''"
                          :class="searchCondo.length <= 2 ? 'sorting' : ''"
                        >
                          Investment DP<span></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="condo in showCondos" :key="condo.id">
                        <td class="name">
                          <div class="whitespace-nowrap">{{ condo.name }}</div>
                        </td>
                        <td>
                          <div class="condos-tooltip">
                            <span class="condos-tooltip-element">{{
                              condo.address ? condo.address : "N/A"
                            }}</span>
                            <div class="condos-tooltip-inside">
                              <p>{{ condo.address ? condo.address : "N/A" }}</p>
                            </div>
                          </div>
                        </td>
                        <td class="text-center">
                          <div class="condos-tooltip status-tooltip">
                            <div
                              class="status rounded"
                              :class="classForStatus(condo.approvalStatus)"
                            >
                              {{ condo.approvalStatus }}
                            </div>
                            <div class="condos-tooltip-inside">
                              <p>
                                {{ textForToolTip(condo.approvalStatus) }}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td class="text-center">
                          <div class="status outline-skyblue rounded area-box">
                            {{ condo.area }}
                          </div>
                        </td>
                        <td class="text-center">
                          {{ condo.primaryDP ? condo.primaryDP + "%" : "N/A" }}
                        </td>
                        <td class="text-center">
                          {{ condo.secondDp ? condo.secondDp + "%" : "N/A" }}
                        </td>
                        <td class="text-center">
                          {{ condo.invDP ? condo.invDP + "%" : "N/A" }}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot v-show="totalItems > 10">
                      <tr>
                        <td colspan="7">
                          <div class="pagination">
                            <div class="pagination__btn">
                              <button
                                @click="page--"
                                type="button"
                                :class="page <= 1 ? 'disabled' : ''"
                                class="pagination__btn-prev button"
                              >
                                <img
                                  src="../assets/images/prev-icon.svg"
                                  class="previous icon"
                                />
                              </button>
                              <button
                                @click="page++"
                                type="button"
                                :class="page === pages.length ? 'disabled' : ''"
                                class="pagination__btn-next button"
                              >
                                <img
                                  src="../assets/images/prev-icon.svg"
                                  class="next icon"
                                />
                              </button>
                            </div>
                            <label>{{ from }}-{{ to }} of {{ totalItems }} items</label>
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <table v-else class="table text-center text-secondary">
                    <h1 class="fw-bolder">No Data Available</h1>
                  </table>
                </div>
                <!----Desktop table code ended---->

                <!----mobile table card code start---->
                <div v-if="showCondos.length" class="table__card d-block d-sm-none">
                  <div class="card" v-for="condo in showCondos" :key="condo.id">
                    <div class="table-row">
                      <label>Condo Name</label>
                      <p>{{ condo.name }}</p>
                    </div>
                    <div class="table-row">
                      <label>Address</label>
                      <p>{{ condo.address ? condo.address : "N/A" }}</p>
                    </div>
                    <div class="table-row">
                      <div class="d-flex flex-nowrap justify-content-between">
                        <label>Status</label>
                        <div class="condos-tooltip status-tooltip">
                          <div
                            class="status rounded"
                            :class="classForStatus(condo.approvalStatus)"
                          >
                            {{ condo.approvalStatus }}
                          </div>
                          <div class="condos-tooltip-inside tooltip-inside">
                            <p>{{ textForToolTip(condo.approvalStatus) }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="table-row">
                      <div class="d-flex flex-nowrap justify-content-between">
                        <label>Area</label>
                        <div class="status outline-skyblue rounded area-box">
                          {{ condo.area }}
                        </div>
                      </div>
                    </div>
                    <div class="table-row">
                      <div class="d-flex flex-nowrap justify-content-between">
                        <label>Primary DP</label>
                        <p>
                          {{ condo.primaryDP ? condo.primaryDP + "%" : "NA" }}
                        </p>
                      </div>
                    </div>
                    <div class="table-row">
                      <div class="d-flex flex-nowrap justify-content-between">
                        <label>Second DP</label>
                        <p>
                          {{ condo.secondDp ? condo.secondDp + "%" : "N/A" }}
                        </p>
                      </div>
                    </div>
                    <div class="table-row">
                      <div class="d-flex flex-nowrap justify-content-between">
                        <label>Investment DP</label>
                        <p>{{ condo.invDP ? condo.invDP + "%" : "N/A" }}</p>
                      </div>
                    </div>
                  </div>
                  <div v-show="totalItems > 10" class="pagination">
                    <div class="pagination__btn">
                      <button
                        @click="page--"
                        type="button"
                        :class="page <= 1 ? 'disabled' : ''"
                        class="pagination__btn-prev button"
                      >
                        <img src="../assets/images/prev-icon.svg" class="previous icon" />
                      </button>
                      <button
                        @click="page++"
                        type="button"
                        :class="page === pages.length ? 'disabled' : ''"
                        class="pagination__btn-next button"
                      >
                        <img src="../assets/images/prev-icon.svg" class="next icon" />
                      </button>
                    </div>
                    <label>{{ from }}-{{ to }} of {{ totalItems }} items</label>
                  </div>
                </div>
                <div
                  v-else
                  class="table__card d-block d-sm-none text-center text-secondary"
                >
                  <h1 class="fw-bolder">No Data Available</h1>
                </div>
                <!----mobile table card code end---->
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <ProfileInfo />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "./../event-bus";
import _ from "lodash";
import CondosSorting from "./../mixins/condos-sorting";
import InterestRateToolBar from "./InterestRateToolBar.vue";
import ProfileInfo from "./Profile-Info.vue";

export default {
  components: {
    InterestRateToolBar,
    ProfileInfo,
  },
  name: "Condos",
  mixins: [CondosSorting],
  data() {
    return {
      allCondos: [],
      condosList: [],
      showCondos: [],
      totalItems: 0,
      areaFilter: [],
      areaFilterValue: "",
      dpFilter: [],
      dpFilterValue: [],
      searchCondo: "",
      multipleFilters: false,
      from: 0,
      to: 0,
      page: 1,
      perPage: 10,
      pages: [],
    };
  },
  watch: {
    searchCondo: _.debounce(function (newVal) {
      if (newVal.length > 2) {
        this.condosList = this.allCondos.filter(
          (condo) =>
            condo.name.toLowerCase().includes(newVal.toLowerCase()) ||
            condo.address.toLowerCase().includes(newVal.toLowerCase())
        );
      } else {
        this.condosList = this.allCondos;
      }

      this.totalItems = this.condosList.length;
      this.setPages(this.condosList);
      this.paginate(this.condosList);
      this.page = 1;
    }, 500),

    page(newVal) {
      if (newVal) this.paginate(this.condosList);
    },

    dpFilterValue(newVal) {
      this.multipleFilters = this.areaFilterValue
        ? newVal?.length && newVal.includes(true)
          ? true
          : false
        : false;

      if (!this.multipleFilters) {
        if (newVal?.length && newVal.includes(true)) {
          const indexes = newVal.reduce((r, n, i) => {
            n === true && r.push(i);

            return r;
          }, []);

          const values = indexes.map((v) => {
            return this.dpFilter[v];
          });

          if (values?.length) {
            this.condosList = [];
            let theArr = [];

            values.forEach((x) => {
              let itemsArr = [];

              itemsArr = this.allCondos.filter(
                (v) => v.primaryDP === x || v.secondDp === x || v.invDP === x
              );

              theArr.push(...itemsArr);
            });

            this.condosList = _.uniqWith(theArr, _.isEqual);
          } else {
            this.condosList = this.allCondos;
          }
        } else {
          if (this.areaFilterValue) {
            this.applyAreaFilter(this.areaFilterValue);
            return;
          } else {
            this.condosList = this.allCondos;
          }
        }

        this.totalItems = this.condosList.length;
        this.setPages(this.condosList);
        this.paginate(this.condosList);
      } else {
        this.applyMultipleFilters(this.multipleFilters);
      }
    },

    multipleFilters(newVal) {
      if (newVal) {
        this.applyMultipleFilters(newVal);
      }
    },
  },
  computed: {
    ...mapGetters(["getOrgId", "getProspectId", "getUser", "getAssets"]),
  },
  mounted() {
    this.getCondosData();
  },
  methods: {
    async getCondosData() {
      eventBus.$emit("loader-status", true);

      await this.$http
        .get("/accountAuth/condos?orgId=" + this.getOrgId)
        .then((response) => {
          let resp = response.data.data;

          if (response.status === 200) {
            this.allCondos = resp.condosData;
            this.condosList = resp.condosData;

            this.totalItems = this.allCondos.length;

            this.setPages(this.condosList);
            this.paginate(this.condosList);

            this.sortArea(resp);
            this.sortDownPayment(resp);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      eventBus.$emit("loader-status", false);
    },

    setPages(condos) {
      this.pages = [];

      let numberOfPages = Math.ceil(condos.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    paginate(condos) {
      this.from = this.page * this.perPage - this.perPage;
      this.to = this.page * this.perPage;
      this.showCondos = [].concat.apply(condos.slice(this.from, this.to), []);

      if (this.page === this.pages.length) {
        this.to = this.from + this.showCondos.length;
      }

      this.from = this.page * this.perPage - this.perPage + 1;
    },

    applyAreaFilter(value) {
      this.areaFilterValue = value;

      this.multipleFilters =
        this.dpFilterValue?.length && this.dpFilterValue.includes(true)
          ? value
            ? true
            : false
          : false;

      if (!this.multipleFilters) {
        if (!value) {
          this.dpFilterValue = [];
          return;
        }

        this.condosList = this.allCondos.filter((v) => v.area === value);

        this.totalItems = this.condosList.length;
        this.setPages(this.condosList);
        this.paginate(this.condosList);
      } else {
        this.applyMultipleFilters(this.multipleFilters);
      }
    },

    applyMultipleFilters(value) {
      if (value) {
        this.condosList = [];

        this.condosList = this.allCondos.filter((v) => v.area === this.areaFilterValue);

        const indexes = this.dpFilterValue.reduce((r, n, i) => {
          n === true && r.push(i);

          return r;
        }, []);

        const values = indexes.map((v) => {
          return this.dpFilter[v];
        });

        let theArr = [];

        values.forEach((x) => {
          let itemsArr = [];

          itemsArr = this.condosList.filter(
            (v) => v.primaryDP === x || v.secondDp === x || v.invDP === x
          );

          theArr.push(...itemsArr);
        });

        this.condosList = _.uniqWith(theArr, _.isEqual);
        this.totalItems = this.condosList.length;
        this.setPages(this.condosList);
        this.paginate(this.condosList);
      }
    },

    classForStatus(status) {
      switch (status) {
        case "Full Review":
          return "full-review";
        case "Limited Review":
          return "limited-review";
        case "Can-Do":
          return "can-do";
        case "Non-warrantable":
          return "non-warrantable";
        default:
          return "primary-color";
      }
    },

    textForToolTip(status) {
      if (status === "Full Review")
        return `Condo meets all requirements and qualifies for the lowest down payment options!`;
      else if (status === "Limited Review")
        return `Most condo requirements met. Down payment options from 10% and 30% are available.`;
      else if (status === "Can-Do")
        return `This condo is unique and requires a special, in-house program.`;
      else if (status === "Non-warrantable")
        return `This condo is not approved due to warrantability issues. Give us a call to discuss financing options. `;
    },
  },
};
</script>

<style scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.font-weight-normal {
  font-weight: 500 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.zero-index{
  z-index: 0;
}
</style>