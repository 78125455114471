<template>
  <div
    class="modal"
    id="condos_chart"
    tabindex="-1"
    aria-labelledby="condosChartLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <div class="d-flex w-100 align-items-center">
            <div class="header_tabs">
              <div
                class="tab"
                :class="isRateTabSelected && 'selected'"
                @click="changeTab('rates')"
              >
                Rates Trend
              </div>
              <div
                class="tab"
                :class="isCreditTabSelected && 'selected'"
                @click="changeTab('credit')"
              >
                Credit & LTV
              </div>
            </div>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="$emit('hideDialog', false)"
            >
              <img src="../assets/images/cross-icon.svg" alt="cross icon" />
            </button>
          </div>
        </div>
        <div class="modal-body">
          <!-- Credit and LTV -->
          <div class="chart" v-show="isCreditTabSelected">
            <BarChart :chartData="barChartData" :chartOptions="barChartOptions" />
          </div>
          <!-- Rates trend -->
          <div class="chart" v-show="isRateTabSelected">
            <LineChart :chartData="lineChartData" :chartOptions="lineChartOptions" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import LineChart from "./LineChart.vue";
import BarChart from "./BarChart.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    LineChart,
    BarChart,
  },
  props: {
    rateHistoryData: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      selectedTab: "rates",
      // Data for line chart
      lineChartData: {
        labels: [],
        datasets: [
          {
            label: "C15 - Yr",
            borderColor: "#222222",
            backgroundColor: "#222222",
            data: [],
            tension: 0.1, // Make line sharp
            fill: false, // Remove background color
          },
          {
            label: "C30 - Yr",
            borderColor: "",
            backgroundColor: "",
            data: [],
            tension: 0.1, // Make line sharp
            fill: false, // Remove background color
          },
        ],
      },
      // Line Chart options (optional)
      lineChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },

      // Data for bar chart
      barChartData: {
        labels: ["Fico < 680", "680 - 699", "700 - 719", "720 - 739", "Fico > 740"],
        datasets: [
          {
            label: "LTV <= 80",
            backgroundColor: "#222222",
            data: [],
          },
          {
            label: "LTV > 80",
            backgroundColor: "",
            data: [],
          },
        ],
      },
      // Bar Chart options (optional)
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
        },
        animation: {
          y: { from: 0 },
        },
      },
    };
  },
  computed: {
    ...mapGetters(["getAssets"]),

    isCreditTabSelected() {
      return this.selectedTab === "credit";
    },
    isRateTabSelected() {
      return this.selectedTab === "rates";
    },
  },
  watch: {
    rateHistoryData: {
      deep: true,
      handler(newVal) {
        if (newVal?.length) {
          this.barChartData.datasets[0].data = [
            newVal[0].X30LT80Less680,
            newVal[0].X30LT80Less680To699,
            newVal[0].X30LT80Less700To719,
            newVal[0].X30LT80Less720To739,
            newVal[0].X30LT80Plus740,
          ];

          this.barChartData.datasets[1].backgroundColor =
            this.getAssets?.primaryBrandColor || "#BE2530";

          this.barChartData.datasets[1].data = [
            newVal[0].X30GT80Less680,
            newVal[0].X30GT80Plus680To699,
            newVal[0].X30GT80Plus700To719,
            newVal[0].X30GT80Plus720To739,
            newVal[0].X30GT80Plus740,
          ];

          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
          });

          this.lineChartData.datasets[1].borderColor =
            this.getAssets?.primaryBrandColor || "#BE2530";
          this.lineChartData.datasets[1].backgroundColor =
            this.getAssets?.primaryBrandColor || "#BE2530";

          this.lineChartData.labels = [];

          newVal.forEach((element, index) => {
            if (index % 2 === 0) {
              let day = dateFormatter.format(new Date(element.dataDate));
              this.lineChartData.labels.unshift(day);
              this.lineChartData.datasets[0].data.unshift(element.C15);
              this.lineChartData.datasets[1].data.unshift(element.C30);
            }
          });
        }
      },
    },
  },
  mounted() {
    if (this.rateHistoryData?.length) {
      this.barChartData.datasets[0].data = [
        this.rateHistoryData[0].X30LT80Less680,
        this.rateHistoryData[0].X30LT80Less680To699,
        this.rateHistoryData[0].X30LT80Less700To719,
        this.rateHistoryData[0].X30LT80Less720To739,
        this.rateHistoryData[0].X30LT80Plus740,
      ];

      this.barChartData.datasets[1].backgroundColor =
        this.getAssets?.primaryBrandColor || "#BE2530";

      this.barChartData.datasets[1].data = [
        this.rateHistoryData[0].X30GT80Less680,
        this.rateHistoryData[0].X30GT80Plus680To699,
        this.rateHistoryData[0].X30GT80Plus700To719,
        this.rateHistoryData[0].X30GT80Plus720To739,
        this.rateHistoryData[0].X30GT80Plus740,
      ];

      const dateFormatter = new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "2-digit",
      });

      this.lineChartData.datasets[1].borderColor =
        this.getAssets?.primaryBrandColor || "#BE2530";
      this.lineChartData.datasets[1].backgroundColor =
        this.getAssets?.primaryBrandColor || "#BE2530";

      this.lineChartData.labels = [];

      this.rateHistoryData.forEach((element, index) => {
        if (index % 2 === 0) {
          let day = dateFormatter.format(new Date(element.dataDate));
          this.lineChartData.labels.unshift(day);
          this.lineChartData.datasets[0].data.unshift(element.C15);
          this.lineChartData.datasets[1].data.unshift(element.C30);
        }
      });
    }
  },
  methods: {
    changeTab(tab) {
      this.selectedTab = tab;
    },
  },
};
</script>

<style scoped>
#condos_chart .modal-content {
  border-radius: 12px;
  border: none;
}

#condos_chart .modal-header {
  border-bottom: none;
  padding: 27px 40px 0;
}

#condos_chart .modal-header .btn-close {
  position: absolute;
  top: 10px;
  right: -30px;
  background-color: #fff;
  border-radius: 40px;
}

#condos_chart .modal-body {
  padding: 28px 40px 40px;
}

#condos_chart .modal-body .chart {
  background-color: #f6f8fa;
  padding: 20px;
}

.header_tabs {
  display: flex;
  border: 1px solid #d7dae3;
  border-radius: 8px;
  padding: 3px;
}

.header_tabs .tab {
  font-weight: 400;
  padding: 5px 10px;
  cursor: pointer;
  color: #000000;
  border-radius: 5px;
}

.header_tabs .tab.selected {
  font-weight: 600;
  border-radius: 6px;
  color: #fff;
  background: #15162b;
}

/* .modal-body .chart > div {
  height: 400px;
} */

@media (max-width: 767px) {
  #condos_chart .modal-header {
    padding: 20px 20px 0;
  }

  #condos_chart .modal-body {
    padding: 20px 20px;
  }

  #condos_chart .modal-body .chart {
    padding: 10px;
  }

  #condos_chart .modal-header .btn-close {
    position: relative;
    right: 0;
    top: 0;
    background-color: transparent;
    margin: 0 0 auto auto;
  }
}
</style>
