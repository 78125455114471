<template>
  <b-modal
    v-model="amrModal"
    modal-class="modal-cover-main-set checkin-modal-design"
    centered
    hide-header
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
  >
    <div class="modal-header modal-cover-title-set">
      <h5>Annual Homeowner Check-In for</h5>
      <h3>{{ clientObj.clientName }} / {{ clientObj.street }}</h3>
      <p>Last Updated: {{ clientObj.lastUpdatedDate | formatDate }}</p>
      <button
        type="button"
        class="btn-close"
        @click="close"
        aria-label="Close"
      ></button>
    </div>
    <div class="Iner-modal-body">
      <div class="qa-main-cover">
        <div
          class="qa-main-card"
          v-for="(amrResponse, index) in amrResponses"
          :key="index"
        >
          <div class="question-main-cover">
            <h6><span>Q</span>{{ amrResponse.question }}</h6>
          </div>
          <div class="answer-main-cover">
            <div class="text-answer">
              <h5 v-if="index === amrResponses.length - 1">
                <span>A</span>
                ${{ amrResponse.answer }}
              </h5>
              <h5 v-else>
                <span>A</span>
                <img
                  v-if="
                    amrResponse.answer === 'No' || amrResponse.answer === 'N/A'
                  "
                  src="./../assets/images/close-red-icon.svg"
                  alt="no"
                />
                {{ amrResponse.answer }}
              </h5>
            </div>
            <div
              v-if="
                index === 1 &&
                amrResponse.answer !== 'We are never moving again ever!'
              "
              class="progress-card-cover extra-margin-top"
            >
              <div class="progress-card-line-cover">
                <div
                  class="progress-card-line-fill"
                  :style="`width: ${dateSliderPosition}%`"
                >
                  <span class="top">Today</span>
                </div>
                <div class="dot-linr-right" style="width: 110px"></div>
              </div>
              <div class="progress-card-text">
                <h4>
                  Close Date <br />
                  {{ clientObj.closeDate | formatDate }}
                </h4>
                <h6>
                  Target Sale Timeframe <br />
                  {{ targetSaleTimeframe }}
                </h6>
              </div>
            </div>
            <div
              v-if="index === amrResponses.length - 1"
              class="progress-card-cover"
            >
              <div class="progress-line-two">
                <div
                  class="progress-ine-two-iner"
                  :style="{ left: percentage + '%' }"
                ></div>
              </div>
              <div class="progress-card-text">
                <h4>
                  Purchase Price <br />
                  (${{ clientObj.purchasePrice }})
                </h4>
                <h5>
                  Est. Low Value <br />
                  (${{ clientObj.lowEstimate }})
                </h5>
                <h6>
                  Est. High Value <br />
                  (${{ clientObj.highEstimate }})
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer-button">
      <button type="button" class="ok-button" @click="close" aria-label="Close">
        OK
      </button>
    </div>
  </b-modal>
</template>

<script>
import moment from "moment";
import { eventBus } from "./../event-bus";
import { mapGetters } from "vuex";

export default {
  name: "Amr-Response-Modal",
  data() {
    return {
      amrModal: false,
      currentYear: new Date(),
    };
  },
  computed: {
    ...mapGetters("mortgage-estimate", {
      clientObj: "getAMRModalData",
      amrResponses: "getAMRResponses",
    }),

    rangeStartDate() {
      return moment(this.clientObj.closeDate).format("MMM D, YYYY");
    },

    currentDate() {
      return moment().format("MMM D, YYYY");
    },

    rangeEndDate() {
      let theAnswer = this.amrResponses[1].answer;

      if (theAnswer.includes("1")) return `${this.getFutureDates(1)}`;
      else if (theAnswer.includes("2") && theAnswer.includes("3"))
        return `${this.getFutureDates(3)}`;
      else if (theAnswer.includes("4") && theAnswer.includes("6"))
        return `${this.getFutureDates(6)}`;
      else if (theAnswer.includes("6")) return `${this.getFutureDates(10)}`;
      else return "";
    },

    targetSaleTimeframe() {
      let theAnswer = this.amrResponses[1].answer;

      if (theAnswer.includes("1"))
        return `${this.getFutureDates(0)} - ${this.getFutureDates(1)}`;
      else if (theAnswer.includes("2") && theAnswer.includes("3"))
        return `${this.getFutureDates(2)} - ${this.getFutureDates(3)}`;
      else if (theAnswer.includes("4") && theAnswer.includes("6"))
        return `${this.getFutureDates(4)} - ${this.getFutureDates(6)}`;
      else if (theAnswer.includes("6"))
        return `${this.getFutureDates(6)} - ${this.getFutureDates(10)}`;
      else return "";
    },

    dateSliderPosition() {
      let closeDate = moment(this.rangeStartDate, "MMM D, YYYY").valueOf();
      let currentDate = moment(this.currentDate, "MMM D, YYYY").valueOf();
      let rangeEndDate = moment(this.rangeEndDate, "MMM D, YYYY").valueOf();

      let percentage =
        ((currentDate - closeDate) / (rangeEndDate - closeDate)) * 100;
      return Math.round(100 - percentage);
    },

    percentage() {
      if (this.clientObj.highEstimate === this.clientObj.purchasePrice)
        return 0;
      return (
        ((Number(this.amrResponses[2].answer) - this.clientObj.purchasePrice) /
          (this.clientObj.highEstimate - this.clientObj.purchasePrice)) *
        100
      ).toFixed(0);
    },
  },
  mounted() {
    eventBus.$on("amrModalOpen", (data) => {
      this.amrModal = data;
    });
  },
  methods: {
    getFutureDates(addYears) {
      return moment().add(addYears, "years").format("MMM D, YYYY");
    },
    close() {
      this.amrModal = false;
      this.$store.dispatch("mortgage-estimate/SET_OPEN_AMR_MODAL", false);
    },
  },
};
</script>

<style>
.checkin-modal-design .modal-body {
  padding: 0px !important;
}
/*** Check-in Modal CSS ***/
.modal-cover-main-set .modal-dialog {
  width: 580px;
}

.modal-cover-title-set h5 {
  line-height: 1.5;
  margin-bottom: 5px;
  font-weight: 600;
}
.modal-cover-title-set p {
  line-height: 1.5;
}

.modal-footer-button {
  margin-top: 0px;
  padding-top: 10px;
}

.question-main-cover h6 {
  padding: 12px 25px 12px;
  font-size: 15px;
  line-height: 1.2;
}

.progress-card-line-fill span {
  font-size: 14px;
}

.extra-margin-top {
  margin-top: 35px !important;
}

.progress-card-text h4,
.progress-card-text h5,
.progress-card-text h6 {
  font-size: 14px;
}

.modal-cover-main-set .Iner-modal-body {
  padding: 18px 22px 18px;
}

.modal-cover-main-set .modal-dialog {
  width: 610px;
  max-width: inherit;
}
.modal-cover-main-set .modal-content {
  border-radius: 15px;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
}
.modal-cover-main-set .Iner-modal-body {
  padding: 22px;
}
.modal-cover-title-set {
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  padding: 22px;
  padding-right: 45px;
  width: 100%;
}
.modal-cover-title-set h5 {
  color: #262a31;
  font-family: "Segoe UI Regular";
  font-size: 18px;
}
.modal-cover-title-set h3 {
  color: #262a31;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 5px;
  width: 100%;
}
.modal-cover-title-set h3 span {
  font-family: "Segoe UI Regular";
  font-weight: 600;
}

.modal-cover-title-set p {
  color: #262a31;
  font-size: 15px;
  font-weight: normal;
  width: 100%;
  margin-bottom: 0;
}

.modal-cover-title-set button {
  position: absolute;
  right: 20px;
  top: 20px;
}

.qa-main-card {
  border: 1px solid #d9dae0;
  width: 100%;
  border-radius: 14px;
  overflow: hidden;
}
.qa-main-cover {
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 15px;
  max-height: 610px;
  overflow: auto;
}
.question-main-cover {
  background-color: #f6f6f6;
}
.question-main-cover h6 {
  display: inline-flex;
  padding: 15px 25px 15px;
  width: 100%;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 12px;
  margin: 0px;
  font-size: 16px;
  font-family: "Segoe UI Regular";
  color: #707070;
  white-space: pre-wrap;
}
.question-main-cover h6 span {
  border: 1px solid #848692;
  display: inline-flex;
  border-radius: 19px;
  padding: 2px 5px 5px;
  color: #707070;
  font-size: 14px;
}

.answer-main-cover {
  width: 100%;
  padding: 15px 25px 15px;
}
.text-answer h5 {
  color: #000000;
  font-size: 16px;
  font-family: "Segoe UI Bold";
  display: inline-flex;
  width: 100%;
  gap: 8px;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 0px;
}
.text-answer h5 span {
  border: 1px solid #be2530;
  display: inline-flex;
  border-radius: 16px;
  padding: 2px 5px 4px;
  color: #707070;
  margin-right: 6px;
  font-size: 14px;
}
.text-answer h5 img {
  width: 14px;
}
.progress-card-cover {
  width: 100%;
  margin-top: 22px;
}

.progress-card-line-cover {
  background-color: #b8b8b8;
  height: 17px;
  border-radius: 16px;
  position: relative;
}
.progress-card-line-cover::before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  position: absolute;
  left: 5px;
  top: 5px;
  background: #707070;
  border-radius: 50%;
  border: 2px solid #ffffff;
}
.progress-card-line-fill {
  background-color: #be2530;
  width: 80%;
  position: absolute;
  right: 0;
  height: 17px;
  border-radius: 0px 16px 16px 0px;
}
.progress-card-line-fill span {
  position: absolute;
}
.progress-card-line-fill span.bottom {
  top: 18px;
  left: 0px;
}
.progress-card-line-fill span.top {
  bottom: 25px;
  left: 0px;
}
.progress-card-line-fill::before {
  content: "";
  background: url(./../assets/images/rectangle-icon.svg) #ffffff top center
    no-repeat;
  background-size: 9px;
  width: 10px;
  height: 24px;
  display: inline-block;
  position: absolute;
  left: 0px;
  top: -6px;
}

.dot-linr-right {
  position: absolute;
  right: 8px;
  top: 7.5px;
  width: 130px;
  height: 2px;
  border-bottom: 2px dotted #ffffff;
}
.dot-linr-right::after {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  position: absolute;
  left: -1px;
  top: -3px;
  background: #707070;
  border-radius: 50%;
  border: 2px solid #ffffff;
}
.dot-linr-right::before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  position: absolute;
  right: -2px;
  top: -3px;
  background: #707070;
  border-radius: 50%;
  border: 2px solid #ffffff;
}
.progress-card-text {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}
.progress-card-text h4,
.progress-card-text h5,
.progress-card-text h6 {
  font-weight: normal;
  font-size: 15px;
  color: #222222;
  margin: 0px;
  margin-top: 5px;
  line-height: 1.5;
}
.progress-card-text h4 {
  text-align: left;
}
.progress-card-text h5 {
  text-align: center;
}
.progress-card-text h6 {
  text-align: right;
}

.progress-line-two {
  background: rgb(255, 203, 207);
  background: linear-gradient(
    90deg,
    rgba(255, 203, 207, 1) 0%,
    rgba(190, 37, 48, 1) 100%
  );
  height: 17px;
  border-radius: 16px;
  position: relative;
}
.progress-line-two::before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  top: 4px;
  background: #707070;
  border-radius: 50%;
  border: 2px solid #ffffff;
  margin: 0 auto;
}
.progress-ine-two-iner {
  width: 9px;
  height: 24px;
  display: inline-block;
  position: absolute;
  background: #222222;
  border-radius: 18px;
  border: 2px solid #ffffff;
  top: -3px;
}

.modal-footer-button {
  display: inline-flex;
  width: 100%;
  padding: 0px 0px 20px 22px;
}
.modal-footer-button button {
  border: 1px solid #e0e4e8;
  color: #262a31;
  background: #ffffff;
  border-radius: 35px;
  padding: 8px 32px;
  font-size: 16px;
  font-family: "Segoe UI Regular";
}
.ok-button:hover {
  background: var(--primary-color);
  color: white;
}
/*** New Modal CSS End ***/

@media (max-width: 767px) {
  .modal.checkin-modal-design {
    margin-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .modal-cover-main-set .modal-dialog {
    width: 95%;
  }
}

@media (max-width: 479px) {
  .question-main-cover h6 {
    font-size: 15px;
  }
  .text-answer h5 {
    font-size: 15px;
  }
  .progress-ine-two-iner {
    width: 7px;
  }
  .modal-cover-title-set h5 {
    font-size: 16px;
  }
  .modal-cover-title-set h3 {
    font-size: 16px;
  }
  .modal-cover-title-set p {
    font-size: 16px;
  }
  .question-main-cover h6 {
    padding-left: 14px;
    padding-right: 14px;
  }
  .answer-main-cover {
    padding-left: 14px;
    padding-right: 14px;
  }
  .progress-card-text h4,
  .progress-card-text h5,
  .progress-card-text h6 {
    font-size: 13px;
  }
  .dot-linr-right {
    width: 88px;
  }
  .modal-cover-title-set {
    padding: 15px;
    padding-right: 40px;
  }
  .modal-cover-main-set .Iner-modal-body {
    padding: 15px;
  }
}
</style>