<template>
  <div class="content__wrapper bg-white mortgage-section pb-0">
    <header>
      <InterestRateToolBar
        :interest-rates="getAssets?.rateHistoryData?.[0] || {}"
      />
    </header>
    <div class="container-fluid pe-0 ps-0 mb-10">
      <div class="row">
        <div class="col-xl-8 col-lg-7 p-4">
          <div class="form__wrapper-content border-0 bg-transparent">
            <div class="page-heading">Mortgage Calculator</div>
            <form>
              <div class="form__body p-0 mt-3">
                <div class="form-group">
                  <div class="form-input col-xl-6 col-lg-12">
                    <div class="input-field">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        v-model="selectedClient"
                      >
                        <option value="" selected disabled hidden>
                          Select a Client
                        </option>
                        <option value="none">None</option>
                        <option
                          v-for="(cn, index) in clientList"
                          :key="index"
                          :value="cn.clientName"
                        >
                          {{ cn.clientName | capitalize }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="form-input col-xl-6 col-lg-12">
                      <label>Home Price</label>
                      <div class="input-field">
                        <img
                          src="../assets/images/dollar-icon.svg"
                          alt="doller icon"
                        />
                        <input
                          type="text"
                          v-model="homePrice"
                          placeholder="Home Price"
                          @input="maskNumber"
                        />
                      </div>
                    </div>
                    <div class="form-input col-xl-6 col-lg-12">
                      <label>Loan Program</label>
                      <div class="input-field">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          v-model="selectedLoanProgram"
                          :disabled="
                            selectedClient !== 'none' && selectedClient !== ''
                          "
                        >
                          <option value="" selected disabled hidden>
                            Select a Loan Program
                          </option>
                          <option
                            v-for="(lp, index) in loanPrograms"
                            :key="index"
                            :value="lp.loanProgramName"
                          >
                            {{ lp.loanProgramName | capitalize }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group d-block pt-4 pb-4">
                  <div class="form-input downpayment">
                    <div class="d-flex justify-content-between">
                      <label class="ps-0"
                        >Down Payment
                        <span>{{ downPayment }}%</span>
                      </label>
                      <span class="fw-bolder"
                        >${{ downPaymentAmount | formatNumber }}</span
                      >
                    </div>
                    <div class="range-slider">
                      <range-slider
                        class="slider"
                        :min="minValueForDp"
                        max="100"
                        step="0.50"
                        v-model="downPayment"
                      >
                      </range-slider>
                      <!-- <div class="d-flex justify-content-between">
                        <span>{{ minValueForDp }}%</span>
                        <span>100%</span>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="form-group d-block mt-3 pt-3 pb-4">
                  <div class="form-input percentage">
                    <div class="d-flex justify-content-between">
                      <label class="ps-0">Interest Rate</label>
                      <span class="fw-bolder"
                        >{{ roundTheNumber(interestRate, 2) }}%</span
                      >
                    </div>
                    <div class="range-slider">
                      <range-slider
                        class="slider"
                        min="0"
                        max="20"
                        step="0.01"
                        v-model="interestRate"
                      >
                      </range-slider>
                      <!-- <div class="d-flex justify-content-between">
                        <span>0%</span>
                        <span>20%</span>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="form-group d-block mt-3 pt-3 pb-4">
                  <div class="form-input downpayment">
                    <div class="d-flex justify-content-between">
                      <label class="ps-0"
                        >Property Taxes/year
                        <span
                          >{{ roundTheNumber(propertyTaxPercentage, 2) }}%</span
                        ></label
                      >
                      <span class="fw-bolder"
                        >${{ propertyTax | formatNumber }}</span
                      >
                    </div>
                    <div class="range-slider">
                      <range-slider
                        class="slider"
                        min="0"
                        max="10"
                        step="0.01"
                        v-model="propertyTaxPercentage"
                      >
                      </range-slider>
                      <!-- <div class="d-flex justify-content-between">
                        <span>0%</span>
                        <span>5%</span>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="form-group d-block mt-3 pt-3 pb-4">
                  <div class="form-input downpayment">
                    <div class="d-flex justify-content-between">
                      <label class="ps-0">Homeowner's Insurance/year</label>
                      <span class="fw-bolder"
                        >${{ homeInsurance | formatNumber }}</span
                      >
                    </div>
                    <div class="range-slider percentage">
                      <range-slider
                        class="slider"
                        min="0"
                        max="20000"
                        step="10"
                        v-model="homeInsurance"
                      >
                      </range-slider>
                      <!-- <div class="d-flex justify-content-between">
                        <span>$0</span>
                        <span>$5000</span>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="form-group d-block mt-3 pt-3 pb-4">
                  <div class="form-input downpayment">
                    <div class="d-flex justify-content-between">
                      <label class="ps-0">HOA Dues/month</label>
                      <span class="fw-bolder"
                        >${{ hoaDues | formatNumber }}</span
                      >
                    </div>
                    <div class="range-slider percentage">
                      <range-slider
                        class="slider"
                        min="0"
                        max="3000"
                        step="1"
                        v-model="hoaDues"
                      >
                      </range-slider>
                      <!-- <div class="d-flex justify-content-between">
                        <span>$0</span>
                        <span>$3000</span>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          class="col-xl-4 col-lg-5 right-fixed"
          :class="showUpFrontFeeRow ? 'add-bottom-padding' : ''"
        >
          <div class="calculations">
            <div class="calculations-content">
              <div class="total-amount">
                <label>Estimated Payment</label>
                <h3>
                  ${{ monthlyMortgagePayment || 0 | formatNumber
                  }}<span>/month</span>
                </h3>
              </div>
              <div class="amount-list">
                <ul>
                  <li>
                    <span class="label">Loan Amount</span>
                    <span class="price">${{ loanAmount | formatNumber }}</span>
                  </li>
                  <li>
                    <span class="label">Loan Term</span>
                    <span class="price">{{
                      termMonths + " months" || "30 Years"
                    }}</span>
                  </li>
                  <li>
                    <span class="label">Down Payment</span>
                    <span class="price"
                      >${{ downPaymentAmount | formatNumber }}</span
                    >
                  </li>
                  <li>
                    <span class="label">Interest Rate</span>
                    <span class="price"
                      >{{ roundTheNumber(interestRate, 2) }}%</span
                    >
                  </li>
                  <li v-if="showUpFrontFeeRow">
                    <span class="label">{{ upFrontFeeLabel }}</span>
                    <span class="price"
                      >${{ upFrontFee || 0 | formatNumber }}</span
                    >
                  </li>
                  <li>
                    <span class="label">Property Taxes</span>
                    <span class="price"
                      >${{ roundTheNumber(propertyTax / 12, 0) }}/month</span
                    >
                  </li>
                  <li>
                    <span class="label">Homeowner's Insurance</span>
                    <span class="price"
                      >${{
                        roundTheNumber(homeInsurance / 12, 0) | formatNumber
                      }}/month</span
                    >
                  </li>
                  <li>
                    <span class="label">HOA Dues</span>
                    <span class="price"
                      >${{ hoaDues | formatNumber }}/month</span
                    >
                  </li>
                  <li>
                    <span class="label">PMI</span>
                    <span class="price">${{ clientPMI | formatNumber }}</span>
                  </li>
                </ul>
              </div>
              <div class="chart__container">
                <div class="chart__container-content">
                  <h4 class="m-0">Breakdown Payment</h4>
                  <div class="relative">
                    <DoughNut :chartData="chartData" :options="options" />
                    <div class="absolute-center text-center">
                      <span class="break-down-text">Your Payment</span><br />
                      <span class="break-down-price font-bolder"
                        >${{ monthlyMortgagePayment || 0 | formatNumber }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 fw-bold disclaimer-fonts p-3">
        This calculator is made available to you as an education tool only and
        calculations are based on user-input information. This is not an
        advertisement for the above terms, interest rate, or payment amounts.
        {{ getUser.loCompany }} does not guarantee the applicability of the
        above terms.
      </div>
    </div>

    <ProfileInfo />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "./../event-bus";
import RangeSlider from "vue-range-slider";
import Toasters from "./../mixins/toasters";
import Numbers from "./../mixins/numbers";
import Shades from "./../mixins/shades";
import DoughNut from "./Doughnut-Chart.vue";
import InterestRateToolBar from "./InterestRateToolBar.vue";
import ProfileInfo from "./Profile-Info.vue";

const options = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: 90,
  animation: {
    animateRotate: true,
  },
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        display: true,
        usePointStyle: true,
        padding: 20,
      },
    },
  },
};

export default {
  name: "Mortgage-Calculator",
  components: {
    RangeSlider,
    DoughNut,
    InterestRateToolBar,
    ProfileInfo,
  },
  mixins: [Toasters, Numbers, Shades],
  data() {
    return {
      selectedClient: "",
      clientList: [],
      selectedLoanProgram: "",
      loanType: "",
      loanPrograms: [],
      termMonths: 360,
      FHAUpfrontRate: 1.75, //This value will always be in percentage(%)
      USDAUpfrontRate: 1, //This value will always be in percentage(%)
      VAFundingFeeRate: 2.15, //This value will always be in percentage(%)
      LTV: "",
      FHAPMIGreaterThanFive: 0.55 / 100,
      FHAPMILesserThanFive: 0.5 / 100,
      USDAPMI: 0.35 / 100,
      monthlyMortgagePayment: "",
      homePrice: "300,000",
      downPayment: 20,
      minValueForDp: 0,
      interestRate: 0,
      defaultTaxRate: 0,
      propertyTaxPercentage: 0,
      homeInsurance: 840,
      hoaDues: 0,
      options,
      chartData: {},
      defaultInterestRate: 0,
    };
  },
  watch: {
    selectedClient(newVal) {
      if (newVal === "none") this.selectedClientNone();
      else {
        if (newVal) {
          let client = this.clientList.find((v) => v.clientName === newVal);
          this.homePrice = this.maskTheNumber(client.homePrice);
          this.selectedLoanProgram = client.loanProgram;
          this.loanType = client.loanType;
          this.downPayment = client.downPayment;
          this.LTV = 100 - this.downPayment;
          this.interestRate =
            client.interestRate || this.getInterestRate(client.loanType);
          this.propertyTaxPercentage = client.annualTaxRate;
          this.homeInsurance = client.annualHomeownerInsurance;
          this.FHAUpfrontRate = client.FHA_UFMIP;
          this.USDAUpfrontRate = client.USDAGuaranteeFee;
          this.VAFundingFeeRate = client.VAFundingFee;
          this.calculateMortgage();
        }
      }
    },

    homePrice() {
      this.calculateMortgage();
    },

    selectedLoanProgram(newVal) {
      if (newVal) {
        let getTheMonths = this.loanPrograms.find(
          (v) => v.loanProgramName === newVal
        );

        if (!this.selectedClient || this.selectedClient === "none")
          this.interestRate = this.getInterestRate(
            getTheMonths.loanProgramCategory
          );

        this.termMonths = getTheMonths.termMonth;

        this.loanType = getTheMonths.loanProgramCategory;

        this.minValueForDp =
          this.loanType === "Conventional"
            ? 3
            : this.loanType === "FHA"
            ? 3.5
            : this.loanType === "Jumbo"
            ? 5
            : newVal === "80-10-10"
            ? 10
            : 0;

        if (
          this.loanType === "Conventional" &&
          this.downPayment < this.minValueForDp
        )
          this.downPayment = this.minValueForDp;

        if (this.loanType === "FHA" && this.downPayment < this.minValueForDp)
          this.downPayment = this.minValueForDp;

        if (this.loanType === "Jumbo" && this.downPayment < this.minValueForDp)
          this.downPayment = this.minValueForDp;

        if (newVal === "80-10-10" && this.downPayment < this.minValueForDp)
          this.downPayment = this.minValueForDp;
      }

      this.calculateMortgage();
    },

    downPayment(newVal) {
      if (newVal === 100) {
        this.interestRate = 0;
        this.homeInsurance = 0;
        this.hoaDues = 0;
        this.propertyTaxPercentage = 0;
      }

      this.LTV = 100 - newVal;
      this.calculateMortgage();
    },

    interestRate() {
      this.calculateMortgage();
    },

    propertyTax() {
      this.calculateMortgage();
    },

    homeInsurance() {
      this.calculateMortgage();
    },

    hoaDues() {
      this.calculateMortgage();
    },

    defaultTaxRate(newVal) {
      this.propertyTaxPercentage = newVal;
    },
  },
  computed: {
    ...mapGetters(["getOrgId", "getProspectId", "getUser", "getAssets"]),

    loanAmount() {
      return (
        this.roundTheNumber(
          Number(this.removeComma(this.homePrice)) - this.downPaymentAmount,
          0
        ) + this.upFrontFee
      );
    },

    showUpFrontFeeRow() {
      let newArr = ["FHA", "USDA", "VA"];
      return newArr.includes(this.loanType);
    },

    upFrontFeeLabel() {
      if (this.loanType === "FHA")
        return "Upfront FHA Fee (" + this.FHAUpfrontRate + "%)";

      if (this.loanType === "USDA")
        return "Upfront USDA Fee (" + this.USDAUpfrontRate + "%)";

      if (this.loanType === "VA")
        return "VA Funding Fee (" + this.VAFundingFeeRate + "%)";

      return "";
    },

    upFrontFee() {
      let actualLoanAmount = this.roundTheNumber(
        Number(this.removeComma(this.homePrice)) - this.downPaymentAmount,
        0
      );

      if (this.loanType === "FHA") {
        let FHAUpfront = (actualLoanAmount * this.FHAUpfrontRate) / 100;
        return this.roundTheNumber(FHAUpfront, 0);
      }

      if (this.loanType === "USDA") {
        let USDAUpfront = (actualLoanAmount * this.USDAUpfrontRate) / 100;
        return this.roundTheNumber(USDAUpfront, 0);
      }

      if (this.loanType === "VA") {
        let VAFunding = (actualLoanAmount * this.VAFundingFeeRate) / 100;
        return this.roundTheNumber(VAFunding, 0);
      }

      return 0;
    },

    propertyTax() {
      let theTax =
        Number(this.removeComma(this.homePrice)) *
        0.25 *
        (this.propertyTaxPercentage / 100);
      return this.roundTheNumber(theTax, 0);
    },

    downPaymentAmount() {
      return Number(this.removeComma(this.homePrice)) && this.downPayment
        ? this.roundTheNumber(
            (Number(this.removeComma(this.homePrice)) * this.downPayment) / 100,
            0
          )
        : 0;
    },

    clientPMI() {
      let PMI;

      if (this.loanType === "Conventional") {
        if (this.LTV >= 95.1 && this.LTV <= 97) {
          PMI = (this.loanAmount * 0.0066) / 12;
        }
        if (this.LTV >= 90.1 && this.LTV <= 95) {
          PMI = (this.loanAmount * 0.0046) / 12;
        }
        if (this.LTV >= 85.1 && this.LTV <= 90) {
          PMI = (this.loanAmount * 0.0033) / 12;
        }
        if (this.LTV >= 80.1 && this.LTV <= 85) {
          PMI = (this.loanAmount * 0.0019) / 12;
        }
        if (this.LTV <= 80) {
          PMI = 0.0;
        }
      }

      if (this.loanType === "FHA") {
        if (this.LTV > 95) {
          PMI = (this.loanAmount * this.FHAPMIGreaterThanFive) / 12;
        }
        if (this.LTV <= 95) {
          PMI = (this.loanAmount * this.FHAPMILesserThanFive) / 12;
        }
      }

      if (this.loanType === "USDA") {
        PMI = (this.loanAmount * this.USDAPMI) / 12;
      }

      let loanTypeArr = ["Jumbo", "VA", "HELLOC", "Other"];

      if (loanTypeArr.includes(this.loanType)) PMI = 0.0;

      return this.roundTheNumber(PMI, 2) || 0;
    },
  },
  mounted() {
    this.LTV = 100 - this.downPayment;
    this.getMortgageData();
  },
  methods: {
    async getMortgageData() {
      eventBus.$emit("loader-status", true);

      await this.$http
        .get(
          "/mortgage/getMortgageCalculatorData/" +
            this.getProspectId +
            "?orgId=" +
            this.getOrgId
        )
        .then((response) => {
          let resp = response.data;

          if (resp.status === 200) {
            this.clientList = resp.data;
            this.clientList.map((v) => {
              v.interestRate = v.interestRate
                ? Number(v.interestRate.slice(0, -1))
                : 0;
            });
            this.loanPrograms = resp.availableLoanPrograms;
            this.homePrice =
              resp.averagePurchasePrice && resp.averagePurchasePrice !== "0"
                ? this.maskTheNumber(resp.averagePurchasePrice)
                : "300,000";
            this.interestRate = resp.currentRate.C30;
            this.defaultInterestRate = resp.currentRate.C30;
            this.defaultTaxRate = resp.annualTaxRate || 4.22;
            this.currentRate = resp.currentRate;
          }
        })
        .catch((error) => {
          console.log(error);
        });

      eventBus.$emit("loader-status", false);
    },

    calculateMortgage() {
      if (!this.interestRate) return;

      const monthlyInterest = this.interestRate / 100 / 12;
      const months = this.termMonths;

      let principalAndInterest;

      if (this.interestRate !== 0) {
        principalAndInterest =
          this.loanAmount *
          ((monthlyInterest * (1 + monthlyInterest) ** months) /
            Math.abs(1 - (1 + monthlyInterest) ** months));
      }

      if (this.interestRate === 0) principalAndInterest = 0;

      principalAndInterest = this.roundTheNumber(principalAndInterest, 0);

      this.monthlyMortgagePayment = this.roundTheNumber(
        principalAndInterest +
          this.propertyTax / 12 +
          this.homeInsurance / 12 +
          this.clientPMI +
          this.hoaDues,
        0
      );

      // Generate shades of Primary brand color for Chart
      let primaryColor = this.getAssets.primaryBrandColor || "#A6383C";

      let firstShade = this.getShadesOfColor(primaryColor, 0);
      let secondShade = this.getShadesOfColor(primaryColor, -0.3);
      let thirdShade = this.getShadesOfColor(primaryColor, 0.3);
      let fourthShade = this.getShadesOfColor(primaryColor, -0.5);
      let fifthShade = this.getShadesOfColor(primaryColor, 0.7);

      this.chartData = Object.assign({});
      this.$set(this.chartData, "labels", []);
      this.$set(this.chartData, "datasets", []);
      this.chartData.labels = [
        "P&I",
        "Taxes",
        "Homeowner's Insurance",
        "PMI",
        "HOA Dues",
      ];
      this.chartData.datasets.push({
        backgroundColor: [
          firstShade,
          secondShade,
          thirdShade,
          fourthShade,
          fifthShade,
        ],
        data: [
          this.roundTheNumber(principalAndInterest, 0),
          this.roundTheNumber(this.propertyTax / 12, 0),
          this.roundTheNumber(this.homeInsurance / 12, 0),
          this.roundTheNumber(this.clientPMI, 0),
          this.roundTheNumber(this.hoaDues, 0),
        ],
        pointStyle: "circle",
      });
    },

    selectedClientNone() {
      this.loanType = "";
      this.termMonths = 360;
      this.FHAUpfrontRate = 1.75; //This value will always be in percentage(%)
      this.USDAUpfrontRate = 1; //This value will always be in percentage(%)
      this.VAFundingFeeRate = 2.15; //This value will always be in percentage(%)
      this.LTV = "";
      this.FHAPMIGreaterThanFive = 0.55 / 100;
      this.FHAPMILesserThanFive = 0.5 / 100;
      this.USDAPMI = 0.35 / 100;
      this.monthlyMortgagePayment = "";
      this.homePrice = "300,000";
      this.downPayment = 20;
      this.minValueForDp = 0;
      this.interestRate = this.defaultInterestRate;
      this.propertyTaxPercentage = this.defaultTaxRate;
      this.homeInsurance = 840;
      this.hoaDues = 0;
      this.selectedLoanProgram = "";
      this.calculateMortgage();
    },

    maskNumber() {
      this.homePrice = this.maskTheNumber(this.homePrice);
    },

    removeComma(num) {
      if (typeof num === "string" && num.includes(","))
        return num.replace(/,/g, "");
      else return num;
    },

    getInterestRate(loanType) {
      if (loanType === "FHA") return this.currentRate.FHA30;
      if (loanType === "Conventional") return this.currentRate.C30;
      if (loanType === "Jumbo") return this.currentRate.J30;
      if (loanType === "VA") return this.currentRate.VA30;
      if (loanType === "USDA") return this.currentRate.USDA30;

      return this.currentRate.C30;
    },
  },
};
</script>

<style>
@import "./../assets/css/range-slider.css";
</style>

<style scoped>
.footer-profile-cover {
  margin-top: 0px !important;
}
</style>